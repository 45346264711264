import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';



import fondo from '../../assets/img/landingPage/fondoApps.png';


import features_1 from '../../assets/img/landingPage/Features-1.png'
import features_2 from '../../assets/img/landingPage/Features-2.png'
import features_3 from '../../assets/img/landingPage/Features-3.png'

import logo from '../../assets/img/logos/extensions_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'
import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'


import user from '../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../assets/img/ui_icons/fa-doc.svg'

import tablet from '../../assets/img/landingPage/tabletApi.png'


const AvapProduct = () => {
    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);



    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center py-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">/* THE ALGORITHM THAT PROVES 1+1 IS NOT 2 */</h1>
                    <p className="fl-subtitle">
                        Empowering developers to use AVAP within their preferred development environment
                    </p>
                    {/* <a className="glowBtn-dark" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a> */}

                </section>

                {/* Powerful API Platform */}
                <section className="text-center py-5 container">
                    <div className="row">
                        <div className="col">

{/* 
                            <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-4"
                            /> */}

                            <h3 className="fl-subtitle-xs">Unlock Seamless API Development</h3>
                            <p className="fl-text">
                                AVAP Dev Studio Extensions are powerful tools designed to seamlessly integrate AVAP programming
                                language into Microsoft Visual Code. By leveraging AVAP extensions, developers can efficiently build, test,
                                and manage Virtual APIs within their familiar coding workspace while taking full advantage of the language’s
                                specialized features tailored for API lifecycle management.
                            </p>
                        </div>
                    </div>
                </section>


                <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div>

                <section className="container mb-5">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">Extensions</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="fl-card grayGradient">

                                {/* <img
                                    src="https://placehold.co/1026x423"
                                    alt="Platform Placeholder"
                                    className="img-fluid mt-4 mb-4"
                                /> */}

                            </div>
                        </div>
                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <div className="fl-bigLabel green">Avap Dev Studio</div>
                                    <div className="fl-subtitle">
                                        AVAP Dev Extension
                                    </div>
                                </div>
                                <div className="fl-text">
                                    Core tool for managing the full API lifecycle. For a complete development, management,
                                    and publishing experience,
                                    additional extensions are required:
                                    AVAP API Version Control for handling version management and AVAP API Publisher
                                    for publishing APIs across environments.
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <div className="fl-bigLabel red">Avap Version Control</div>
                                </div>
                                <div className="fl-text">
                                    This extension simplifies the process of building and managing
                                    multiple API versions in AVAP code. It requires the AVAP Dev Extension to fully integrate versioning
                                    capabilities into your development environment,
                                    streamlining API management throughout the project lifecycle.
                                </div>

                            </div>
                        </div>

                        <div className="col-4">
                            <div className="fl-card grayGradient">
{/* 
                                <img
                                    src="https://placehold.co/1026x423"
                                    alt="Platform Placeholder"
                                    className="img-fluid mt-4 mb-4"
                                /> */}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="fl-card grayGradient">

                                {/* <img
                                    src="https://placehold.co/1026x423"
                                    alt="Platform Placeholder"
                                    className="img-fluid mt-4 mb-4"
                                /> */}

                            </div>
                        </div>
                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <div className="fl-bigLabel purple">Avap Publisher</div>
                                </div>
                                <div className="fl-text">
                                    It integrates seamlessly with other extensions in the AVAP Dev family,
                                    enabling the publishing of APIs developed in the AVAP language.
                                    It also supports the publishing of APIs created in other technologies with an endpoint.
                                    AVAP Dev Extension for Visual Studio Code is required.
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <div className="fl-bigLabel yellow">Avap</div>
                                    <div className="fl-subtitle">
                                        AVAP Linter & Completion
                                    </div>
                                </div>
                                <div className="fl-text">
                                    A Visual Studio Code extension with rich
                                    support for AVAP language, providing access points for extensions to seamlessly integrate and provide a
                                    complete API lifecycle management, formatting, linting, code navigation, and more.
                                </div>

                            </div>
                        </div>

                        <div className="col-4">
                            <div className="fl-card grayGradient">

                                {/* <img
                                    src="https://placehold.co/1026x423"
                                    alt="Platform Placeholder"
                                    className="img-fluid mt-4 mb-4"
                                /> */}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="fl-card grayGradient">
{/* 
                                <img
                                    src="https://placehold.co/1026x423"
                                    alt="Platform Placeholder"
                                    className="img-fluid mt-4 mb-4"
                                /> */}

                            </div>
                        </div>
                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <div className="fl-bigLabel green ">Avap Specs</div>

                                </div>
                                <div className="fl-text">
                                    This extension simplifies publishing and managing API specifications.
                                    In collaboration with the Open API Initiative, this extension allows developers to effortlessly work with the OpenAPI Specification (OAS),
                                    streamlining API documentation within their IDE.
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-4 text-center">
                            {/* <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Complete API Lifecycle Management</h3>
                            <p className="fl-text">
                                Streamline building, testing, managing, and publishing APIs within Visual Studio Code.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Seamless Integration with AVAP</h3>
                            <p className="fl-text">
                                Effortlessly incorporate AVAP features into your coding workflow.
                            </p>
                        </div>
                        <div className="col-md-4 text-center">
                            {/* <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>

                    </div>
                </section>


                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-8">
                    <div className="row align-items-center">

                        <div className="col-md-4 text-center">
                            {/* <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Version Control and Publishing</h3>
                            <p className="fl-text">
                                Manage multiple API versions and publish across environments with ease.
                            </p>
                        </div>
                    </div>
                </section>


                <div className="bottomfl-wrapper-label position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom opacity-5" />

                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default AvapProduct;
