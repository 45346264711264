import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Dropdown, Button, Container, Spinner, Modal } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin, parseToNumberFormat } from '../../services/AmountService';
import VerifyCancelSubModal from '../Modals/VerifyCancelSubModal';
import PaymentConfirmationModal from '../Modals/paymentConfirmation';
import ConfirmDeleteProjectModal from '../Modals/ConfirmDeleteProjectModal';
import PaymentConfirmation from '../Modals/paymentConfirmation';
import PaymentSuccess from '../Modals/paymentSuccess';
import SessionService from '../../services/SessionsService';
import { useHistory } from 'react-router-dom';
import AddCreditCard from '../Modals/addCreditCard';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

import { faEllipsisV, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import subIcon from '../../assets/img/ui_icons/cld_sub-s.svg'
import activeIcon from '../../assets/img/ui_icons/active.svg'
import inactiveIcon from '../../assets/img/ui_icons/inavctive.svg'


type subProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  monthRateLimit: number,
  maxMonthRateLimit: number,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean
}


const SubscriptionContent: FunctionComponent<subProps> = (props: subProps) => {

  const { name, price, purchased, expires,
    monthRateLimit, maxMonthRateLimit,
    annualPayment, active, renovate } = props;

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);

  }
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);



  const deleteSubscription = async () => {
    try {
      setError('');
      setSuccess('');
      setLoading(true);
      const result = await ObexRequestHandler.delete('/subscription');
      const { success } = result;
      if (success) {
        setSuccess(success);
        setLoading(false);
        setTimeout(function () {
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      } else {
        setError(error)
      }
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
    history.push('/plan_subcription')
    window.location.reload();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    deleteSubscription();
  }

  return (

    <>

      <Col>
        <div className="customRow">
          <div className='td_centered' style={{minWidth:'20%'}}>{name}</div>
          <div className='td_centered' style={{minWidth:'13%'}}>Plan Subscription</div>
          <div className='td_centered' style={{maxWidth:'10%'}}>{purchased.toString().split(' ')[0]}</div>
          <div className='td_centered' style={{maxWidth:'10%'}}>{expires.toString().split(' ')[0]}</div>
          <div className='td_centered'>{parseToCoin(price)}</div>
          <div className='td_centered' style={{maxWidth:'9%'}}>{renovate ? 'Automatic' : 'Manual'}</div>
          <div className='td_centered'>{annualPayment ? 'Annual' : 'Monthly'}</div>
          <div className='td_centered' style={{maxWidth:'8.5%'}}>
            {active ? (
              <>
                <span className='active'>{/*<img src={activeIcon} alt="" />*/} Active</span>
              </>
            ) : (
              <>
                <span className='inactive'>{/*<img src={inactiveIcon} alt="" />*/} Inactive</span>
              </>
            )}
          </div>
          <div>


            <Dropdown className="my-auto p-0">
              <Dropdown.Toggle className="">
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item style={{ paddingLeft:0 }} key={1} disabled={renovate == false} onClick={handleModal}> Cancel Subscription</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Col>





      {/*} <div className="border-top my-2 mx-3"></div>*/}



      {/*}

            <Card.Text className="px-3 pt-3">


              <Row>
                <Col md="8">
                  <span>Monthly rate limit</span>
                </Col>
                <Col md="4" className="text-right">
                <span><span className="text-bold">{parseToNumberFormat(monthRateLimit)}</span> Requests</span>
                </Col>
              </Row>
            </Card.Text>
            <div className="border-top my-2 mx-3"></div>
            <Card.Text className="px-3 pt-3">
              <Row>
                <Col md="8">
                  <span>Max Monthly rate limit</span>
                </Col>
                <Col md="4" className="text-right">
                <span><span className="text-bold">{parseToNumberFormat(maxMonthRateLimit)}</span> Requests</span>
                </Col>
              </Row>
            </Card.Text>


*/}





      <VerifyCancelSubModal
        showModal={showModal}
        onCloseModal={handleModal}
        handleSubmit={handleSubmit}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name}
      />

    </>

  )
}


type extraProjectProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean,
  id: number,
  pendindPayments: boolean,
}

const SubExtraProject: FunctionComponent<extraProjectProps> = (props: extraProjectProps) => {

  const { name, price, purchased, expires, annualPayment, active, renovate,
    id, pendindPayments } = props;
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal)
    const history = useHistory();
    if (!showModal) history.push('/plan_subcription');
  };
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);


  const deleteExtraProjects = async (id) => {
    try {
      setError('');
      setSuccess('');
      setLoading(true);
      const result = await ObexRequestHandler.delete(`/extraprojects/${id}`);
      const { success } = result;
      if (success) {
        setSuccess(success);
        setLoading(false);
        setTimeout(function () {
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      } else {
        setError(error)
      }
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
    window.location.reload();
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    deleteExtraProjects(id);
  }

  return (
    <>


      <Col id='extras' md="12">

        <div className="customRow">
          <div className='td_centered' style={{minWidth:'20%'}}>{name}</div>
          <div className='td_centered' style={{minWidth:'13%'}}>Extra Project</div>
          <div className='td_centered' style={{maxWidth:'10%'}}>{purchased.toString().split(' ')[0]}</div>
          <div className='td_centered' style={{maxWidth:'10%'}}>{expires.toString().split(' ')[0]}</div>
          <div className='td_centered'>{parseToCoin(price)}</div>
          <div className='td_centered' style={{maxWidth:'9%'}}>{renovate ? 'Automatic' : 'Manual'}</div>
          <div className='td_centered'>{annualPayment ? 'Annual' : 'Monthly'}</div>
          <div className='td_centered' style={{maxWidth:'8.5%'}}>
            {active ? (
              <>
                <span className='active'>{/*<img src={activeIcon} alt="" />*/} Active</span>
              </>
            ) : (
              <>
                <span className='inactive'>{/*<img src={inactiveIcon} alt="" />*/} Inactive</span>
              </>
            )}
          </div>
          <div>


            <Dropdown className="my-auto p-0">
              <Dropdown.Toggle className="customDropdown">
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item key={1} disabled={pendindPayments} onClick={handleModal}> Cancel Subscription</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>


          </div>
        </div>
      </Col>




      <VerifyCancelSubModal
        showModal={showModal}
        onCloseModal={handleModal}
        handleSubmit={(e) => handleSubmit(e, id)}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name} />

    </>
  )
}

//

type extraDevelopersProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean,
  id: number,
  pendindPayments: boolean
}

const SubExtraDevelopers: FunctionComponent<extraDevelopersProps> = (props: extraDevelopersProps) => {

  const { name, price, purchased, expires, annualPayment, active, renovate,
    id, pendindPayments } = props;

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal(!showModal);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteExtraRequests = async (id) => {
    try {
      setError('');
      setLoading(true);
      const result = await ObexRequestHandler.delete(`/extrarequests/${id}`);
      const { success } = result;
      if (success) {
        setSuccess(success);
        setLoading(false);
        setTimeout(function () {
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      }
      setError(error)
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
    window.location.reload();
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(e);
    console.log(id)
    //deleteExtraRequests(id);
  }

  return (



    <>

      <Col id='extras' md="12">

        <div className="customRow">
          <div className='td_centered' style={{minWidth:'20%'}}>{name}</div>
          <div className='td_centered' style={{minWidth:'13%'}}>Developer Slot</div>
          <div className='td_centered' style={{maxWidth:'10%'}}>{purchased.toString().split(' ')[0]}</div>
          <div className='td_centered' style={{maxWidth:'10%'}}>{expires.toString().split(' ')[0]}</div>
          <div className='td_centered'>{parseToCoin(price)}</div>
          <div className='td_centered' style={{maxWidth:'9%'}}>{renovate ? 'Automatic' : 'Manual'}</div>
          <div className='td_centered'>{annualPayment ? 'Annual' : 'Monthly'}</div>
          <div className='td_centered' style={{maxWidth:'8.5%'}}>
            {active ? (
              <>
                <span className='active'>{/*<img src={activeIcon} alt="" />*/} Active</span>
              </>
            ) : (
              <>
                <span className='inactive'>{/*<img src={inactiveIcon} alt="" />*/} Inactive</span>
              </>
            )}
          </div>
          <div>


            <Dropdown className="my-auto p-0">
              <Dropdown.Toggle className="customDropdown">
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item key={1} disabled={pendindPayments} onClick={handleModal}> Cancel Subscription</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Col>

      <VerifyCancelSubModal
        showModal={showModal}
        onCloseModal={handleModal}
        handleSubmit={(e) => handleSubmit(e, id)}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name}
      />

    </>





  )
}

//

type extraRequestProps = {
  name: string,
  price: number,
  purchased: string,
  expires: string,
  annualPayment: boolean,
  active: boolean,
  renovate: boolean,
  id: number,
  pendindPayments: boolean
}

const SubExtraRequest: FunctionComponent<extraRequestProps> = (props: extraRequestProps) => {

  const { name, price, purchased, expires, annualPayment, active, renovate,
    id, pendindPayments } = props;

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => setShowModal(!showModal);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const deleteExtraRequests = async (id) => {
    try {
      setError('');
      setLoading(true);
      const result = await ObexRequestHandler.delete(`/extrarequests/${id}`);
      const { success } = result;
      if (success) {
        setSuccess(success);
        setLoading(false);
        setTimeout(function () {
          setSuccess('');
          setShowModal(false);
        }.bind(this), 1000);
      }
      setError(error)
    } catch (error) {
      console.error('ERROR WHILE SAVING ', error)
      setError(error.message || error);
    }
    setLoading(false);
    window.location.reload();
  }

  const handleSubmit = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(e);
    console.log(id)
    deleteExtraRequests(id);
  }

  return (
    <Row id='' className="mb-3">
      <Col id='request' md="12">


        <div className="customRow">
          <div className='td_centered' style={{minWidth:'20%'}}>{name}</div>
          <div className='td_centered' style={{minWidth:'13%'}}> Developer Slot</div>
          <div className='td_centered' style={{maxWidth:'10%'}}>{purchased.toString().split(' ')[0]}</div>
          <div className='td_centered' style={{maxWidth:'10%'}}>{expires.toString().split(' ')[0]}</div>
          <div className='td_centered'>{parseToCoin(price)}</div>
          <div className='td_centered' style={{maxWidth:'9%'}}>{renovate ? 'Automatic' : 'Manual'}</div>
          <div className='td_centered'>{annualPayment ? 'Annual' : 'Monthly'}</div>
          <div className='td_centered' style={{maxWidth:'8.5%'}}>
            {active ? (
              <>
                <span className='active'>{/*<img src={activeIcon} alt="" />*/} Active</span>
              </>
            ) : (
              <>
                <span className='inactive'>{/*<img src={inactiveIcon} alt="" />*/} Inactive</span>
              </>
            )}
          </div>
          <div>


          <Dropdown className="my-auto p-0">
              <Dropdown.Toggle className="customDropdown">
                <FontAwesomeIcon icon={faEllipsisV} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item key={1} disabled={pendindPayments} onClick={handleModal}> Cancel Subscription</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Col>

      <VerifyCancelSubModal
        showModal={showModal}
        onCloseModal={handleModal}
        handleSubmit={(e) => handleSubmit(e, id)}
        error={error}
        success={success}
        loading={loading}
        name_to_check={name}
      />
    </Row>
  )
}


type planSubcriptionProps = {
  sider: any
}

const PlanSubscriptionContent: FunctionComponent<planSubcriptionProps> = (props: planSubcriptionProps) => {

  const [subContent, setSubContent] = useState(<div></div>);
  const [projectsPlans, setProjectsPlans] = useState([]);
  const [requestsPlans, setRequestPlans] = useState([]);
  const [stillLoaded, setStillLoaded] = useState(true);
  const [ExtraDevsSlots, setextraDevsSlots] = useState([]);
  const [PendingInvoice, setPendingInvoice] = useState({ invoice: [], total: 0 });
  const [Pending, setPending] = useState(false);
  const [shoWDel, setShowDel] = useState(false)

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentEnable, setPaymentMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [pendindPayments, setpendingPayments] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [cards_, setCards] = useState([]);
  const [card_id_stripe, setCardID] = useState('');
  const [newadded_card, setAddedCard] = useState('');

  //customTable
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;


  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if (cards_.some(e => e.id == c.id)) {
            //console.log('exists');
          } else {
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);

    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }
  const handleModal = () => {
    setShowModal(!showModal);
    //onCloseAction();
    getCards();

  };


  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value == 'addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();

  }

  // const [priceSuscription, SetPrecio] = useState(0);

  const whenClosing = () => {
    setShowDel(false);
    //getData();
  }
  const history = useHistory();


  const getCurrentSubcription = async () => {


    const resulta = await ObexRequestHandler.get('/check_renovated_suscription', {}, true);
    setPending(resulta.data.renovated);
    /*
    const { data } = resulta;

      const { renovated } = data;
      */


    //wast.style.display = 'block';


    /*
    if (!renovated) {
      wast.style.display = 'block';
      */
    const resultPending = (await ObexRequestHandler.get('/get_pending_invoice', {}, true)).data || {};

    console.log('PENDING');
    console.log(resultPending);
    setPendingInvoice(resultPending);
    setpendingPayments(!resultPending);
    /*    }
  */
    const result = (await ObexRequestHandler.get('/purchased_services', {}, true)).data || {};
    // console.log(result);
    if (result) {
      const { subscription, extraProjectsPlans, extraRequestsPlans, extraDevsSlots } = result;
      let suscriptionPrice = 0;

      if (!subscription.annual_payment) {
        /*SetPrecio(subscription.monthly_price);*/suscriptionPrice = subscription.monthly_price;
      } else {
        /*SetPrecio(subscription.anual_price * 10);*/suscriptionPrice = (subscription.annual_price * 10);
      }

      setSubContent(<SubscriptionContent
        name={subscription.name}
        price={suscriptionPrice}
        purchased={subscription.purchased}
        expires={subscription.expires}
        monthRateLimit={subscription.month_rate_limit}
        maxMonthRateLimit={subscription.max_month_rate_limit}
        annualPayment={subscription.annual_payment}
        active={subscription.active}
        renovate={subscription.renovate} />);
      setProjectsPlans(extraProjectsPlans);
      setRequestPlans(extraRequestsPlans);
      setextraDevsSlots(extraDevsSlots);
    }
    setStillLoaded(false);
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const makePayment = async () => {
    try {
      startPayment();
      const payload = { Invoice: PendingInvoice, card_stripe_id: card_id_stripe };
      const result = await ObexRequestHandler.post('/pay_pending_invoice', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentMode(false);

      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getCurrentSubcription();
    getCards();

    // Simular obtener las filas desde el servidor o estado
    const allRows = Array.from(document.querySelectorAll('.dataWrapper .customRow'));
    setRows(allRows);
  }, [])


  const totalPages = Math.ceil(rows.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  const OnSuccesAction = () => {
    // SessionService.renewSession('/plan_subcription');

    history.push('/cloud_dashboard');

    //onCloseAction();
  }

  return (
    <>
      <BrowserView>
        <DashLayout sider={props.sider} active={'plan_subcription'}>


          <Row id='topHeader' className='mb-5'>
            <Col >
              <div className='d-flex flex-column'>
                <div className="cld_wrapperTitle">
                  <img src={subIcon} alt="icon dashboard" />
                  <span className="cld_title w-100 margin-y-24px">
                  <span style={{ color:'#949498', fontSize:'14px' }}>Plan & Usage </span>/ Plans and Suscriptions 
                    
                  </span>
                </div>
                <div className="customBorder"></div>
                {/* <span className="cld_legend w-100">
                  View and manage your current membership plans and add-ons for a personalized service.
                </span> */}
              </div>
            </Col>
          </Row>



          {stillLoaded ?
            <Container className="vh-100 d-flex flex-column vw-100">
              <Row className="h-100" >
                <Col md="1" className="mx-auto d-flex align-items-center">
                  <Spinner animation="border" />
                </Col>
              </Row>
            </Container>
            :
            <>
              <Container fluid className='cld_borderWrapper'>
                <div className="dataWrapper">
                  <Row>
                    <Col>
                      <div className="customRow customHeader">
                        <div style={{minWidth:'20%'}}>Item</div>
                        <div style={{minWidth:'13%'}}>Type</div>
                        <div style={{maxWidth:'10%'}}>Purchased</div>
                        <div style={{maxWidth:'10%'}}>Expiration</div>
                        <div>Cost</div>
                        <div style={{maxWidth:'9%'}}>Renovation</div>
                        <div>Schedule</div>
                        <div style={{maxWidth:'8.5%'}}>Status</div>
                        <div>Actions</div>
                      </div>
                    </Col>
                  </Row>

                  {/* Implementación de paginación */}
                  {[
                    subContent,
                    ...projectsPlans.map((plan) => (
                      <SubExtraProject
                        key={'SubExtraProject' + plan.id}
                        name={plan.title}
                        price={plan.monthly_price}
                        purchased={plan.purchased}
                        expires={plan.expires}
                        annualPayment={plan.annual_payment}
                        active={plan.active}
                        renovate={plan.renovate}
                        id={plan.purchase_id}
                        pendindPayments={pendindPayments}
                      />
                    )),
                    ...requestsPlans.map((plan) => (
                      <SubExtraRequest
                        key={'SubExtraRequest' + plan.id}
                        name={plan.title}
                        price={plan.monthly_price}
                        purchased={plan.purchased}
                        expires={plan.expires}
                        annualPayment={plan.annual_payment}
                        active={plan.active}
                        renovate={plan.renovate}
                        id={plan.purchase_id}
                        pendindPayments={pendindPayments}
                      />
                    )),
                    ...ExtraDevsSlots.map((plan) => (
                      <SubExtraDevelopers
                        key={'SubExtraDevelopers' + plan.id}
                        name={plan.title}
                        price={plan.monthly_price}
                        purchased={plan.purchased}
                        expires={plan.expires}
                        annualPayment={plan.annual_payment}
                        active={plan.active}
                        renovate={plan.renovate}
                        id={plan.purchase_id}
                        pendindPayments={pendindPayments}
                      />
                    )),
                  ]
                    .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                    .map((row, index) => (
                      <Row key={index}>{row}</Row>
                    ))}

                  {/* Controles de paginación */}
                  {projectsPlans.length + requestsPlans.length + ExtraDevsSlots.length > itemsPerPage && (
                    <Row>
                      <Col>
                        <div className="pagination">
                          {Array.from({
                            length: Math.ceil(
                              (projectsPlans.length + requestsPlans.length + ExtraDevsSlots.length) / itemsPerPage
                            ),
                          }).map((_, pageIndex) => (
                            <button
                              key={pageIndex}
                              className={pageIndex === currentPage ? 'active' : ''}
                              onClick={() => setCurrentPage(pageIndex)}
                            >
                              {pageIndex + 1}
                            </button>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>

              </Container>



              <Modal dialogClassName="addons-dialog themeDialog w-75 obex-dialog"
                show={shoWDel}
                onHide={whenClosing}
                animation={false}>
                <Modal.Header closeButton className="border-0 pb-0">
                  <Modal.Title className="text-center w-100">
                    <Row>
                      <Col className="addons-title">
                        <span >Pay your outdated suscriptions</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="10" className="mx-auto text-center modal-subtitle">

                        {paymentEnable ?
                          <span className="text-center d-block">Review your payment and proceed.</span> :
                          <span className="text-center d-block">Choose your package and continue to checkout.</span>
                        }
                      </Col>
                    </Row>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {paymentEnable ?
                    <PaymentConfirmation
                      actionSubmit={makePayment}
                      handleGoBack={() => { setShowDel(false); }}
                      nameServiceSelected={`Paying ${PendingInvoice.total} for suscription.`}
                      handleAnnualPayment={null}
                      amountMonth={PendingInvoice.total}
                      amountYear={0}
                      processingPayment={loading}
                      success={success}
                      error={error}
                      spanish={false}
                    />
                    : (paymentSuccess ? <PaymentSuccess blue={true} onCloseAction={OnSuccesAction} paymentDescription={''} paymentType={''} /> : <></>)
                  }
                </Modal.Body>
              </Modal>
              {showModal && <AddCreditCard show={showModal} handleClose={handleModal} isGreen={false} spanish={false} />}



            </>
          }

        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default PlanSubscriptionContent;