import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/Features-1.png'
import features_2 from '../../assets/img/landingPage/Features-2.png'
import features_3 from '../../assets/img/landingPage/Features-3.png'



import logo from '../../assets/img/logos/AVAP_Marketplace_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'



const VersionControlProduct = () => {
    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center py-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Designed for powerful API Products</h1>
                    <p className="fl-subtitle">
                        Discover, integrate, and accelerate your projects
                    </p>
                    {/* <a className="glowBtn-dark" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a> */}
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-title mb-3">
                                A dynamic platform for API discovery and innovation
                            </p>
                            <p className="fl-text">
                                AVAP Marketplace is an integrated platform that showcases a wide range of API products, connectors, and other tools. This innovative marketplace empowers developers to certify, publish, and monetize their API Products while discovering valuable tools that enhance their applications and drive efficiency.
                            </p>
                        </div>
                    </div>

                </section>



                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">API Product Listing</h3>
                            <p className="fl-text">
                                Allows API developers to publish their API Products, from AVAP Cloud and AVAP Collaborative Network, for public display and commercial use, offering full control.

                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Developer Profiles</h3>
                            <p className="fl-text">
                                Adds developer information from AVAP ID, including contact details, API Project names, and more, ensuring accurate representation across the platform.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Comprehensive Certification Process</h3>
                            <p className="fl-text">
                                Offers a structured API Product certification workflow, from initial draft through review, approval, and public release, ensuring quality and compliance.
                            </p>
                        </div>

                    </div>
                </section>



                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                    <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Role-Based Access</h3>
                            <p className="fl-text">
                                Restricts access to the private sections of the marketplace based on AVAP ID roles, ensuring that only authorized users can manage, publish, or interact with API Products.

                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>
              
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                       

                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>

                        <div className="col-md-6 pl-5">
                        <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Public and Private Portals</h3>
                            <p className="fl-text">
                                Provides both public-facing API listings for general browsing and a private portal for API developers to manage their products, certification states, and sales.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                    <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Flexible Commercialization Models</h3>
                            <p className="fl-text">
                                Supports various economic models, such as monthly or annual subscription-based, with more complex models in future iterations.
                            </p>
                        </div>


                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>


                        
                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Wide Selection of API Products and Tools
                                </p>
                                <p className="fl-text">
                                    Discover and integrate a diverse range of high-quality APIs, accelerating development and saving time. <br />
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Easy Certification and Monetization
                                </p>
                                <p className="fl-text">
                                    A streamlined certification process and flexible commercialization models allow developers to publish and monetize API products with ease.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Fostering Collaboration and Innovation
                                </p>
                                <p className="fl-text">
                                    AVAP Marketplace enables community-driven innovation, encouraging collaboration while ensuring secure management of API products.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
