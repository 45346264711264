import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import icon_name from "../../../assets/img/newDashboard/icon_name.png";
import icono_birthday from "../../../assets/img/newDashboard/icono_birthday.png";
import icono_country from "../../../assets/img/newDashboard/icono_country.png";
import icono_language from "../../../assets/img/newDashboard/icono_language.png";
//import splasLogo from '../../../src/assets/img/ilustracion_splash.png';
import splasLogo from '../../../../src/assets/img/ilustracion_splash_id@2x.png';
import moment from 'moment';
import "./PersonalInformation.css";
import "./../NewDashboard.css"
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { Button, Modal, Toast, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { UpdatedName } from '../../../redux/slices/updateSidebarGreen';
import SessionService from '../../../services/SessionsService';
// import { isUpdatedName } from '../../../redux/slices/updateSidebarGreen';
import Cookies from 'universal-cookie';

const PersonalInformation = () => {
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [days, setDays] = useState([]);
  const [locality, setLocality] = useState('');
  const [language_id, setLanguageId] = useState(0);
  const [day, setDay] = useState(0);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(1);
  const [first_name, setFirstName] = useState('');
  const [middle_name, setMiddleName] = useState('');
  const [last_name, setLastName] = useState('');
  const [newInfo, setNewInfo] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [whereWantGo, setWhereWantGo] = useState("");

  const dispatch = useDispatch();  

  const toggleShowToast = () => setShowToast(!showToast);

  let countriesList = countries.map(c => <option value={c.country_code} selected={locality === c.country_code}>{c.country}</option>);
  countriesList.push(<option value={-1}>-</option>)
  
  const languagesList = languages.map(c => c.id === 2 ? <option value={c.id} selected={language_id === c.id}>{c.language}</option>:<></>);
  let daysList = days.map(c => <option value={c} selected={day === c}>{c}</option>);
  daysList.push(<option value={0}>-</option>)
  let yearsList = years.map(c => <option value={c} selected={year === c}>{c}</option>);
  yearsList.push(<option value={0}>-</option>)
  const { path, url } = useRouteMatch();
  const history = useHistory();








  const [showSplash, setShowSplash] = useState(false);
  const [showSplash2, setShowSplash2] = useState(false);
  const [showSplash3, setShowSplash3] = useState(false);
  const [showSplash4, setShowSplash4] = useState(false);
  const [showSplash5, setShowSplash5] = useState(false);
  const [showSplash6, setShowSplash6] = useState(false);
  const [showSplash7, setShowSplash7] = useState(false);
  const [showSplash8, setShowSplash8] = useState(false);
  const [showSplash9, setShowSplash9] = useState(false);
  const [showSplash10, setShowSplash10] = useState(false);
  const [showSplash11, setShowSplash11] = useState(false);
  const [showSplash12, setShowSplash12] = useState(false);
  const [showSplash13, setShowSplash13] = useState(false);
  const [showSplash14, setShowSplash14] = useState(false);
  const [showSplash15, setShowSplash15] = useState(false);
  const [showSplash16, setShowSplash16] = useState(false);
  const [tutorialPref, setTutorialpref] = useState(false);
  const handleCloseE = () => {

    setShowSplash(false)
  
  }
  const handleClose2a = () => {setShowSplash2(false); SessionService.TUTORIAL_SHOWED_ID = true; }

  const handleClose2 = () => {setShowSplash2(false);
                              setShowSplash3(true) ; SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow = () => setShowSplash2(!showSplash2);
  
  const handleClose3 = () => {setShowSplash3(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow2 = () => setShowSplash3(!showSplash3);

  const handleClose4 = () => {setShowSplash4(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow3 = () => setShowSplash4(!showSplash4);

  const handleClose5 = () => {setShowSplash5(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow4 = () => setShowSplash5(!showSplash5);

  const handleClose6 = () => {setShowSplash6(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow5 = () => setShowSplash6(!showSplash6);

  const handleClose7 = () => {setShowSplash7(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow6 = () => setShowSplash7(!showSplash7);

  const handleClose8 = () => {setShowSplash8(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow7 = () => setShowSplash8(!showSplash8);

  const handleClose9 = () => {setShowSplash9(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow8 = () => setShowSplash9(!showSplash9);

  const handleClose10 = () => {setShowSplash10(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow9 = () => setShowSplash10(!showSplash10);

  const handleClose11 = () => {setShowSplash11(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow10 = () => setShowSplash11(!showSplash11);

  const handleClose12 = () => {setShowSplash12(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow11 = () => setShowSplash12(!showSplash12);

  const handleClose13 = () => {setShowSplash13(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow12 = () => setShowSplash13(!showSplash13);

  const handleClose14 = () => {setShowSplash14(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow13 = () => setShowSplash14(!showSplash14);

  const handleClose15 = () => {setShowSplash15(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow14 = () => setShowSplash15(!showSplash15);


  const handleClose16 = () => {setShowSplash16(false); SessionService.TUTORIAL_SHOWED_ID = true; }
  const handleShow15 = () => setShowSplash16(!showSplash16);


  const startTutorial = () =>{


    handleCloseE();
    handleShow();
  }


  const updateCookies = (e) => {
    const isChecked = e.target.checked;
    setTutorialpref(!isChecked); 
    const cookies = new Cookies();
    cookies.set('tutorial_id', !isChecked, { path: '/' }); 
    console.log(cookies.get('tutorial_id')); 
  };

  /*
const getVerified = async () => {
    SessionService.RENOVATEDSUSCRIPTION =true;
    const result = await ObexRequestHandler.get('/check_renovated_suscription');
    
    const { success, data } = result;
    if (success) {
      const { renovated } = data;
      
      const wast = document.getElementById('nopayment') as HTMLDivElement;
      if (!renovated) {
        wast.style.display = 'block';
        SessionService.RENOVATEDSUSCRIPTION = renovated;
      }
    }
}
*/
useEffect(() => {
  const cookies = new Cookies();
  let TutorialPreference = cookies.get('tutorial_id');
  if (TutorialPreference == undefined) TutorialPreference = 'true';
  const tuto_pref = (String(TutorialPreference).toLowerCase() === 'true');


  setTutorialpref(tuto_pref);
  //getVerified();
  if(SessionService.TUTORIAL_SHOWED_ID == false) {
    setShowSplash(true);
    SessionService.TUTORIAL_SHOWED_ID = true; 
  }
}, []);










  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );

  const getPersonalInformation = async () => {
    try {
      const result = (await ObexRequestHandler.get('/get_personal_info')).data;
      const date_ = new Date(result.birthdate);
      
      setDay(date_.getDate());
      setMonth(date_.getMonth() + 1);
      setYear(date_.getFullYear());
      setLanguageId(result.language ? result.language : 0);
      setLocality(result.region ? result.region : -1);
      setFirstName(result.first_name ? result.first_name : "");
      setMiddleName(result.middle_name ? result.middle_name : "");
      setLastName(result.last_name ? result.last_name : "");
    } catch (error) {
      console.error('ERROR FETCHING PERSONAL INFO', error);
    }
  };

  const postNewInfo = async (newName, newMiddle, newLastName) => {
    try {
      const result = (await ObexRequestHandler.post('/set_name_info', {
        first_name: newName,
        middle_name: newMiddle,
        last_name: newLastName
      })).data;
      setNewInfo(result);
    } catch (error) {
      console.error('ERROR', error);
    }
  };

  const getLanguages = async () => {
    try {
      const result = (await ObexRequestHandler.get('/get_languages')).data;
      setLanguages(result);
    } catch (error) {
      console.error('ERROR FETCHING LANGUAGES', error);
    }
  };

  const getCountries = async () => {
    try {
      const result = (await ObexRequestHandler.get('/countries')).data;
      setCountries(result);
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES', error);
    }
  };

  const getDays = (manual_month = null, manual_year = null) => {
    if (manual_month === null) {
      manual_month = month;
    }
    if (manual_year === null) {
      manual_year = year;
    }
    let moment_str = manual_year + "-" + manual_month;
    var maxDays = moment(moment_str, "YYYY-MM").daysInMonth();
    let days_array = arrayRange(1, maxDays, 1);
    setDays(days_array);
  };

  const getYears = async () => {
    let years_array = arrayRange(moment().year(), 1900, -1);
    setYears(years_array);
  };

  const updateBirhtdate = async (day_ = day, month_ = month, year_ = year) => {
    try {
      var date_str = year_ + "-" + month_ + "-" + day_;
      const payload = { birthdate: date_str };
      await ObexRequestHandler.post('/set_birthday', payload);
    } catch (error) {
      alert('ERROR CHANGING BIRTHDATE');
      console.error('ERROR CHANGING BIRTHDATE', error);
    }
  };

  const updateCountry = async (country_) => {
    try {
      const payload = { region: country_ };
      await ObexRequestHandler.post('/set_region', payload);
    } catch (error) {
      alert('ERROR CHANGING REGION');
      console.error('ERROR CHANGING REGION', error);
    }
  };

  const updateLanguage = async (language_) => {
    try {
      const payload = { language_id: language_ };
      await ObexRequestHandler.post('/set_language', payload);
    } catch (error) {
      alert('ERROR CHANGING LANGUAGE');
      console.error('ERROR CHANGING LANGUAGE', error);
    }
  };

  const handleCountry = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    setLocality(value);
    setShowToast(true);
    setShouldBlockNavigation(true)
    
  };

  const handleLanguage = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    setLanguageId(value);
    setShowToast(true);
    setShouldBlockNavigation(true)
  };

  const handleDay = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    setShowToast(true);
    setShouldBlockNavigation(true)
    setDay(value);
  };

  const handleMonth = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    await setMonth(value);
    getDays(value);
    setShowToast(true);
    setShouldBlockNavigation(true)
  };

  const handleYear = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    await setYear(value);
    getDays(month, value);
    setShowToast(true);
    setShouldBlockNavigation(true)
  };

  const handleFirstNameChange = (e) => {
    const newValue = e.target.value;
    setShowToast(true);
    setShouldBlockNavigation(true)
    setFirstName(newValue);
  };

  const handleMiddleNameChange = (e) => {
    const newValue = e.target.value;
    setShowToast(true);
    setShouldBlockNavigation(true)
    setMiddleName(newValue);
  };

  const handleLastNameChange = (e) => {
    const newValue = e.target.value;
    setShowToast(true);
    setShouldBlockNavigation(true)
    setLastName(newValue);
  };

   const applyChanges = () => {
    
    postNewInfo(first_name, middle_name, last_name);
    updateBirhtdate(day, month, year);
    updateCountry(locality);
    updateLanguage(language_id);
    setShowToast(false);
    setShouldBlockNavigation(false)
    dispatch(UpdatedName(true));
  };

  const NoapplyChanges = async() => {
    
    setShowModal(false); // Ocultar el modal
    setShowToast(false);
    await setShouldBlockNavigation(false)
  };

  const OutOfInputWithEnter = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };


  const handleConfirmNavigation = async () => {
    // debugger
    setShowModal(false); // Ocultar el modal
    setShowToast(false);
    await setShouldBlockNavigation(false)
    history.push(whereWantGo);
  };



  useEffect(() => {
    getYears();
    getCountries();
    getLanguages();
    getDays();
    getPersonalInformation();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Verificar si se debe bloquear la navegación
    // debugger
    if (shouldBlockNavigation==true) {
      // Bloquear la navegación al componente
      const unblock = history.block((location, action) => {
        setShowModal(true); // Mostrar el modal al intentar navegar

        // Obtener la ruta hacia donde se intenta navegar
        const destinationPath = location.pathname;
        // console.log('Intento de navegación hacia:', destinationPath);

        setWhereWantGo(destinationPath);

        return false; // Devolver false para bloquear la navegación
      });

      return () => {
        // Desbloquear la navegación al salir del componente
        unblock(); // Llamar a unblock para desbloquear la navegación
      };
    }
  }, [history, showToast]);

  return (
    <div>




  <Modal className='tip1id'
   show={showSplash2} 
   animation={false}
   onHide={handleClose2a}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Avatar</div>
        <div style={{fontSize:'12px'}}>
        Select the image that best represents you and customize your community avatar. This image can be used across various services and accompany content you might participate in, such as blog articles, forum questions and answers, or emails.
        </div>
          <button onClick={()=>{handleClose2a()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button onClick={()=>{handleClose2()}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip2id'
   show={showSplash3} 
   animation={false}
   onHide={handleClose3}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Personal Information</div>
        <div style={{fontSize:'12px'}}>
        Provide your personal and contact details to ensure a complete and personalized experience on the platform.
        </div>
        <button onClick={()=>{handleClose3()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button onClick={()=>{handleClose3(); setShowSplash4(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip3id'
   show={showSplash4} 
   animation={false}
   onHide={handleClose4}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Sign-in and Security</div>
        <div style={{fontSize:'12px'}}>
        Manage your security options and access your credentials file. The credentials file may be required when running products and initiating services. All products and services require your credentials and an internet connection to function properly.
        </div>
        <button onClick={()=>{handleClose4()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button onClick={()=>{handleClose4(); setShowSplash5(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip4id'
   show={showSplash5} 
   animation={false}
   onHide={handleClose5}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Licenses and Subscription </div>
        <div style={{fontSize:'12px'}}>
        Activate licenses and memberships for any AVAP Framework products. Discover all products with free licenses and explore the world of virtual APIs.
        </div>
        <button onClick={()=>{handleClose5()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button  onClick={()=>{handleClose5(); setShowSplash6(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip5id'
   show={showSplash6} 
   animation={false}
   onHide={handleClose6}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Downloads</div>
        <div style={{fontSize:'12px'}}>
        Download any of the products within AVAP Framework. In the downloads center, you can find any product, regardless of its license type. Remember that all products have a free version (except AVAP Gateway Advanced).
        </div>
        <button onClick={()=>{handleClose6()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button  onClick={()=>{handleClose6(); setShowSplash7(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip6id'
   show={showSplash7} 
   animation={false}
   onHide={handleClose7}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>API Project Granted</div>
        <div style={{fontSize:'12px'}}>
        Access all information about your active projects in one place. Here, you’ll find a summary of all your API Projects: standalone, cloud, peer-to-peer (AVAP Collaborative Network), or enterprise.
        </div>
        <button onClick={()=>{handleClose7()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button onClick={()=>{handleClose7(); setShowSplash8(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip7id'
   show={showSplash8} 
   animation={false}
   onHide={handleClose8}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Manage Licenses and Memberships</div>
        <div style={{fontSize:'12px'}}>
        Manage all your licenses and memberships in one place. Optimize your resources as needed. Activate and deactivate licenses and memberships according to your needs.

        </div>
        <button onClick={()=>{handleClose8()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button onClick={()=>{handleClose8(); setShowSplash9(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip8id'
   show={showSplash9} 
   animation={false}
   onHide={handleClose9}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Payment Methods</div>
        <div style={{fontSize:'12px'}}>
        Manage your payment methods, organize your cards, and set your payment preferences. Automate payments for your AVAP Cloud usage and other services that require it.
        </div>
        <button onClick={()=>{handleClose9()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button onClick={()=>{handleClose9(); setShowSplash10(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip9id'
   show={showSplash10} 
   animation={false}
   onHide={handleClose10}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Billing</div>
        <div style={{fontSize:'12px'}}>
        Review the history of your transactions: licenses, memberships, and usage. View your invoices, print, and forward them as PDFs via email or share through other channels. * Some services referenced here will be available soon.
        </div>
        <button onClick={()=>{handleClose10()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button  onClick={()=>{handleClose10(); setShowSplash11(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip10id'
   show={showSplash11} 
   animation={false}
   onHide={handleClose11}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Tutorial</div>
        <div style={{fontSize:'12px'}}>
        Run this tutorial whenever you wish. Activate or deactivate it at the start of each session using the checkbox that displays the message: Don’t show this window again.
        </div>
        <button onClick={()=>{handleClose11()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button  onClick={()=>{handleClose11(); setShowSplash12(true)}}  style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip11id'
   show={showSplash12} 
   animation={false}
   onHide={handleClose12}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Sign out</div>
        <div style={{fontSize:'12px'}}>
        Log out of your session to avoid security issues. It is a good practice to sign out if you move to a different location or remain inactive for an extended period.
        </div>
        {/*<button onClick={()=>{handleClose12()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>*/}
          <button  onClick={()=>{handleClose12(); }} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip12id'
   show={showSplash13} 
   animation={false}
   onHide={handleClose13}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Upgrade Plan</div>
        <div style={{fontSize:'12px'}}>
        Upgrade your membership and switch to Business or Enterprise based on your needs. Obtain new resources without service downtime.
        </div>
        <button onClick={()=>{handleClose13()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button  onClick={()=>{handleClose13(); setShowSplash14(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip13id'
   show={showSplash14} 
   animation={false}
   onHide={handleClose14}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Billing</div>
        <div style={{fontSize:'12px'}}>
        At any time, you can view the invoices generated for your membership to keep track of expenses. This includes not only your membership but also all contracted resources.
        </div>
        <button onClick={()=>{handleClose14()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button  onClick={()=>{handleClose14(); setShowSplash15(true)}}  style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip14id'
   show={showSplash15} 
   animation={false}
   onHide={handleClose15}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Tutorial</div>
        <div style={{fontSize:'12px'}}>
        Launch this tutorial at any time. It allows you to access the tutorial and stay updated with any changes. Remember that this tutorial is dynamic and constantly updated!.
        </div>
        <button onClick={()=>{handleClose15()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-green'>Skip</button>
          <button  onClick={()=>{handleClose15(); setShowSplash16(true)}}  style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>


  <Modal className='tip15id'
   show={showSplash16} 
   animation={false}
   onHide={handleClose16}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#026b50',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Sign out </div>
        <div style={{fontSize:'12px'}}>
        Log out of your session to avoid security issues. It is a good practice to sign out if you move to a different location or remain inactive for an extended period.
        </div>

          <button  onClick={()=>{handleClose16()}}  style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-green'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>


   <Modal dialogClassName="addons-dialog w-40 obex-dialog" style={{ with:'40%', marginTop:'0em', paddingTop:'7em' }} container={document.getElementById('avapIdWrapper')}
      show={showSplash} 
      onHide={handleCloseE}
      
      animation={false}>
      <Modal.Header closeButton className="border-0 pb-0" >
        <div style={{ width:'100%', textAlign:'center' }}>
        <Image 
        style={{
          paddingTop:'3em',
          marginLeft:'auto',
          marginRight:'auto',
          width:'30%'
        }}
        src={splasLogo}>

        </Image>

        <div className='' style={{marginTop:'3rem'}}>
        <h3 className='textBold'>Welcome to AVAP ID</h3>
        </div>
        <div style={{marginTop:'-10px', marginBottom:'2rem'}}>
          Let's find out how we can help you, in less than a minute
        </div>
        <div>
          <button onClick={startTutorial} className='custom-button textBold'>Get Started!</button>
        </div>
        <div style={{display:'flex', marginTop:'2rem'}}>
        
        
        <div style={{fontSize:'10px', marginLeft:'auto', marginRight:'auto'}}>
      
        <input checked={!tutorialPref} style={{marginRight:'10px'}} type="checkbox" onChange={updateCookies}/>
          Don't show again this window</div>
        
        </div>
        </div>
      </Modal.Header>
      <Modal.Body>

</Modal.Body>
</Modal>



      
      <div className='custom_warning'>
        <Toast show={showToast} onClose={toggleShowToast} className="custom_toast">

        <Toast.Body className='custom_toast_body'>
            <div className='d-flex justify-content-end'>
              <div className='mr-2'>Data modified, would you like to save it?</div>
              <div className='mr-2 textBold' onClick={applyChanges} style={{cursor:"pointer"}}>Yes</div>
              <div className='textBold' onClick={NoapplyChanges} style={{cursor:"pointer"}}>No</div>
            </div>
          </Toast.Body>
        </Toast>
      </div>
      {/* <button onClick={blockNavigation}>Bloquear Navegación</button> */}
      <h2 className='textBold marginBottom2rem'>Personal Information
      </h2>
      <p className='fontText3 colorBlackPI marginBottom2rem'>Manage your information, including phone numbers and email addresses where you can be reached.</p>
      <div className="grid-container" style={{width:'65vw'}}>
        <div className="boxShadowCard borderRadius ">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icon_name} alt="" width={"100%"} height={"100%"} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Name</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column boxShadowCardBottom'>
                <div className='fontText1'>First Name</div>
                <input
                  className='fontText2 colorBlackPI input-reset'
                  type="text"
                  placeholder={first_name}
                  value={first_name}
                  onChange={handleFirstNameChange}
                  onKeyDown={OutOfInputWithEnter}
                />
              </div>
              <div className='d-flex flex-column boxShadowCardBottom pt-3'>
                <div className='fontText1'>Middle Name (optional)</div>
                <input
                  className='fontText2 colorBlackPI input-reset'
                  type="text"
                  placeholder={middle_name}
                  value={middle_name}
                  onChange={handleMiddleNameChange}
                  onKeyDown={OutOfInputWithEnter}
                />
              </div>
              <div className='d-flex flex-column boxShadowCardBottom pt-3 '>
                <div className='fontText1'>Last Name</div>
                <input
                  className='fontText2 colorBlackPI input-reset'
                  type="text"
                  placeholder={last_name}
                  value={last_name}
                  onChange={handleLastNameChange}
                  onKeyDown={OutOfInputWithEnter}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="boxShadowCard borderRadius ">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icono_birthday} alt="" width={"100%"} height={"100%"} style={{ marginTop: "1px" }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Date of birth</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column mb-3'>
                <div className='fontText1'>Month</div>
                <div className="select-container">
                  <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleMonth} value={year!=1969? month:13}>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                    <option value="13">-</option>
                  </select>
                </div>
              </div>
              <div className='d-flex flex-row'>
                <div className='d-flex flex-column widthSelect1 mr-2'>
                  <div className='fontText1'>Day</div>
                  <div className="select-container">
                    <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleDay} value={year!=1969 ? day:0}>
                      {daysList}
                    </select>
                  </div>
                </div>
                <div className='d-flex flex-column widthSelect1 '>
                  <div className='fontText1'>Year</div>
                  <div className="select-container">
                    <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleYear} value={year!=1969 ? year: 0}>
                      {yearsList}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="boxShadowCard borderRadius ">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icono_country} alt="" width={'100%'} height={'100%'} style={{ marginTop: '2px' }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Country/Region</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column'>
                <div style={{ height:'82px' }} className='colorBlackPI mb-3'>This is the country or region you selected for your account.</div>
                <div className="select-container">
                  <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleCountry} value={locality}>
                    {countriesList}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="boxShadowCard borderRadius ">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icono_language} alt="" width={"100%"} height={"100%"} style={{ marginTop: "4px" }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Language</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column'>
                <div style={{ height:'82px' }} className='colorBlackPI mb-3 alingLanguage'>This language will be used for email and other communications from AVAP.</div>
                <div className="select-container">
                  <select className="form-select p-2 borderRadius backgroundSelect" aria-label="Default select example" onChange={handleLanguage} value={language_id}>
                    {languagesList}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal style={{ marginTop:'7rem' }} show={showModal} onHide={handleClose}>
        <Modal.Body className='colorBlack'>Do you want leave without saving your changes?</Modal.Body>
        <Modal.Footer>
          <button className="btn custom-button  textBold" onClick={handleConfirmNavigation}>
            Yes
          </button>

          <button className="btn custom-button  textBold" onClick={handleClose}>
            No
          </button>
          
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PersonalInformation;