import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Row, Col, Form, Container, Button, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { useHistory } from 'react-router-dom';
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch';

type AddProjectModalProps = {
  shown?: boolean; // Opcional
  onCloseAction?: () => void; // Opcional
  onSuccessCreation?: () => void; // Opcional
  setAvailable?: (value: boolean) => void; // Opcional
  opened: any
};



const AddProjectForm: FunctionComponent<AddProjectModalProps> = ({ opened, shown, onCloseAction, onSuccessCreation, setAvailable }) => {
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [currencyId, setCurrencyId] = useState('USD');
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [customDb, setCustomDb] = useState(false);
  const [dbHost, setDbHost] = useState('');
  const [dbPort, setDbPort] = useState('5432');
  const [dbUser, setDbUser] = useState('');
  const [dbPass, setDbPass] = useState('');

    const [ListSelP, addListSelP] = useState([])
    const [selectedGAR, setSelectedGAR] = useState([])
    const [teams, setTeams] = useState([]);


    const getTeams = async (filtro) => {
      try {
        setTeams([]);
        setLoading(true);
        const projects = (await ObexRequestHandler.get(`/developer_teams?organization=${SessionService.ORGANIZATION}`, {}, false)).data || [];
  
        // Simula visibilidad hardcodeada
        const updatedProjects = projects.result.map((project) => ({
          ...project,
          visibility: 'Global', // O ['org1', 'org2'] si es personalizado
        }));
  
        setTeams(filtro ? updatedProjects.filter((element) => element.name.toLowerCase().includes(filtro.toLowerCase())) : updatedProjects);
      } catch (error) {
        console.error('ERROR FETCHING PROJECTS ', error);
      } finally {
        setLoading(false);
        //document.getElementById('buscador')?.focus();
      }
    };

  const createProject = async () => {
    try {
      setLoading(true);
      const payload = {
        teams: selectedGAR,
        name: projectName,
        description: projectDescription,
        countryCode,
        autogenerate: true,
        currencyId,
        organization_id: SessionService.ORGANIZATION,
        ...(customDb && { host: dbHost, port: dbPort, user: dbUser, password: dbPass })
      };
      const result = await ObexRequestHandler.post('/projects', payload);
      if (result.success) {
        console.log("TERMINADO!!!!");
        onCloseAction();
        history.push('/projects');
      }
    } catch (error) {
      console.error('Error creating project: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await createProject();
  };

  const getCountriesAndCode = async () => {
    try {
      const result = await ObexRequestHandler.get('/servers_regions');
      setCountries(result.data || []);
    } catch (error) {
      console.error('ERROR GETTING COUNTRIES ', error);
    }
  };

  const getPossibleCurrencies = async () => {
    try {
      const result = await ObexRequestHandler.get('/currencies', { all: true });
      setCurrencies(result.data || []);
    } catch (error) {
      console.error('ERROR GETTING CURRENCIES ', error);
    }
  };

  useEffect(() => {
    getCountriesAndCode();
    getPossibleCurrencies();
    getTeams('');
  }, []);

  useEffect(() => {
    setProjectDescription('');
    setCountryCode('');
    setProjectDescription('');
    setCustomDb(false);
    addListSelP([]);
  }, [opened]);

  useEffect(() => {
    setSubmitDisabled(!(projectDescription && countryCode && currencyId));
  }, [projectDescription, countryCode, currencyId]);

  const countriesList = countries.map((c) => <option value={c.code}>{c.region}</option>);
  const currenciesList = currencies.map((c) => <option value={c.id}>{c.name}</option>);

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col className="">
            <Form.Group controlId="projectDescription">
              <Form.Label>API Project Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="API Project Name"
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
                required
              />
            </Form.Group>

{/*}
            <Form.Group controlId="projectName">
              <Form.Label>Custom Project ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Custom Project ID"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                required
              />
            </Form.Group>
*/}
            <Form.Group controlId="country">
              <Form.Label>Location</Form.Label>
              <Form.Control
                as="select"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                required
              >
                <option value="" disabled hidden>
                  Choose a location
                </option>
                {countriesList}
              </Form.Control>
            </Form.Group>


{/*}
            <Form.Group controlId="currency">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                as="select"
                value={currencyId}
                onChange={(e) => setCurrencyId(e.target.value)}
                required
              >
                <option value="" disabled hidden>
                  Choose a currency
                </option>
                {currenciesList}
              </Form.Control>
            </Form.Group>
            */}

            <Form.Check
              type="checkbox"
              label="Use your own database (PostgreSQL)"
              checked={customDb}
              onChange={(e) => setCustomDb(e.target.checked)}
            />

            {customDb && (
              <Fragment>
                <Form.Group controlId="dbHost">
                  <Form.Label>Database Host</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Database Host"
                    value={dbHost}
                    onChange={(e) => setDbHost(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="dbPort">
                  <Form.Label>Database Port</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Database Port"
                    value={dbPort}
                    onChange={(e) => setDbPort(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="dbUser">
                  <Form.Label>Database User</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Database User"
                    value={dbUser}
                    onChange={(e) => setDbUser(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="dbPass">
                  <Form.Label>Database Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Database Password"
                    value={dbPass}
                    onChange={(e) => setDbPass(e.target.value)}
                    required
                  />
                </Form.Group>







              </Fragment>
            )}

            <Form.Group controlId="selectGA" className="mt-3 mb-3">
              <Form.Label>Teams's List</Form.Label>
              <GenericSelectableList
                items={teams}
                searchKeys={['name']}
                onAdd={(role) => setSelectedGAR((prev) => [...prev, role.id])}
                onRemove={(role) =>
                  setSelectedGAR((prev) => prev.filter((r) => r !== role.id))
                }
                renderLabel={(role) => role.name}
                placeholder="Search teams..."
                multiOption={true} // Selección múltiple
                selectedItems={ListSelP}
                setSelectedItems={addListSelP}
                itemSelected={''}
              />
            </Form.Group>


          </Col>
        </Row>

        <Row>
          <Col md='6'>
            <Button className='mt-3 w-100' type="submit" disabled={submitDisabled || loading} variant="primary" block>
              {loading ? <Spinner animation="border" size="sm" /> : 'Create'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddProjectForm;
