import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/Features-1.png'
import features_2 from '../../assets/img/landingPage/Features-2.png'
import features_3 from '../../assets/img/landingPage/Features-3.png'



import logo from '../../assets/img/logos/AVAP_DS_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'



const IdeProduct = () => {
    useEffect(() => {
	
		document.body.classList.add('dark-bg');
		return () => {
			document.body.classList.remove('dark-bg');
		};

	}, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center py-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">An AVAP IDE</h1>
                    <p className="fl-subtitle">
                        Power up your APIs with AVAP Dev Studio, propel <br /> your development to the next level
                    </p>
                    {/* <a className="glowBtn-dark" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a> */}
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">

                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-subtitle-xs mb-3">
                                A Powerful Platform for Next-Gen API Development


                            </p>

                            <p className="fl-text">
                                AVAP Dev Studio is a powerful IDE that takes advantage of the full capabilities of AVAP technology. It streamlines API creation, management, and optimization with real-time code editing, intelligent error detection, and dynamic testing. Designed for efficiency and ease of use, AVAP Dev Studio accelerates development workflows, boosts productivity, and ensures smooth API lifecycle management.
                            </p>
                        </div>
                    </div>

                </section>

                {/* Features */}
                <section className="container pb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <p>Complete Project Management</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>AVAP Code Execution</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <p>BRUNIX Copilot</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Complete API Support</h3>
                            <p className="fl-text">
                                Streamline the creation and management of AVAP code repositories while efficiently overseeing the publication of API versions across diverse environments. Simplify the process of managing and controlling unit testing, ensuring seamless deployment across all stages.

                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <h3 className="fl-title">Multi-language Support</h3>
                            <p className="fl-text">
                                AVAP Dev Studio provides advanced linters and intelligent code completions for AVAP and the most popular programming languages. It also features an integrated GIT service for efficient API management and seamless GitHub integration, streamlining your development workflow.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">AI Integration</h3>
                            <p className="fl-text">
                                BRUNIX AI brings advanced artificial intelligence into your development environment, offering real-time code analysis, insights, and modifications to streamline your workflow. Its integrated no-code/low-code tool simplifies complex development tasks, making them accessible to users of all skill levels.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col mb-5">
                            <div className="fl-title text-center">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    API Lifecycle Management
                                </p>
                                <p className="fl-text">
                                    A single environment to efficiently manage API creation, publication, and maintenance, enhancing control and reducing time-to-market.
                                    <br />
                                    &nbsp;
                                    <br />&nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Visual API Creation for Enhanced Productivity
                                </p>
                                <p className="fl-text">
                                    The integrated visual API design tools accelerate development and improve design accuracy, enabling developers to bring ideas to life quickly and effectively.
                                    <br />&nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    AI-Driven Pre-Development Efficiency
                                </p>
                                <p className="fl-text">
                                    Embedded BRUNIX assists in code translation and real-time analysis, helping developers write more accurate and efficient code based on their input, reducing errors and saving time.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default IdeProduct;
