import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col, Button, Form, Table, Spinner, DropdownButton, Dropdown } from 'react-bootstrap';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import Drawer from './Drawer';
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from '../../services/SessionsService';
import { BrowserView } from 'react-device-detect';
import orgIcon from '../../assets/img/ui_icons/credentials.png'
import { useHistory } from 'react-router-dom';

//token View

type Token = {
  id: string;
 // project: string;
  purpose: string;
  type: string;
  status: string;
  created: string;
  expires: string;
  lastUsed: string;
  expired: boolean;
  organizations: string[];
  selectedGA: string[];
  key: any;
  registration_token: any;
};

interface OrganizationSelectProps {
  onOrganizationChange?: (orgId: number) => void; // Ahora es opcional
  selectedOrganization?: number;
}
const Tokens: FunctionComponent<OrganizationSelectProps> = ({ onOrganizationChange, selectedOrganization }) => {
  const [apiProducts, setApiProducts] = useState([]);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAddTokenDrawerOpen, setIsAddTokenDrawerOpen] = useState(false);
  const [localSelectedGA, setSelectedGA] = useState<any[]>([]);
  const [permissions, setPermissions] = useState([]);
  const [listaRecuperados, setlistaRecuperados] = useState([])

  const [listaRecuperados1, setlistaRecuperados1] = useState([])
  const [listaRecuperados2, setlistaRecuperados2] = useState([])
  const [listaRecuperados3, setlistaRecuperados3] = useState([])
  const [listaRecuperados4, setlistaRecuperados4] = useState([])
  const [orgDevs, setSelectedOrgDevs] = useState([])
  const [certsT, setCertsT] = useState([]);
  const [organizations, setOrganizations] = useState<{ id: number; name: string }[]>([]);

  const fetchOrganizations = async () => {
    try {
      const response = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];
      setOrganizations(response.result || []);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    } finally {
      setLoading(false);
    }
  };
const history = useHistory();

const getCerts = async () =>{
  const certs = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`)


  console.log(certs.data);

console.log(certs.data.map((ga) => ({ id: ga.id, name: ga.name, region_id: ga.region_id })))
  setCertsT(certs.data);

};

  // Datos simulados
  let mockTokens: Token[] = [];

  const fetchOrgDevs = async (organization) =>{

    const devs = (await ObexRequestHandler.get(`/organization_devs?organization=${organization}`, {}, true)).data || [];
      setSelectedOrgDevs(devs);
  }

  // Simulación de carga de datos
  const fetchTokens = async (filter: string): Promise<void> => {
    setLoading(true);
  
    try {
      const [devTokensResponse, tokensResponse] = await Promise.all([
        ObexRequestHandler.get(`/cert/get_dev_tokens?organization=${SessionService.ORGANIZATION}`),
        ObexRequestHandler.get(`/cert/get_tokens?organization=${SessionService.ORGANIZATION}`)
      ]);
  
      const devTokens: Token[] = devTokensResponse.data || [];
      const otherTokens: Token[] = tokensResponse.data || [];
  
      const combinedTokens: Token[] = [...devTokens, ...otherTokens];
  
      const filteredTokens: Token[] = filter.trim() === ''
      ? combinedTokens
      : combinedTokens.filter((token) => {
          const filterLower = filter.toLowerCase();
          const orgs = token.organizations ? token.organizations.join(', ').toLowerCase() : '';
          const id = token.id ? token.id.toLowerCase() : '';
          const type = token.type ? token.type.toLowerCase() : '';
          const purpose = token.purpose ? token.purpose.toLowerCase() : '';
    
          return (
            orgs.includes(filterLower) ||
            id.includes(filterLower) ||
            type.includes(filterLower) ||
            purpose.includes(filterLower)
          );
        });
    
      
      setTokens(filteredTokens);
    } catch (error) {
      console.error('Error fetching tokens:', error);
      setTokens([]); 
    } finally {
      setLoading(false); 
    }
  };
  
  
  

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleRevokeToken = async (tokenId: any) => {
    if (window.confirm('Are you sure you want to revoke this token??')) {
      if (tokenId.id !== undefined) {

        await ObexRequestHandler.get(`/cert/delete_tokens?organization=${SessionService.ORGANIZATION}&id=${tokenId.id}`) 

      } else {
        console.log(tokenId);
        //registration_token
        await ObexRequestHandler.get(`/cert/del_dev_tokens?id=${tokenId.registration_token}`) 
      }

      console.log(tokenId);

      fetchTokens('');
      getPermits('');
      fetchApiData();
      fetchOrgDevs(SessionService.ORGANIZATION);
      fetchOrganizations();

      history.push('/tokens');

    }
  };

  const getPermits = async (filtro) => {
    try {

      //setloadDevs(true);
      setLoading(true);
      setPermissions([]);

      const permits = (
        await ObexRequestHandler.get('/iam/get_permits', {}, true)).data || [];

      const filter_permits = permits.filter(element =>
        element.permit_code.toLowerCase().includes(filtro.toLowerCase())
      );
      setPermissions(filtro === '' ? permits : filter_permits);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }

    setLoading(false);

  }

  const handleAddPermit = (permit) => {
    // Añade un permiso seleccionado
    if (!localSelectedGA.some((p) => p.id === permit.id)) {
      setSelectedGA((prev) => [...prev, permit]);
    }
  };

  const handleRemovePermit = (permit) => {
    // Elimina un permiso seleccionado
    setSelectedGA((prev) => prev.filter((p) => p.id !== permit.id));
  };



  const handleAddGA = (ga: string) => {
    if (!localSelectedGA.includes(ga)) {
      setSelectedGA([...localSelectedGA, ga]);
      console.log(ga);



    }
  };

  const handleRemoveGA = (ga: string) => {
    setSelectedGA(localSelectedGA.filter((item) => item !== ga));
  };



  const [securityTypes] = useState(["Developer Token File", "JWT", "SAML"]);
  const [formData, setFormData] = useState({
    apiProduct: "",
    version: "",
    type: "",
    security: false,
    securityType: "",
    headers: [],
    signatureType: "",
    project: '',
    expires: '',
  });

  const handleDownloadToken = (token: Token) => {
    const content = `
      Token ID: ${token.id}
      Type: ${token.type}
      Purpose: ${token.purpose}
      Status: ${token.status}
      Created: ${token.created}
      Expires: ${token.expires}
      Last Used: ${token.lastUsed}
      Organizations: ${token.organizations}
      Value: ${token.selectedGA}
    `;
    const filecontent = `{'id_token':'${token.key || token.registration_token}'}`

    const blob = new Blob([filecontent], { type: 'text/plain' });
    const link = document.createElement('a');
    
    console.log(token);
    link.href = URL.createObjectURL(blob);
    link.download = `token_${token.id}.txt`;
    link.click();
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleAddToken = (newToken: Token) => {
    setTokens((prevTokens) => [...prevTokens, newToken]);
  };

  const fetchApiData = async () => {
    try {
      setLoading(true);

      // Obtener API Products
      const productsResponse = await ObexRequestHandler.get('/api/products', {}, true);
      setApiProducts(productsResponse.data || []);

      // Obtener proyectos disponibles
      const projectsResponse = await ObexRequestHandler.get('/projects', {}, true);
      setAvailableProjects(projectsResponse.data || []);
      console.log('Projects Response:', projectsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };




  const handleOrganizationSelect = (organization: { id: number; name: string }) => {
    setSelectedGA((prev) => [...prev, organization]); // Seleccionar organización
    // Actualiza los proyectos para esta organización
    fetchProjectsForOrganization(organization.id);
  };

  const fetchProjectsForOrganization = async (organizationId: number) => {
    try {
      setLoading(true);
      const response = await ObexRequestHandler.get(`/projects?organizationId=${organizationId}`, {}, true);
      setAvailableProjects(response.data || []);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchTokens('');
    getPermits('');
    fetchApiData();
    getCerts();
    fetchOrgDevs(SessionService.ORGANIZATION);
    fetchOrganizations();
    window.scrollTo(0, 0);
  }, []);

  return (
    <BrowserView>
    <DashLayout sider={DashRouters} active="organizations" sublinks={[]}>
      <Row id="topHeader">
        <Col md="10">
          <div className="d-flex flex-column">
            <div className="cld_wrapperTitle">
            <img style={{ width:'24px' }} src={orgIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px">
              <span style={{ color:'#949498', fontSize:'14px' }}>Credential Manager </span>/ Tokens
      
                </span>
            </div>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right resBtn"
            onClick={() => {
              setlistaRecuperados([])
              setlistaRecuperados1([])
              setlistaRecuperados2([])
              setlistaRecuperados4([])
              setFormData({
                apiProduct: '',
                version: '',
                type: '',
                security: false,
                securityType: '',
                headers: [],
                signatureType: '',
                project: '',
                expires: '',
              })
              setIsAddTokenDrawerOpen(true)}}
          >
            Add Token
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>





      <Row id="table" className="mt-5">
        <Col>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <div className="cld_borderWrapper">

              <span className='cld_sectionTitle'>Tokens</span>

              <div className="searchContainer">

                <Form.Control
                  id="buscador"
                  required
                  type="text"
                  value={filter}
                  placeholder="Search by Project or Token ID"
                  onChange={handleFilterChange}
                />

                <Button
                  className="customButton-transparent px-4"
                  onClick={() => fetchTokens(filter)}
                >
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </Button>
              </div>

              <Table className="cld_tableWrapper">
                <thead>
                  <tr className='tableRow'>
                    <th style={{ minWidth:'9%' }} className="textBold">Type</th>
                    <th style={{ minWidth:'21.5%' }} className="textBold">Purpose</th>
                    <th className="textBold">Status</th>
                    <th className="textBold">Created</th>
                    <th className="textBold">Expires</th>
                  {/*}  <th className="textBold">Last Used</th> */}
                    <th style={{ minWidth:'16%', maxWidth:'16%' }} className="textBold">Users</th>
                    <th className="textBold" style={{ textAlign: 'end' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {tokens.map((token) => (
                    <tr className='tableRow' key={token.id}>
                      <td className='td_centered'>{token.type === 'Developer Token File' ? 'Dev Token': token.type}</td>
                      <td className='td_centered'>{token.purpose}</td>
                      <td className='td_centered'>{token.status === undefined ? (token.expired?'Expired':'Active'):(token.status ? 'Active':'Inactive')}</td>
                      <td className='td_centered'>{token.created != null ? token.created.split('T')[0] : '-'}</td>
                      <td className='td_centered'>{token.created != null ? token.expires.split('T')[0] : '-'}</td>
                  {/*}    <td>{token.lastUsed}</td> */}
                      <td className='td_centered'>{token.organizations === undefined ? '*': token.organizations}</td>
                      <td className='td_centered text-right'>
                        {/*}
                        <Button
                          className="customButton-blue"
                          onClick={() => handleDownloadToken(token)}
                          style={{ marginRight: '8px' }}
                        >
                          Download
                        </Button>
                        {token.status !== 'Revoked' && (
                          <Button
                            className="customButton-red"
                            onClick={() => handleRevokeToken(token.id)}
                          >
                            Revoke
                          </Button>
                          
                        )} */}
                        <div className="actionsWrapper">

                          <Dropdown className='my-auto' style={{ marginRight: '-15px' }}>
                            <Dropdown.Toggle className="organizations" >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {<Dropdown.Item key={1} onClick={() => { handleDownloadToken(token) }}>Download</Dropdown.Item>}
                              {(token.status !== 'Revoked') && <Dropdown.Item key={1} onClick={() => {handleRevokeToken(token)}}>Revoke</Dropdown.Item>}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
{/*}
                  {tokens.length < 3 &&
                  <div style={{ height:'65px' }}></div>
                  }
                  */}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>

      {/* Drawer for Adding Token */}
      <Drawer
        isOpen={isAddTokenDrawerOpen}
        toggleSidebar={() => setIsAddTokenDrawerOpen(!isAddTokenDrawerOpen)}
        name="Add Token"
      >
        <div>
          {/* <h3>Create a New Token</h3> */}
          <Form
            onSubmit={async (e) => {
              e.preventDefault();

              const purpose = formData.version;
              const expiration = formData.expires;
              const type = formData.securityType;
              const key = formData.signatureType;
              const org_id = SessionService.ORGANIZATION;
              const permits = listaRecuperados;

              const payload = {
                purpose,
                expiration,
                type,
                key,
                org_id,
                permits
              }
              console.log(payload)

              await ObexRequestHandler.post('/cert/add_tokens', payload)

              setIsAddTokenDrawerOpen(false);

              fetchTokens('');
              getPermits('');
              fetchApiData();
              fetchOrgDevs(SessionService.ORGANIZATION);
              fetchOrganizations();
            }}
          >
            {/*}
            <Form.Group controlId="organizationSelect" className="mb-3">
              <Form.Label>Select Organization</Form.Label>
              <GenericSelectableList
                items={organizations}
                searchKeys={['name']} // Búsqueda por nombre
                onAdd={handleOrganizationSelect}
                onRemove={(org) => {
                  setSelectedGA((prev) => prev.filter((p) => p.id !== org.id)); // Remover organización seleccionada
                }}
                renderLabel={(org) => org.name}
                placeholder="Select an organization..."
                multiOption={true} // Solo una organización a la vez
                selectedItems={listaRecuperados1}
                setSelectedItems={setlistaRecuperados1}
              />
            </Form.Group>
*/}

{/*}
            <Form.Group controlId="projectSelect" className="mb-3">
              <Form.Label>Select Project</Form.Label>
              <GenericSelectableList
                items={availableProjects}
                searchKeys={['description']} // Búsqueda por descripción del proyecto
                onAdd={(project) => {
                  setFormData((prev) => ({ ...prev, project: project.id })); // Selecciona un proyecto
                }}
                onRemove={() => {
                  setFormData((prev) => ({ ...prev, project: '' })); // Limpia selección de proyecto
                }}
                renderLabel={(project) => project.description}
                placeholder="Select a project..."
                multiOption={true} // Solo un proyecto a la vez

                selectedItems={listaRecuperados2}
                setSelectedItems={setlistaRecuperados2}

              />
            </Form.Group>

*/}


            <Form.Group>
              <Form.Label>Purpose</Form.Label>
              <Form.Control 
                  type="text" 
                  placeholder="Enter purpose (e.g., Read, Write)" 
                  value={formData.version || ''} 
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, version: e.target.value }))
                  }/>
            </Form.Group>


            <Form.Group>
              <Form.Label>Token Type</Form.Label>
              <Form.Control
                as="select"
                name="securityType"
                value={formData.securityType}
                onChange={handleFormChange}
              >
                <option value="">Select a Token type</option>
                {securityTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

{ (formData.securityType == 'JWT' || formData.securityType == 'OAuth') &&
            <Form.Group>
              <Form.Label>Key</Form.Label>
              <Form.Control 
                  type="text" 
                  placeholder="Enter key value" 
                  value={formData.signatureType || ''} 
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, signatureType: e.target.value }))
                  }
              />
            </Form.Group>
}

{ formData.securityType == 'SAML' &&
            <Form.Group controlId="selectGA" className="mb-3">
              <Form.Label>Select Cert</Form.Label>
              <GenericSelectableList
                items={certsT.map((ga) => ({ id: ga.id.toString(), name: ga.name }))}
                searchKeys={['permit_code', 'description']} // Búsqueda por código o descripción
                onAdd={handleAddPermit}
                onRemove={handleRemovePermit}
                renderLabel={(permit) => permit.name}
                placeholder="Select cert..."
                multiOption={false} // Permitir selección múltiple
                selectedItems={listaRecuperados4}
                itemSelected={''}
                setSelectedItems={setlistaRecuperados4}
              />
            </Form.Group>
}


{ formData.securityType == 'Developer Token File' &&


            <><Form.Group controlId="developerSelect" className="mb-3">
                <Form.Label>Select Developer</Form.Label>
                <GenericSelectableList
                  items={orgDevs}
                  searchKeys={['email']} // Búsqueda por descripción del proyecto
                  onAdd={(project) => {
                    setFormData((prev) => ({ ...prev, project: project.id })); // Selecciona un proyecto
                  } }
                  onRemove={() => {
                    setFormData((prev) => ({ ...prev, project: '' })); // Limpia selección de proyecto
                  } }
                  renderLabel={(project) => project.email}
                  placeholder="Select a developer..."
                  multiOption={false} // Solo un proyecto a la vez
                  itemSelected={''}
                  selectedItems={listaRecuperados3}
                  setSelectedItems={setlistaRecuperados3} />
              </Form.Group><Form.Group controlId="projectSelect" className="mb-3">
                  <Form.Label>Select Projects</Form.Label>
                  <GenericSelectableList
                    items={availableProjects}
                    searchKeys={['description']} // Búsqueda por descripción del proyecto
                    onAdd={(project) => {
                      setFormData((prev) => ({ ...prev, project: project.id })); // Selecciona un proyecto
                    } }
                    onRemove={() => {
                      setFormData((prev) => ({ ...prev, project: '' })); // Limpia selección de proyecto
                    } }
                    renderLabel={(project) => project.description}
                    placeholder="Select a project..."
                    multiOption={true} // Solo un proyecto a la vez
                    itemSelected={''}
                    selectedItems={listaRecuperados2}
                    setSelectedItems={setlistaRecuperados2} />
                </Form.Group></>

}




            <Form.Group controlId="expirationDate" className="mb-3">
              <Form.Label>Expiration Date</Form.Label>
              <Form.Control
                type="date"
                name="expirationDate"
                value={formData.expires || ''} 
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, expires: e.target.value }))
                }
              />
            </Form.Group>




            <Form.Group controlId="selectGA" className="mb-3">
              <Form.Label>Select Permits</Form.Label>
              <GenericSelectableList
                items={permissions}
                searchKeys={['permit_code', 'description']} // Búsqueda por código o descripción
                onAdd={handleAddPermit}
                onRemove={handleRemovePermit}
                renderLabel={(permit) => `${permit.permit_code} (${permit.description})`}
                placeholder="Select permissions..."
                multiOption={true} // Permitir selección múltiple
                itemSelected={''}
                selectedItems={listaRecuperados}
                setSelectedItems={setlistaRecuperados}


              />
            </Form.Group>



            <Row>
              <Col md='6'>
                <Button className="w-100" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Drawer>
    </DashLayout>
    </BrowserView>
  );
};

export default Tokens;
