import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";

import { useHistory } from "react-router-dom";
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'


//img
import headerBg from '../../assets/img/landingPage/ilustracion-home_v4.png'
import virtualApi from '../../assets/img/landingPage/02_video@2x.png'
import apilife from '../../assets/img/landingPage/apiLife.png'
import openAi from '../../assets/img/landingPage/openapi-blanco.png'
import linux from '../../assets/img/landingPage/Linux.png'
import tablet from '../../assets/img/landingPage/tablet.png'

import gradientCollab from '../../assets/img/landingPage/gradient_collab.png'
import gradientApi from '../../assets/img/landingPage/gradientApi.png'
import gradientServices from '../../assets/img/landingPage/gradientServices.png'
import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import icn_design from '../../assets/img/landingPage/icono-design@2x.png'
import icn_implementation from '../../assets/img/landingPage/icono-implementation@2x.png'
import icn_deploy from '../../assets/img/landingPage/icono-deployment@2x.png'
import icn_testing from '../../assets/img/landingPage/iconos-testing@2x.png'
import icn_versioning from '../../assets/img/landingPage/iconos-versiones@2x.png'

//logos
import logo_vs from '../../assets/img/logos/AVAP_VS_white.svg'
import logo_avap from '../../assets/img/logos/AVAP_Lenguaje_white.svg'
import logo_gate from '../../assets/img/logos/AVAP_Gateway_white.svg'
import logo_cloud from '../../assets/img/logos/AVAP_Cloud_white.svg'
import logo_collab from '../../assets/img/logos/AVAP_Collaborative_white.svg'
import logo_avds from '../../assets/img/logos/AVAP_DS_white.svg'
import logo_avapConnect from '../../assets/img/landingPage/AVAP_Connect.svg'



const App = () => {
    const history = useHistory();
    const [selectedProduct, setSelectedProduct] = useState('AVAP');
    const productDetails = {
        AVAP: {
            title: 'Advanced Virtual API Programming',
            text: 'Virtual, AI-enhanced, high-level specific language for API development. With features like language specifications, an interpreter, and a server, it lets developers focus on problem-solving in a new era of virtual programming.',
            image: apilife,
            route: '/avap',
        },
        AVS: {
            title: 'Manage and publish APIs across development and production environments.',
            text: 'AVS supports multiple API versions, automates testing, and enables smooth deployment in various environments, ensuring compatibility with all language and API servers.',
            image: apilife,
            route: '/avs',
        },
        AVAPGA: {
            title: 'More powerful than a proxy',
            text: 'AVAP Gateway Advanced redirects requests based on consumption history, scenarios, and conditions with Lua-driven functionality. It’s a universal, efficient solution compatible with any language server.',
            image: apilife,
            route: '/gateway',
        },
        Servicios: {
            title: 'Power platform for the API Lifecycle',
            text: 'Manage your APIs in a SaaS model —from design to testing and deployment. Powered by AVAP technology, virtuality and artificial intelligence will be in the core of every API and API product.',
            image: apilife,
            route: '/cloud',
        },
        AVAPCN: {
            title: 'First democratic P2P network for APIs',
            text: 'P2P platform that enables scalable deployment of AVS and AVAP Interpreter & Server Language for API development. It optimizes API requests by redirecting to faster servers and enhances virtual machine capacity through dedicated channels.',
            image: apilife,
            route: '/collaborative',
        },
        AVAPDevStudio: {
            title: 'AVAP own IDE',
            text: 'Robust IDE that streamlines project management, API development, and publication. It enhances collaboration with AI-driven coding extensions, co-coding features, and integrated chat management.',
            image: apilife,
            route: '/devstudio',
        },
    };

    const getLogo = (productKey) => {
        switch (productKey) {
            case 'AVAP':
                return logo_avap;
            case 'AVS':
                return logo_vs;
            case 'AVAPGA':
                return logo_gate;
            case 'Servicios':
                return logo_cloud;
            case 'AVAPCN':
                return logo_collab;
            case 'AVAPDevStudio':
                return logo_avds;
            default:
                return logo_avap;
        }
    };



    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);


    return (
        <>
            <FlNav></FlNav>
            <div className="fl-mainWrapper">
                <div className="fl-wrapper">
                    <div className="fl-container">
                        <header id="fl-header" style={{ backgroundImage: `url(${headerBg})` }}>
                            <div className="fl-content-wrapper">
                                <h1 className="fl-title-big text-center">API Lifecycle Solution</h1>
                                <p className="fl-title text-center m-0">Virtuality and AI at its core</p>
                                <p className="fl-text text-center fl-txt-container">
                                    Efficient, intelligent API management powered by next-gen technology,
                                    delivering seamless scalability and enhanced profitability
                                    for your team and operations
                                </p>

                                <a className="glowBtn" href="https://avapid.com" target='_blank'>
                                    <span className="btnInner">  Create my AVAP ID</span>
                                </a>
                            </div>
                        </header>
                    </div>
                </div>

                <div className="fl-wrapper">
                    <img src={gradientApi} alt="" className="gradient gradientapi" />
                    <div className="fl-container">
                        <section id="apiSection" className=" mt-3">

                            <h3 className="fl-title text-center">An API Virtualization Platform</h3>
                            <p className="fl-text text-center m-0">AVAP Framework is a comprehensive suite of products and services to
                                manage <br />the entire API lifecycle, from development to maintenance and deployment.</p>

                            <a href="https://www.youtube.com/watch?v=LlVcKzcM3uc" target='_blank' className="text-center fl-link my-5"> Watch video <FontAwesomeIcon size="xs" icon={faArrowRight} /></a>
                            <img src={virtualApi} alt="" />

                        </section>

                    </div>
                </div>

                <div className="fl-wrapper">
                    <div className="fl-container">
                        <section id="characteristics">
                            <div className="charWrapper">
                                <div className="fl-item">
                                    Designing
                                    <div className="fl-border"></div>
                                    <img src={icn_design} alt="" />
                                </div>
                                <div className="fl-item">
                                    Implementation
                                    <div className="fl-border"></div>
                                    <img src={icn_implementation} alt="" />
                                </div>
                                <div className="fl-item">
                                    Deployment
                                    <div className="fl-border"></div>
                                    <img src={icn_deploy} alt="" />
                                </div>
                                <div className="fl-item">
                                    Testing
                                    <div className="fl-border"></div>
                                    <img src={icn_testing} alt="" />
                                </div>
                                <div className="fl-item">
                                    Versioning
                                    <div className="fl-border"></div>
                                    <img src={icn_versioning} alt="" />
                                </div>

                            </div>
                        </section>

                    </div>
                </div>



                <div className="fl-wrapper">
                    <img src={gradientServices} className="gradient gradientServices" alt="" />
                    <img src={gradientApi} alt="" className="gradient gradientlife" />
                    <div className="fl-container">

                        <section id="products">
                            <h3 className="fl-title text-center">Our Products & Services</h3>

                            <div className="fl-productsWrapper">
                                {Object.keys(productDetails).map((productKey) => (
                                    <div
                                        key={productKey}
                                        className={`fl-darkButton ${selectedProduct === productKey ? 'active' : ''}`}
                                        onClick={() => setSelectedProduct(productKey)}
                                        style={{ cursor: 'pointer' }} // Cambia el cursor para indicar que es clicable
                                    >
                                        <img className="logo" src={getLogo(productKey)} alt={productKey} />
                                    </div>
                                ))}
                            </div>
                        </section>


                    </div>
                </div>

                <div className="fl-wrapper">
                    <div className="fl-container">
                        <section id="lifeCycle" className="mt-3">
                            <div className="fl-lifeWrapper">
                                <div className="fl-left">
                                    <h3 className="fl-title">
                                        {productDetails[selectedProduct].title.split('<br />').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </h3>
                                    <p className="fl-text">
                                        {productDetails[selectedProduct].text.split('<br />').map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </p>
                                    <a
                                        onClick={() => {
                                            history.push(productDetails[selectedProduct].route);
                                            window.scrollTo(0, 0);
                                        }}
                                        className="fl-link"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Learn more <FontAwesomeIcon size="xs" icon={faArrowRight} />
                                    </a>
                                </div>
                                <div className="fl-right">
                                    <img className="fl-placeholder" src={productDetails[selectedProduct].image} alt={selectedProduct} />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>


                <div className="fl-wrapper">
                    <div className="fl-container">

                        <section id="box">
                            <div className="fl-box">
                                <p>Official members of global tech communities, enhancing our commitment
                                    to open API standards and innovation
                                </p>
                                <div className="imgWrapper">
                                    <img src={openAi} alt="" />
                                    <img src={linux} alt="" />
                                </div>
                            </div>
                        </section>

                    </div>
                </div>

                <div className="fl-wrapper">
                    <div className="fl-container">
                        <section id="avapCollab">
                            <img className="logoTitle" src={logo_collab} alt="" />

                            <h3 className="fl-title mt-5">
                                The first P2P network for API <br />
                                development and deployment
                            </h3>
                            <div className="imgWrapper">
                                <img src={gradientCollab} alt="" />
                                <img src={tablet} alt="" className="mt-5" />
                            </div>
                            <p className="text-center fl-c-gray">
                                Leverage a peer-to-peer platform for scalable API development and publication with AVS (AVAP Virtual Server) and AVAP Interpreter & Server Language.
                                This network intelligently evaluates each API request for direct processing or optimal redirection to servers with faster response times,
                                enhancing virtual machine capacity across language servers through dedicated AVAP Virtual Code channels.
                            </p>

                            <a onClick={() => {
                                history.push("/collaborative");
                                window.scrollTo(0, 0);
                            }} className='fl-link'>Learn more</a>

                        </section>

                    </div>
                </div>


                <div className="fl-wrapper fl-z-index-2">
                    <div className="fl-container">
                        <section id="mainFeatures">
                            <h3 className="fl-title">
                                Main Features
                            </h3>

                            <div className="fl-itemsWrapper">
                                <div className="fl-darkButton">
                                    <div className="itemButton">
                                        <p className=''>Free and International Network</p>
                                        <img src={icon_free} alt="" />
                                    </div>
                                </div>
                                <div className="fl-darkButton">
                                    <div className="itemButton">
                                        <p className=''>Exponential Capacity</p>
                                        <img src={icon_expo} alt="" />
                                    </div>
                                </div>
                                <div className="fl-darkButton">
                                    <div className="itemButton">
                                        <p className=' text-capitalize'>Dynamic thread distribution</p>
                                        <img src={icon_dyn} alt="" />
                                    </div>
                                </div>
                                <div className="fl-darkButton">
                                    <div className="itemButton">
                                        <p className=' text-capitalize'>Intelligent Capacity</p>
                                        <img src={icon_expo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>


                <div className="fl-wrapper">
                    <div className="fl-container fl-z-index-2">
                        <section id="join">
                            <img className="connectLogo" src={logo_avapConnect} alt="" />
                            <h3 className="text-center fl-subtitle m-0">Join AVAP Partner Network</h3>
                            <p className="text-center fl-subtitle-blue">
                                Take advantage of the Early Access program
                            </p>
                            <p className="text-center fl-c-gray">
                                Join our program for international business partners to access a unified infrastructure, powerful sales tools, and comprehensive support. Unlock pre-sales opportunities and enhance your role within the AVAP Framework partner ecosystem, driving growth and innovation. Elevate your business today!
                            </p>

                            <a href='http://connect.avapframework.com' target='_blank' className="fl-btn-white d-table mx-auto">Join Now</a>
                        </section>
                    </div>

                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default App;
