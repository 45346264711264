import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import dashboard from '../../assets/img/landingPage/cloudDashboard.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/Features-1.png'
import features_2 from '../../assets/img/landingPage/Features-2.png'
import features_3 from '../../assets/img/landingPage/Features-3.png'

import icn_1 from '../../assets/img/landingPage/icn_vision.png'
import icn_2 from '../../assets/img/landingPage/icn_mission.png'
import icn_3 from '../../assets/img/landingPage/icono1.png'

import rocket from '../../assets/img/landingPage/rocket.png'
import tablet from '../../assets/img/landingPage/tabletApi.png'

import logo from '../../assets/img/logos/AVAP_API-Publisher_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'


import user from '../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../assets/img/ui_icons/fa-doc.svg'

import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'



const PublisherProduct = () => {

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);



    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center py-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Seamless Integration</h1>
                    <p className="fl-subtitle">
                        Simplifying API Publishing and Management
                    </p>
                    {/* <a className="glowBtn-dark" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a> */}
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="co">
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4"
                            /> */}
                            <p className="fl-subtitle-xs mb-3">
                                API publishing for enhanced security, scalability, <br />and integration.
                            </p>
                            <p className="fl-text">
                                AVAP API Publisher simplifies the publishing and management of APIs within the AVAP ecosystem,
                                allowing seamless transitions from staging to live environments. It manages endpoint assignments,
                                access control, usage policies, version management, and provides analytics to ensure APIs are secure,
                                scalable, and optimized for integration across diverse platforms.
                                This comprehensive solution empowers developers to focus on innovation while ensuring reliable, secure API operations.
                            </p>
                        </div>
                    </div>

                </section>

                <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div>



                <section className="container">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">Key Features</div>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">User-Friendly Interface</h3>
                            <p className="fl-text">
                                AVAP API Publisher offers an intuitive, easy-to-navigate dashboard.
                                The interface streamlines API management by presenting critical information in one place, including current deployments, usage analytics, and API status. With clear visualizations and easy-to-follow workflows,
                                developers can quickly publish APIs, configure access control, manage versions, and track performance.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Robust Version Control</h3>
                            <p className="fl-text">
                                It enables management of multiple versions of your APIs.
                                Allowing developers to push updates and enhancements without disrupting existing services.
                                AVAP’s version control ensures backward compatibility, preventing downtime and minimizing risk.
                                It also allows for easy rollback to previous versions,
                                giving teams the flexibility to test and refine APIs while maintaining stable production environments.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Comprehensive Analytics</h3>
                            <p className="fl-text">
                                AVAP API Publisher gives you deep insights into how your APIs are performing.
                                You can monitor key metrics such as request volume, response times, error rates,
                                and user activity. This information is vital for optimizing performance,
                                identifying bottlenecks, and ensuring APIs meet user expectations.
                                Detailed analytics also allow teams to make data-driven decisions to optimize infrastructure,
                                reduce latency, and improve reliability.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Streamlined Deployment
                                </p>
                                <p className="fl-text">
                                    AVAP automates processes and predicts solutions,
                                    allowing developers to focus on high-level problem-solving rather than technical execution,
                                    speeding up the development process. <br />
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Enhanced Security and Access Control
                                </p>
                                <p className="fl-text">
                                    With its virtual nature,
                                    AVAP adapts to different environments and evolves without requiring code modifications,
                                    ensuring seamless scalability and adaptability to changing demands.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Effortless Integration
                                </p>
                                <p className="fl-text">
                                    AVAP API Publisher ensures APIs integrate seamlessly with existing applications and services,
                                    enhancing collaboration and reducing integration complexity. <br />
                                    &nbsp; <br />
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default PublisherProduct;
