import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";
import devIcon from '../../assets/img/ui_icons/cld_org-s.svg';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";

import Drawer from "./Drawer";

const ApiCatalog = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [apiProducts, setApiProducts] = useState([]);

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [selectedApiForEdit, setSelectedApiForEdit] = useState(null);
  const [selectedApi, setSelectedApi] = useState("");



  const handlePublish = async (api_product) => {

    /*
    
              let data = JSON.stringify({
                "api_product_id": api_product_id,
                "api_product_version": version_value,
                "developer_token": AccesToken
                });
                
                let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${cloud}/api_product_apis_by_dev`,
                headers: { 
                  'Content-Type': 'application/json', 
                },
                data : data
                };
                
                axios.request(config)
    
    
    */




    console.log(api_product.latest_version);

    let version_value = 0.1;
    try {
      if (api_product.latest_version) version_value = Number(api_product.latest_version) + 0.1;
    } catch { }
    const data = {
      api_product_id: api_product.api_product_id,
      api_product_version: version_value,
    };

    console.log(data);
    console.log(api_product);



    const publish_data = await ObexRequestHandler.post('/api_product_apis_by_dev', data);

    console.log(publish_data);
    fetchApiProducts();

  }



  const handleDevPortal = async (api_product) => {


    const data = {
      api_product_id: api_product.api_product_id,
      new_status: !api_product.active

    }

    const publish_data = await ObexRequestHandler.post('/update_portal_prod_status', data);

    console.log(publish_data);
    fetchApiProducts();

  }


  // Function to fetch API products from the backend
  const fetchApiProducts = async () => {
    setLoading(true);
    try {
      const data = await ObexRequestHandler.get(`/publishing/get_products_by_org?org_id=${SessionService.ORGANIZATION}`)

      console.log(data)
      const resmod = [];

      data.data.forEach(dat => {
        if (dat.is_published_active) resmod.push(dat);
      })
      
      setApiProducts(resmod);
    } catch (error) {
      console.error('Error fetching API products:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch API products on component mount (useEffect hook)
  useEffect(() => {
    fetchApiProducts();
  }, []); // Empty dependency array ensures fetching only once on mount

  // Search functionality (unchanged)
  const filteredApis = apiProducts.filter(api =>
    api.api_product_name.toLowerCase().includes(searchTerm.toLowerCase())



  );

  const apiOptions = [
    { id: 1, name: "API Product 1" },
    { id: 2, name: "API Product 2" },
    { id: 3, name: "API Product 3" },
    { id: 4, name: "API Product 4" }
  ];



  const openEditDrawer = (api) => {
    setSelectedApiForEdit(api);
    setIsEditDrawerOpen(true);
  };

  const closeEditDrawer = () => {
    setSelectedApiForEdit(null);
    setIsEditDrawerOpen(false);
  };

  const handleApiChange = (e) => {
    setSelectedApi(e.target.value);
  };


  return (
    <DashLayout sider={DashRouters} active={'api-catalog'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8' >
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px"><span style={{ color: '#949498', fontSize: '14px' }}>API Catalog </span>/ API Catalog </span> 
            </div>
          </div>
        </Col>
        <Col md='4' className='align-content-center'>
          {/* Aquí se puede colocar algún botón de acción si fuera necesario */}
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">
          <span className='cld_sectionTitle'>Published API Products</span>

          <div className="searchContainer mb-4">
            <Form.Control
              id='buscador'
              required
              type="text"
              value={searchTerm}
              placeholder="Search by API name"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button className="customButton-transparent px-4">
              <FontAwesomeIcon icon={faSearch} size="1x" />
            </Button>
          </div>

          <Table className="cld_tableWrapper" style={{height:'auto'}}>
            <tbody>
              <tr className="headerRow">
                <th className="textBold">API Name</th>
                <th className="textBold">API Project</th>
                <th style={{ width: '20%' }} className="textBold">Description</th>
                <th style={{ width: '9%' }} className="textBold">Market Version</th>
                <th style={{ width: '9%' }} className="textBold">Dev Portal</th>
                <th style={{ width: '9%' }} className="textBold">APIs</th>
                <th style={{ textAlign: 'end' }} className="textBold">Actions</th>
              </tr>
              {filteredApis.map((api) => (
                <tr className="tableRow" key={api.id}>
                  <td className="td_centered">{api.api_product_name}</td>
                  <td className="td_centered">{api.project_id} {api.project_name}</td>
                  <td className="td_centered">{api.description}</td>
                  <td className="td_centered">{api.latest_version || 'unpublished'}</td>
                  <td className="td_centered">{api.active ? 'Yes' : 'No'}</td>
                  <td className="td_centered">{Number(api.published_entries_count) + Number(api.gateway_entries_count	)}</td>
                  <td>
                    <div className="actionsWrapper">
                      <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%', marginRight: '20px' }}>
                        <Dropdown.Toggle className="organizations">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          { !api.installed &&
                          <Dropdown.Item
                            key={1}
                            onClick={() => handlePublish(api)}
                          >
                            Pub in Marketplace
                          </Dropdown.Item>
                          }

                          {!api.active ?

                            <Dropdown.Item
                              key={1}
                              onClick={() => handleDevPortal(api)}
                            >
                              To Dev Portals
                            </Dropdown.Item>
                            :
                            <Dropdown.Item
                              key={1}
                              onClick={() => handleDevPortal(api)}
                            >
                              Retire from Dev Portal
                            </Dropdown.Item>



                          }
{/*}
                          <Dropdown.Item
                            key={2}
                            onClick={() => openEditDrawer(api)}
                          >
                            Edit
                          </Dropdown.Item>
*/}

                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </td>
                </tr>
              ))}
{/*}
                  {filteredApis.length < 3 &&
                    <div style={{ height:'95px' }}></div>
                  }
*/}

            </tbody>
          </Table>
        </div>
      )}

      <Drawer
        toggleSidebar={closeEditDrawer}
        isOpen={isEditDrawerOpen}
        name="Edit API"
        subtitle=""
      >
        <div>
          <h5>Edit API: {selectedApiForEdit?.api_product_name}</h5>
          <p>This is a simulated content for editing the API.</p>
          <Form.Group controlId="apiSelect">
            <Form.Label>Select API</Form.Label>
            <Form.Control
              as="select"
              value={selectedApi}
              onChange={handleApiChange}
            >
              <option value="">-- Select an API --</option>
              {apiOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button variant="primary" onClick={closeEditDrawer}>
            Close
          </Button>
        </div>
      </Drawer>
    </DashLayout>
  );
};

export default ApiCatalog;
