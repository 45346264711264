import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Spinner, Dropdown, Form } from 'react-bootstrap';
import { faCheck, faEllipsisV, faExternalLinkSquareAlt, faLink, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import Drawer from './Drawer';
import DevPortalForm from './DeveloperPortalForm';
import devIcon from '../../assets/img/ui_icons/cld_org-s.svg'
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Organizations from '../../newViews/avapCloud/organizations';
import SessionService from '../../services/SessionsService';
import config from '../../configs/appConfig.json';
import { useHistory } from 'react-router-dom';


// Tipo para un Proxy
type DevPortal = {
  id?: string; // Opcional
  basePath: string;
  name: string;
  description: string;
  products: any[];
  projects: any[];
  config: any;
};

const DevPortalManager = () => {
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editingProxy, setEditingProxy] = useState<DevPortal | null>(null);
  const [filteredProxies, setFilteredProxies] = useState<DevPortal[]>([]);
  const [filtro, setFiltro] = useState('');
  const [certsT, setCertsT] = useState([]);

  const [selBackgroudColor, setSelBackgroudColor] = useState('#f8f9fa')
  const [selprimaryColor, setSelPrimaryColor] = useState('#007bff')
  const [selsecondaryColor, setSelSecondaryColor] = useState('#6c757d')
  const [selTextColor, setSelTextColor] = useState('#212529')
  const [selLogo, setSelLogo] = useState('https://ui-avatars.com/api/?name=Avanquia&background=007bff&color=fff&size=128')

  const [proxies, setProxies] = useState<DevPortal[]>([]);
  const [allproxies, setAllProxies] = useState<DevPortal[]>([]);

  const [portalConfig, setPortalConfig] = useState({});
  const history = useHistory();
  const handleSaveProxy = (newProxy: DevPortal) => {
  

    console.log(newProxy);
    setDrawerOpen(false);
    getProxies();
    getCerts();
    history.push('/dev_portals')
  };


  const handleOpenDrawer = async (proxy?: DevPortal) => {
    
    if (proxy){ 
    const logo = await ObexRequestHandler.get(`/publishing/get_dev_portal_logo/${proxy.basePath.replace('/_', '')}`, {}, true)
    //console.log(logo);
    const config_h = await ObexRequestHandler.get(`/publishing/get_dev_portal_config/${proxy.basePath.replace('/_', '')}`, {}, true)
    
    const { data } = config_h;

    
    let config_theme= {
      backgroundColor: data.backgroundColor || '#f8f9fa',
      primaryColor: data.primaryColor || '#007bff',
      secondaryColor: data.secondaryColor || '#6c757d',
      textColor: data.textColor || '#212529',
      logo: logo.data.logo || '',
      layout: data.layout || 1,
      visibility: data.visibility
    }
    if (data!=undefined){
      const { theme } = data;
      if (theme!=undefined){
        //console.log(theme);
        config_theme = {
          backgroundColor: theme.backgroundColor,
          primaryColor: theme.primaryColor,
          secondaryColor: theme.secondaryColor,
          textColor: theme.textColor,
          logo: logo.data.logo,
          visibility: theme.visibility,
          layout: theme.layout
        }
        proxy.config = config_theme;

        setPortalConfig({
          backgroundColor: theme.backgroundColor,
          primaryColor: theme.primaryColor,
          secondaryColor: theme.secondaryColor,
          textColor: theme.textColor,
          logo: logo.data.logo,
          layout: theme.layout,
          visibility: theme.visibility
        })
      }
      if (data != undefined) {
        const { theme } = data;
        if (theme != undefined) {
          //console.log(theme);
          config_theme = {
            backgroundColor: theme.backgroundColor,
            primaryColor: theme.primaryXolor,
            secondaryColor: theme.secondaryColor,
            textColor: theme.textColor,
            logo: data.logo,
            visibility: theme.visibility,
            layout: theme.layout
          }
          proxy.config = config_theme;
        }
      }
    }
  }

    setEditingProxy(
      proxy || {
        id: undefined,
        basePath: '',
        name: '',
        description: '',
        products: [],
        projects: [],
        config: {
          backgroundColor: '#f8f9fa',
          primaryColor: '#007bff',
          secondaryColor: '#6c757d',
          textColor: '#212529',
          logo: '',
          visibility: false,
          layout: 1
        }
      }
    );
    
    setDrawerOpen(true);
    
  };

  const handleDeleteProxy = async (proxy) => {
    if (window.confirm('Are you sure you want to delete this proxy?')) {

      console.log(proxy);
      //setProxies(proxies.filter((proxy) => proxy.id !== id));
      await ObexRequestHandler.post(`/publishing/delete_dev_portal?id=${proxy.id}`)
    }
    getProxies();
    //const history = useHistory();
    
  };


  const getCerts = async () => {
    const certs = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`)

    setCertsT(certs.data);

  };


  const getProxies = async () => {
  history.push('/dev_portals')
    setProxies([]);
    setFilteredProxies([]);

    const proxies_configs = await ObexRequestHandler.get(`/gateway/get_dev_portal_by_org?org_id=${SessionService.ORGANIZATION}`, {}, true)
    history.push('/dev_portals')
    const proxies = proxies_configs.data.map((prox) => ({
      basePath: prox.route,
      id: prox.id,
      name: prox.name,
      description: prox.description,
      products: prox.associated_products,
      projects: prox.associated_projects
    }));


    history.push('/dev_portals')

    const filtered = proxies.filter((proxy) =>
      proxy.basePath.toLowerCase().includes(filtro.toLowerCase())
    );
    setAllProxies(proxies);
    setProxies(proxies);
    setFilteredProxies(filtered);
    history.push('/dev_portals')

  };



  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(e.target.value);

    filterProjects(e.target.value)

  };

  const filterProjects = async (filtro) => {

    console.log(allproxies);

    const filter_projects = allproxies.filter(element =>
      element.name.toLowerCase().includes(filtro.toLowerCase())
    );
    setProxies(filtro === '' ? allproxies : filter_projects);
    setFilteredProxies(filtro === '' ? allproxies : filter_projects)
  }

  useEffect(() => {
    getProxies();
    getCerts();
  }, []);

  return (
    <DashLayout sider={DashRouters} active="proxies" sublinks={[]}>
      <Row id="topHeader">
        <Col md="10">
          <div className="cld_wrapperTitle">
            <img src={devIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
            <span style={{ color: '#949498', fontSize: '14px' }}>Dev Portal </span>/ Developer Portals
            </span>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right  resBtn"
            onClick={() => handleOpenDrawer()}>
            Add Developer Portal
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <Row id='table' className='mt-5'>
        <Col>
          {loading ? (
            <Row style={{ textAlign: 'center' }}>
              <Col>
                <Spinner animation="border" style={{ marginLeft: '8px' }} />
              </Col>
            </Row>
          ) : (
            <div className='cld_borderWrapper'>
              <span className='cld_sectionTitle'>Developer Portals</span>

              <div className="searchContainer">
                <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by Developer portal name"
                  onChange={changeFilter} />
                <Button
                  className="customButton-transparent px-4"
                  onClick={getProxies}>
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </Button>

              </div>

              <Table className="cld_tableWrapper">
                <tbody>
                  <tr className='tableRow'>
                    <th style={{ width: '20%' }} className='textBold'>Base Path</th>
                    <th style={{ width: '15%' }} className='textBold'>Name</th>
                    <th style={{ width: '20%' }} className='textBold'>Description</th>
                    <th style={{ width: '20%' }} className='textBold'>API Products</th>
                    <th style={{ width: '20%' }} className='textBold'>API Projects</th>
                    <th style={{ width: '5%', textAlign: 'end' }} className='textBold'>Actions</th>
                  </tr>


                  {(filteredProxies.length > 0 ? filteredProxies : proxies).map((proxy) => (
                    <tr className="tableRow" key={proxy.id}>
                      <td className='td_centered'>
                        <div style={{display:'flex'}}>
                        <div>{proxy.basePath}</div>
                        <div style={{width:'100%', position:'absolute', marginLeft:'75%'}}>
                        <a 
                              href={`${config.url === 'https://avs-primary-pre.101obex.mooo.com' ? 'https://staging.avapid.com' : (config.url === 'http://localhost'? 'http://localhost:8080':'https://avapid.com')}/devportal/${proxy.basePath.replace('/_', '')}`}
                              target="_blank" 
                              rel="noopener noreferrer" 
                              title={`${config.url === 'https://avs-primary-pre.101obex.mooo.com' ? 'https://staging.avapid.com' : (config.url === 'http://localhost'? 'http://localhost:8080':'https://avapid.com')}/devportal/${proxy.basePath.replace('/_', '')}`}
                              style={{ textDecoration: 'none', color: 'inherit' }} // Opcional: evita estilos de enlace
                          >
                          <FontAwesomeIcon icon={faExternalLinkSquareAlt}/>
                          </a>
                        </div>
                        </div>
                        </td>
                      <td className='td_centered'>{proxy.name}</td>
                      <td className='td_centered'>{proxy.description}</td>
                      {/*<td>{proxy.selectedGA.join(', ')}</td>*/}
                      {/*<td>
                        {proxy.certs && proxy.certs.length > 0
                          ? proxy.certs
                          : 'No certificates'}
                      </td>
                      <td>{proxy.balance ? 'Enabled' : 'Disabled'}</td>
                      <td>{proxy.algorithm || 'N/A'}</td>*/}
                      <td className='td_centered'>{proxy.products.map(pro => (pro.name))}</td>
                      <td className='td_centered'>{proxy.projects.map(pro => (pro.project_description))}</td>
                      <td className="td_centered text-right">
                        <div className="actionsWrapper">
                          <Dropdown className="my-auto" style={{ marginRight: '20px' }}>
                            <Dropdown.Toggle className="organizations">
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item key={1} onClick={() => handleOpenDrawer(proxy)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item key={2} onClick={() => handleDeleteProxy(proxy)}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
{/*}
                  {proxies.length < 3 &&
                    <div style={{ height: '70px' }}></div>
                  }

*/}

                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
      <Drawer
        isOpen={drawerOpen}
        toggleSidebar={() => setDrawerOpen(false)}
        name={!(editingProxy?.basePath == '') ? 'Edit Developer Portal' : 'Add Developer Portal'}
      >
        <></>

        <DevPortalForm
          opened={drawerOpen}
          isEditing={!(editingProxy?.basePath == '')}
          id={editingProxy?.id}
          basePath={editingProxy?.basePath || ''}
          name={editingProxy?.name || ''}
          description={editingProxy?.description || ''}
          products={editingProxy?.products || []}
          projects={editingProxy?.projects || []}
          config={portalConfig || {}}
          onSubmit={handleSaveProxy}
        />
      </Drawer>
    </DashLayout>
  );
};

export default DevPortalManager;
