import React from "react";
import logos from "../../../assets/img/landingPage/LogosFamilia_pie.svg";

import icn_mail from "../../../assets/img/landingPage/icono_mail.svg";
import icn_fb from "../../../assets/img/landingPage/icono_Socialmedia_fb.svg";
import icn_in from "../../../assets/img/landingPage/icono_Socialmedia_In.svg";
import icn_r from "../../../assets/img/landingPage/icono_Socialmedia_R.svg";
import icn_x from "../../../assets/img/landingPage/icono_Socialmedia_X.svg";
import icn_yt from "../../../assets/img/landingPage/icono_Socialmedia_YT.svg";
import icn_location from "../../../assets/img/landingPage/icono_ubicacion.svg";

const Footer = () => {
    return (
        <footer className="fl-footer">
            {/* Primera fila */}
            <div className="fl-footer-row">
                <p className="fl-footer-logos">
                    <img src={logos} alt="Logos Familia" />
                </p>
            </div>

            {/* Segunda fila */}
            <div className="fl-footer-row grid">
                <div>
                    <h4>Products & Services</h4>
                    <ul>
                        <li>
                            <a href="/avap">AVAP Advanced Virtual API Programming</a>
                        </li>
                        <li>
                            <a href="/avs">AVAP Virtual Server</a>
                        </li>
                        <li>
                            <a href="/gateway">AVAP Gateway Advanced</a>
                        </li>
                        <li>
                            <a href="/devstudio">AVAP Dev Studio</a>
                        </li>
                        <li>
                            <a href="/cloud">AVAP Cloud</a>
                        </li>
                        <li>
                            <a href="/collaborative">AVAP Collaborative Network</a>
                        </li>
                    </ul>
                </div>
                <div>
                    {/* <h4>AVAP ID</h4>
                    <ul>
                        <a href="/avap" target="_blank" rel="noopener noreferrer">AVAP ID</a>
                    </ul> */}

                    <h4>AI Brunix</h4>
                    <ul>
                           <a href="/brunix" rel="noopener noreferrer">AI -Brunix</a>
                    </ul>
                </div>
                <div>
                    <h4>Resources</h4>
                    <ul>
                        <li>
                            <a href="/default" target="_blank" rel="noopener noreferrer">AVAP Developer Portal</a>
                        </li>
                        <li>
                            <a href="https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US" target="_blank" rel="noopener noreferrer">AVAP Alexandria</a>
                        </li>
                        <li>
                            <a href="https://speed-computing-1656.my.site.com/communities/s/" target="_blank" rel="noopener noreferrer">AVAP Communities</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>Company</h4>
                    <ul>
                        <li>
                            <a href="/about">About Us</a>
                        </li>
                        <li>
                            <a href="/contact">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>Legal</h4>
                    <ul>
                        <li>
                            <a href="https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US" target="_blank">T&C AVAP Collaborative Network
                            </a>
                        </li>
                        <li>
                            <a href="https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US" target="_blank">T&C AVAP Cloud
                            </a>
                        </li>
                        <li>
                            <a href="https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US" target="_blank">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Tercera fila */}
            <div className="fl-footer-row flex fl-border-top">
                <div>
                    <p>Corporate head office</p>
                    <p>1010BEX, CORP 440 N Wolfe RD Sunnyvale 94085, CA</p>
                </div>
                <div>
                    <p><img src={icn_mail} alt="Correo Electrónico" /> sales@1010bex.com</p>
                    <p className="fl-footer-socials">
                        <a href="https://www.linkedin.com/company/avap-framework" target="_blank"><img src={icn_in} alt="LinkedIn" /></a>
                        <a href="https://www.youtube.com/@AVAPFramework'" target="_blank"><img src={icn_yt} alt="YouTube" /></a>
                        <a href="https://x.com/AvapFramework" target="_blank"><img src={icn_x} alt="X (Twitter)" /></a>
                        <a href="https://www.facebook.com/AVAPFramework" target="_blank"><img src={icn_fb} alt="Facebook" /></a>

                        {/* <a href="https://www.reddit.com/user/AVAP_Framework/" target="_blank"><img src={icn_fb} alt="Facebook"  /></a> */}
                    </p>
                </div>
            </div>

            {/* Cuarta fila */}
            <div className="fl-footer-row center fl-border-top">
                <p>&copy; 2024 1010BEX, CORP. All rights reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
