import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Table,
  Dropdown,
  Navbar,
  Nav,
  NavDropdown,
  ListGroup,
  FormControl
} from "react-bootstrap";
import { FaTh, FaList } from "react-icons/fa";
import { useHistory, useParams } from 'react-router-dom';
import Drawer from "../avapCloud/components/Drawer"; 
import CreateAppForm from "./CreateAppForm";
import AddUsersForm from "./AddUsersForm";
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import logo from "../../assets/img/AVAP_DeveloperPortal2.png";

import sun from '../../assets/img/ui_icons/sun.svg'
import moon from '../../assets/img/ui_icons/moon.svg'

const cookies = new Cookies();

interface App {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  apiProducts: string[];
}

interface ApiProduct {
  title: string;
  description: string;
  latestVersion: string;
  details: string;
}

interface Portal {
  portal_id: number;
  id: string;
  name: string;
  theme: {
    primaryColor: string;
    secondaryColor: string;
    backgroundColor: string;
    textColor: string;
  };
  user: {
    name: string;
    photo: string;
  };
  apiProducts: ApiProduct[];
}

const CatalogContent: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [viewMode, setViewMode] = useState<"card" | "list">("card");
  const [filteredApps, setFilteredApps] = useState<App[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerAddUsersOpen, setDrawerAddUsersOpen] = useState(false);
  const [selectedApp, setSelectedApp] = useState<any>({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [portal, setPortal] = useState<Portal | null>(null);
  const [apiProducts, setApiProducts] = useState<ApiProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ApiProduct[]>([]);
  const [userName, setUserName] = useState('unknown');
  
  // Manejo de dark mode
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const DevPortalExists = async () => {
    try {
      const portalCookie = cookies.get('portal');
      const userCookie = cookies.get('user');

      if (!portalCookie) {
        history.replace(`/devportal/${id}/login`);
        return;
      }
      setPortal(portalCookie);

      if (userCookie?.username) {
        setUserName(userCookie.username);
      }

      DevPortalProductsExists(portalCookie.portal_id);
      fetchApps(portalCookie.portal_id);
    } catch (error) {
      console.error(error);
      history.replace(`/devportal/${id}/login`);
    }
  };

  const DevPortalProductsExists = async (portal_id: number) => {
    try {
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_portal_products/${portal_id}`,
        {},
        true
      );
      const data = res.data;
      if (Array.isArray(data) && data.length > 0) {
        const mapped = data.map((prod: any) => ({
          title: prod.avap_api_product_name,
          description: prod.dev_portal_published_description,
          latestVersion: 'v0.1',
          details: prod.dev_portal_published_description + prod.avap_api_product_documentation
        }));
        setApiProducts(mapped);
        setFilteredProducts(mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchApps = async (portal_id: number) => {
    try {
      const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_apps/${portal_id}`, {}, true);
      const data = res.data;
      if (Array.isArray(data) && data.length > 0) {
        const apps = data.map((app: any) => ({
          id: app.app_id,
          name: app.app_name,
          description: app.app_description,
          createdAt: app.app_created_at,
          apiProducts: app.api_products.map((pp: any) => pp.product_name),
        }));
        setFilteredApps(apps);
      } else {
        setFilteredApps([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    DevPortalExists();
  }, [id]);

  const handleRemoveApp = async (app_id: string) => {
    if (!portal?.portal_id) return;

    if (window.confirm("Are you sure you want to delete this App?")) {
      const payload = {
        devportal_id: portal.portal_id,
        app_id: app_id
      };
      const res = await ObexRequestHandler.post(
        "/publishing/delete_dev_portal_app",
        payload
      );
      if (res) {
        fetchApps(portal.portal_id);
      }
    }
  };

  const toggleDarkMode = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (apiProducts.length > 0) {
      const filtered = apiProducts.filter((p) =>
        p.title.toLowerCase().includes(term)
      );
      setFilteredProducts(filtered);
    }
  };

  const handleLogout = () => {
    cookies.remove('user');
    cookies.remove('portal');
    history.push(`/devportal/${id}/login`);
  };

  if (!portal) return <div>Loading Portal...</div>;

  return (
    <div id="mainLayoutWrapper" className="main-layout">
      <Navbar  expand="lg" className="navbar-custom">
        <Navbar.Brand style={{ marginLeft: '10px' }}>
          <img src={logo} alt="" />
        </Navbar.Brand>

        <Nav className="ml-auto d-flex align-items-center">
          <Button variant="primary" onClick={() => setDrawerOpen(true)}>
            Create App
          </Button>

          <a
            style={{ color: 'black'/*portal.theme.primaryColor*/ }}
            className="ml-3"
            onClick={() => history.push(`/devportal/${id}/catalog`)}>
            My Apps
          </a>

          <NavDropdown title={<span>{userName}</span>} id="userDropdown">
            <NavDropdown.Item>Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
          </NavDropdown>

          <button
            style={{ marginLeft: '10px' }}
            onClick={toggleDarkMode}
            className="workspace-button">
            <img style={{ width: '20px' }} src={isDarkMode ? sun : moon} alt="Workspace Menu" />
          </button>

          
        </Nav>
      </Navbar>

      <div className="layout-body">
        <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
          <Form className="sidebar-search">
            <FormControl
              type="text"
              placeholder="Search APIs..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form>

          <ListGroup variant="flush" className="mt-4">
            {filteredProducts.map((api, index) => (
              <ListGroup.Item key={index}>
                <strong>{api.title}</strong> <br />
                <small>v. {api.latestVersion}</small>
                <br />
                <a
                  onClick={() => history.push(`/devportal/${id}/documentation`)}
                  style={{ color: portal.theme.primaryColor }}
                >
                  Documentation
                </a>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        <div className="content-area shadow-sm">
          <Container className="my-4">
            <Row className="mb-3">
              <Col>
                <h4 className="cld-txt">My Apps</h4>
              </Col>
              <Col className="justify-content-end d-flex">
                <Button onClick={() => setViewMode(viewMode === "card" ? "list" : "card")}>
                  {viewMode === "card" ? <FaList /> : <FaTh />}
                </Button>
              </Col>
            </Row>

            {filteredApps.length === 0 ? (
              <div className="text-center my-5">
                <p>You have no apps. Add a new app to get started!</p>
                <Button variant="primary" onClick={() => setDrawerOpen(true)}>
                  Add New App
                </Button>
              </div>
            ) : viewMode === "card" ? (
              <Row>
                {filteredApps.map((app) => (
                  <Col md={4} key={app.id} className="mb-4">
                    <Card>
                      <Card.Body>
                        <Card.Title>{app.name}</Card.Title>
                        <Card.Text>{app.description}</Card.Text>
                        <Card.Text>
                          <small className="text-muted">
                            Created on: {app.createdAt}
                          </small>
                        </Card.Text>
                        {app.apiProducts.length > 0 && (
                          <div>
                            <strong>API Products:</strong>
                            <ul>
                              {app.apiProducts.map((prod, index) => (
                                <li key={index}>{prod}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div className="dropDownCards">
                        <Dropdown className="my-auto">
                          <Dropdown.Toggle className="organizations">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedApp(app);
                                setDrawerOpen(true);
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleRemoveApp(app.id)}
                            >
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedApp(app);
                                setDrawerAddUsersOpen(true);
                              }}
                            >
                              Add Users
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Table className="cld_tableWrapper" hover>
                <thead>
                  <tr className="tableRow">
                    <th className="textBold">Name</th>
                    <th>Description</th>
                    <th>Created At</th>
                    <th>API Products</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredApps.map((app) => (
                    <tr key={app.id}>
                      <td>{app.name}</td>
                      <td>{app.description}</td>
                      <td>{app.createdAt}</td>
                      <td>
                        {app.apiProducts.length > 0 ? (
                          <ul>
                            {app.apiProducts.map((prod, idx) => (
                              <li key={idx}>{prod}</li>
                            ))}
                          </ul>
                        ) : (
                          "None"
                        )}
                      </td>
                      <td>
                        <Dropdown className="my-auto">
                          <Dropdown.Toggle className="organizations">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedApp(app);
                                setDrawerOpen(true);
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleRemoveApp(app.id)}
                            >
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedApp(app);
                                setDrawerAddUsersOpen(true);
                              }}
                            >
                              Add Users
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Container>
        </div>
      </div>

      <Drawer
        isOpen={drawerOpen}
        toggleSidebar={() => {
          setDrawerOpen(false);
          setSelectedApp({});
        }}
        name="Create/Edit App"
      >
        <CreateAppForm
          app_data={selectedApp}
          devportal_id={portal?.id?.toString()}
          onSubmit={() => {
            setDrawerOpen(false);
            if (portal?.portal_id) {
              fetchApps(portal.portal_id);
              DevPortalProductsExists(portal.portal_id);
            }
          }}
        />
      </Drawer>

      <Drawer
        isOpen={drawerAddUsersOpen}
        toggleSidebar={() => setDrawerAddUsersOpen(false)}
        name="Add Users"
      >
        <AddUsersForm />
      </Drawer>
    </div>
  );
};

export default CatalogContent;