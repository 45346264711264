import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import "./SiginSecurity.css";
import "./../NewDashboard.css";
import icono_email from "../../../assets/img/newDashboard/icono_email.png";
import icono_email2 from "../../../assets/img/newDashboard/icono_email2.png";
import icono_password from "../../../assets/img/newDashboard/icono_password.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import icon_name from "../../../assets/img/newDashboard/icon_name.png";

import icono_security from "../../../assets/img/newDashboard/icono_security.png";
import icono_recovery from "../../../assets/img/newDashboard/icono_recovery.png";
import icono_telefono from "../../../assets/img/newDashboard/icono_telefono.png";
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { Alert, Modal, Spinner, Toast } from 'react-bootstrap';
import { unwatchFile } from 'fs';
import { faAngleRight, faExclamationTriangle, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const SiginSecurity = () => {
  let { path, url } = useRouteMatch();
  const [info, setInfo] = useState(null);
  const [email, setEmailAddress] = useState('');
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState(false);
  const [recoveryEmailPassword, setRecoveryEmailPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [enableTwoFactor, setEnableTwoFactor] = useState(false);
  const [newDates, setNewDates] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [whereWantGo, setWhereWantGo] = useState('');
  const [error, setError] = useState('');
  const [downloading, setDownloading] = useState(false);

  const toggleShowToast = () => setShowToast(!showToast);
  const history = useHistory();

  useEffect(() => {
    InfoSaS();
    window.scrollTo(0, 0);
  }, []);

  const InfoSaS = async () => {
    try {
      const result = (await ObexRequestHandler.get('/signin_security_info')).data;
      setInfo(result);
      // console.log('GET /signin_security_info:', result);
      setEmailAddress(result.email);
      setRecoveryEmail(result.recovery_email=='null' ? "": result.recovery_email );
      setPhoneNumber(result.phone_number=='null' ?"": result.phone_number);
      if (result.change_password_email) {
        setPrimaryEmail(result.change_password_email.primary_email);
        setRecoveryEmailPassword(result.change_password_email.recovery_email);
      }
      // console.log('Value of primaryEmail:', result.change_password_email.recovery_email);
    } catch (error) {
      console.error('ERROR GET PRIVACY INFO', error);
    }
  };

  const postEnableTwoFactor = async () => {
    try {
      const result = (await ObexRequestHandler.post('/enable_two_factor_auth', {
        enable_2FA: true
      })).data;
      setEnableTwoFactor(result);
      console.log('postEnableTwoFactor', result);
    } catch (error) {
      console.error('ERROR', error);
    }
  };

  const DownloadCredentials = async () => {
    try {
      setDownloading(true);
      const result = (await ObexRequestHandler.get('/identification/get_credentials')).data;
      //setEnableTwoFactor(result);
      //console.log('postEnableTwoFactor', result);



        const element = document.createElement('a');
        const file = new Blob([JSON.stringify(result)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'credentials.key';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      
        setDownloading(false);

    } catch (error) {
      console.error('ERROR', error);
      setDownloading(false);
    }
    setDownloading(false);
  };

  const postChangePassword = async (emailType) => {
    try {
      const result = (await ObexRequestHandler.post('/set_change_password_email', {
        change_password_email: emailType
      })).data;
      setChangePassword(result);
      console.log('POST /set_avap_analytics:', result);
    } catch (error) {
      console.error('ERROR', error);
    }
  };

  const postEmailPhone = async (newEmail, newRecoveryEmail, newPhoneNumber) => {
    try {
      const result = (await ObexRequestHandler.post('/email_phone', {
        recovery_email: newRecoveryEmail,
        phone: newPhoneNumber
      })).data;
      setNewDates(result);
      console.log('POST /email_phone:', result);
    } catch (error) {
      console.error('ERROR', error);
    }
  };

  const handlePrimaryEmailChange = () => {
    setPrimaryEmail(true);
    setRecoveryEmailPassword(false);
    setShowToast(true);
    setShouldBlockNavigation(true)
  };

  const handleRecoveryEmailPasswordChange = () => {
    setRecoveryEmailPassword(true);
    setPrimaryEmail(false);
    setShowToast(true);
    setShouldBlockNavigation(true)
  };

  function isMail(value) {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
  }

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmailAddress(newEmail);
    postEmailPhone(newEmail, recoveryEmail, phoneNumber);
  };

  const handleRecoveryEmailChange = (e) => {
    const newRecoveryEmail = e.target.value;
    setRecoveryEmail(newRecoveryEmail);
    if (isMail(newRecoveryEmail) == true){
    setShowToast(true)
    setShouldBlockNavigation(true)
    } else {
      setShowToast(false)
      setShouldBlockNavigation(false)
    }
  };
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
}

function isPhonne(value){

  return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

}

  const handlePhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value;
    
    setPhoneNumber(phoneNumber || '');
    //if (!isNumeric(newPhoneNumber)) return;
    if (!isPhonne(newPhoneNumber)) return;

    if (newPhoneNumber.length>12) return
    setPhoneNumber(newPhoneNumber);
    if(newPhoneNumber.length>8){
    setShowToast(true)
    setShouldBlockNavigation(true)} else {
      setShowToast(false)
    setShouldBlockNavigation(false)
    }
    
  };

  const OutOfInputWithEnter = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const applyChanges = () => {
    
    var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    var phoneFormat = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{3}$/;

    var check_email = email.toString().match(emailFormat)
    let check_recoveryEmail = undefined;
    if(recoveryEmail){
      check_recoveryEmail = recoveryEmail.toString().match(emailFormat)
    }
    let check_phone = undefined;

    if(phoneNumber){
      check_phone = phoneNumber.toString().match(phoneFormat)
    }

    if (check_email && (email !=recoveryEmail) &&((recoveryEmail && check_recoveryEmail) || !recoveryEmail) && ((check_phone && phoneNumber) || !phoneNumber )) {
      postEmailPhone(email, recoveryEmail, phoneNumber);
      if(primaryEmail===true){
        postChangePassword("primary_email");
      }else{
        postChangePassword("recovery_email");
      }
    }else{
      if(check_email === null){
        setError('Please enter a correct email address')

      }else if (check_recoveryEmail ===null) {
        setError('Please enter a correct email address')
      }else if(check_phone ===null){
        setError('Please enter a correct phone number')
      }else if(email == recoveryEmail){
        setError('Please enter a different recovery email address')

      }
      else {
        setError('Error at email')

      }
      setTimeout(function () {
        setError('');
      }.bind(this), 3000)
    }
    


    setShowToast(false);
    setShouldBlockNavigation(false)
  };

  const NoapplyChanges = async() => {
    
    setShowModal(false); // Ocultar el modal
    setShowToast(false);
    await setShouldBlockNavigation(false)
  };

  const handleConfirmNavigation = async () => {
    // debugger
    setShowModal(false); // Ocultar el modal
    setShowToast(false);
    await setShouldBlockNavigation(false)
    history.push(whereWantGo);
  };


  useEffect(() => {
    // Verificar si se debe bloquear la navegación
    // debugger
    if (shouldBlockNavigation==true) {
      // Bloquear la navegación al componente
      const unblock = history.block((location, action) => {
        setShowModal(true); // Mostrar el modal al intentar navegar

        // Obtener la ruta hacia donde se intenta navegar
        const destinationPath = location.pathname;

        setWhereWantGo(destinationPath);

        return false; // Devolver false para bloquear la navegación
      });

      return () => {
        // Desbloquear la navegación al salir del componente
        unblock(); // Llamar a unblock para desbloquear la navegación
      };
    }
  }, [history, showToast]);

  return (
    <div style={{marginRight:''}}>
       <div className='custom_warning'>
        <Toast show={showToast} onClose={toggleShowToast} className="custom_toast">

        <Toast.Body className='custom_toast_body'>
            <div className='d-flex justify-content-end'>
              <div className='mr-2'>Data modified, would you like to save it?</div>
              <div className='mr-2 textBold' onClick={applyChanges} style={{cursor:"pointer"}}>Yes</div>
              <div className='textBold' onClick={NoapplyChanges} style={{cursor:"pointer"}}>No</div>
            </div>
          </Toast.Body>
        </Toast>
        
                  {error.length > 0 && (
                    <Alert style={{position:'absolute', zIndex:99999999, width:'93%', marginTop:'5px'}} variant='danger'>
                      {error}
                    </Alert>)}
                
      </div>
      
      <h2 className='textBold marginBottom2rem'>Sign-In and Security</h2>
      <p className='fontText3 colorBlack marginBottom2rem'>Manage settings related to signing in to your account, account security and how to recover your data when you are having trouble signing in.</p>
      <div className="grid-container">
        <div className="boxShadowCard borderRadius">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icono_email} alt="" width={"100%"} height={"100%"} style={{ marginTop: "5px" }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Email & Phone Number</div>
            </div>

            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column boxShadowCardBottom mb-2'>
                <div className='fontText1'>Email address</div>
                <div className='fontText2 colorBlack d-flex'>
                  {/*}<div className='mr-2'>
                    <div className='size_icon_content'>
                      <img src={icono_email2} alt="" width={"100%"} height={"100%"} />
                    </div>
                  </div>*/}
                  <input
                    className='input-reset'
                    type="text"
                    placeholder={email}
                    readOnly={true}
                    style={{width:'100%'}}
                  />
                </div>
              </div>

              <div className='d-flex flex-column boxShadowCardBottom mb-2'>
                <div className='fontText1'>Recovery email</div>
                <div className='fontText2 colorBlack d-flex align-items-baseline'>
                  {/*<div className='mr-2'>
                    <div className='size_icon_content'>
                      <img src={icono_email2} alt="" width={"100%"} height={"100%"} />
                    </div>
                  </div>*/}
                  <input
                    className='input-reset'
                    type="text"
                    placeholder={recoveryEmail}
                    onChange={handleRecoveryEmailChange}
                    onKeyDown={OutOfInputWithEnter}
                    style={{width:'100%'}}
                  />
                </div>
              </div>

              <div className='d-flex flex-column boxShadowCardBottom'>
                <div className='fontText1'>Phone Number</div>
                <div className='fontText2 colorBlack d-flex align-items-baseline'>
                  {/*}
                  <div className='mr-2'>
                    <div className='size_icon_content2'>
                      {/*<img src={icono_telefono} alt="" width={"100%"} height={"100%"} />
                    </div>
                  </div>*/}
                  <input
                    className='input-reset'
                    type="text"
                    placeholder={phoneNumber}
                    style={{width:'100%'}}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onKeyDown={OutOfInputWithEnter}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="boxShadowCard borderRadius">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icon_name} alt="" width={'100%'} height={'100%'} style={{ marginTop: '0px' }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold' style={{ marginTop: '0px' }} >Credentials</div>
            </div>

            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column'>
                <div className='fontText2 colorBlack'>Download your credentials file.</div>
                <div className='fontText2 colorBlack'>You may need it for different products and services to validate your licenses.</div>
                <div style={{ paddingTop:'15px' }} className='fontText2 colorBlack'><FontAwesomeIcon style={{ marginRight:'7px' }} icon={faExclamationTriangle}/>By clicking and generating a new credential file, you revoke previous credential files.</div>
              </div>

              <div className='d-flex flex-column pt-5'>
                <div className='fontText1 '>
                  <button disabled={downloading} onClick={DownloadCredentials} className="btn custom-button w-100 textBold">Download your credentials file {downloading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px', marginBottom:'3px' }}/>} </button>
                </div>
              </div>

{/*
              <div className='d-flex flex-column pt-5'>
                <div className='fontText2 colorBlack d-flex flex-row mb-2'>
                  <div className='mr-1'>
                    <div className="checkbox-containerSS" style={{ marginTop: "2px" }}>
                      <input type="checkbox" checked={primaryEmail} onChange={handlePrimaryEmailChange} />
                    </div>
                  </div>
                  <div>
                    Primary email
                  </div>
                </div>

                <div className='fontText2 colorBlack d-flex flex-row'>
                  <div className='mr-1'>
                    <div className="checkbox-containerSS" style={{ marginTop: "2px" }}>
                      <input type="checkbox" disabled checked={recoveryEmailPassword} onChange={handleRecoveryEmailPasswordChange} />
                    </div>
                  </div>
                  <div>
                    {`Recovery email (not available at the moment)`}
                  </div>
                </div>
              </div>

*/}


            </div>
          </div>
        </div>

        <div className="boxShadowCard borderRadius">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icono_security} alt="" width={"100%"} height={"100%"} style={{ marginBottom: "5px", width: "20px" }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Account Security</div>
            </div>

            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column'>
                <div className='fontText2 colorBlack'>When you sign in on a new device or web browser, a verification code will be sent to your email.</div>
              </div>
              <div className='d-flex flex-column pt-5'>
                <div className='fontText1 '>
                  <button disabled onClick={postEnableTwoFactor} className="btn custom-button w-100 textBold">Enable Two-Factor Authentication</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="boxShadowCard borderRadius">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icono_recovery} alt="" width={"100%"} height={"100%"} style={{ marginTop: "2px" }} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Account Recovery</div>
            </div>

            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column'>
                <div className='fontText2'>If you lose access to your account, a recovery method can help you get your account and data back. Send an email to <span className='colorBlack textBold'>support@101obex.com</span> with <span className='colorBlack textBold'>subject</span> <span className='font-italic'>"Account Recovery"</span> and in the <span className='colorBlack textBold'>body</span> send us your <span className='font-italic'>AVAP ID Primary email.</span> </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body className='colorBlack'>Do you want to leave without saving your changes?</Modal.Body>
        <Modal.Footer>
          <button className="btn custom-button  textBold" onClick={handleConfirmNavigation}>
            Yes
          </button>

          <button className="btn custom-button  textBold" onClick={handleClose}>
            No
          </button>
          
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SiginSecurity;