import { useEffect } from 'react';
import { metaConfig } from '../configs/metaConfig';

const useMetaTags = () => {
	useEffect(() => {
		// Obtener la ruta actual
		const currentPath = window.location.pathname;

		// Buscar configuración de meta tags en la lista
		const meta = metaConfig.find((item) => item.path === currentPath);

		if (meta) {
			// Configurar el título
			document.title = meta.title;

			// Configurar meta description
			let metaDescription = document.querySelector("meta[name='description']") as HTMLMetaElement;
			if (!metaDescription) {
				metaDescription = document.createElement('meta') as HTMLMetaElement;
				metaDescription.name = 'description';
				document.head.appendChild(metaDescription);
			}
			metaDescription.content = meta.description;

			// Configurar URL canonical
			let linkCanonical = document.querySelector("link[rel='canonical']") as HTMLLinkElement;
			if (!linkCanonical) {
				linkCanonical = document.createElement('link') as HTMLLinkElement;
				linkCanonical.rel = 'canonical';
				document.head.appendChild(linkCanonical);
			}
			linkCanonical.href = meta.url;
		} else {
			// Valores por defecto si no hay configuración
			document.title = 'AVAP Framework';
		}
	}, []);
};

export default useMetaTags;
