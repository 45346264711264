import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Spinner,Image } from 'react-bootstrap';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import "./ProjectGranted.css"
import "./../NewDashboard.css"
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import Logo from '../../../assets/img/ilustracion_noInfo2-green.png';
import SessionService from '../../../services/SessionsService';


const ProjectGranted = () => {
  let { path, url } = useRouteMatch();

  const history = useHistory();
  const [Allprojects, setAllprojects] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [get_personal_info, setGet_personal_info] = useState<any>({});


  const getPersonalInformation = async () => {
    try {
      // debugger
      const result = (await ObexRequestHandler.get('/get_personal_info')).data;
      setGet_personal_info(result);
      // console.log(result.image);
    } catch (error) {
      console.error('ERROR FETCHING PERSONAL INFO ', error);
    }
  }


  const getAllprojects = async () => {
    try {
      let result = (await ObexRequestHandler.get('/allprojects')).data;
      if (result == undefined) result = []
      setAllprojects(result.projects.concat(result.p2p_projets));
      setIsLoading(false);

    } catch (error) {
      setAllprojects([]);
      
      //console.error('ERROR FETCHING PERSONAL INFO ', error);
    }
    setIsLoading(false);
  }
  const [creatingToken, setCCreatingToken] = useState(false);
  const [projectToken, setProjectToken] = useState('');

  const createToken = async (project_id, id_org, dev_tok_id) => {
    console.log(project_id);
    console.log(id_org);
    console.log(dev_tok_id);

    setCCreatingToken(true)
    creatingToken
    const payload = {}
    let result;
    if (!dev_tok_id.includes('p2p')) {
    result = await ObexRequestHandler.post(`/assign_developer_token?project_id=${project_id}&id_org=${id_org}&avapid=${get_personal_info.email}`, payload);
    } else {
      result = await ObexRequestHandler.post(`/assign_developer_token?project_id=${project_id}&id_org=${id_org}&avapid=${get_personal_info.email}`, payload, true);
      
    }
    setCCreatingToken(false)
    setProjectToken(result.data);
    const token_info = document.getElementById(dev_tok_id.toString().replace('-p2p', '')) as HTMLDivElement
    token_info.innerText = result.data;
    

  }

  const generateItemProjects = (projects) => {
    if (projects == undefined) return;
    let arrayDivsProyects = [];
    arrayDivsProyects = projects.map((item, index) => (
      <div className="boxShadowCard borderRadius " key={index} style={{minWidth:'100%', maxWidth:'57%'}}>
        <div className='py-3 d-flex flex-column'>
          <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
            <div className='fontSizeTitleCard textBold'>{item.description}</div>
          </div>
          <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
            <div className='d-flex  boxShadowCardBottom justify-content-between pt-3'>
              <div className='fontText2 textBold color'>Project Code Name</div>
              <div className='c-color'>{item.name}</div>
            </div>
            <div className='d-flex  boxShadowCardBottom justify-content-between  pt-3'>
              <div className='fontText2 textBold color'>Dev Token</div>
              <div className='overflowX' style={{width:"75%", overflow:'auto', textAlign:'end'}} id={`dev-${index}`}>{item.developer_token || item.token}</div>
            </div>
            <div className='d-flex  boxShadowCardBottom justify-content-between  pt-3'>
              <div className='fontText2 textBold color'>Manager</div>
              <div>{item.manager || document.getElementById('id_owner').textContent}</div>
            </div>
            <div className='d-flex boxShadowCardBottom justify-content-between pt-3 '>
              <div className='fontText2 textBold color'>email</div>
              <div className='c-color'>{item.email || item.manager_email}</div>
            </div>
            <button style={{ marginTop:'20px' }} onClick={()=>{

              createToken(
                item.project_id || item.obex_project_id,  
                item.organization || item.org_id, 
                item.project_id != undefined ? `dev-${index}` : `dev-p2p-${index}`
              );

            }} className="btn textBold custom-button">Generate Project Access Token</button>
            <div style={{display:'flex'}}>
            <div className="select-container d-flex mb-5" style={{ marginTop: '20px', width:'50%'}}>
              <div className=' border-right backgroundSelect borderRadiusLeft d-flex align-items-center px-2'>Platform</div>
              <select id={index} className="form-select p-2 borderRadiusRight backgroundSelect colorBlack" aria-label="Default select example">
                <option value="0">Windows</option>
                <option value="1">macOS</option>
                <option value="2">Linux</option>
              </select>
              
            </div>
            <div className="d-flex justify-content-end pt-3" style={{ width:'50%', marginTop:'5px', height:'54px'}}>
              <button className="textBold custom-button w-100" style={{ marginRight: "0px", padding:'3px', marginLeft:'10px' }} onClick={()=>{
                var plat = document.getElementById(index) as HTMLSelectElement;
                console.log(plat.options[plat.value].text.toLowerCase())

                var op = plat.options[plat.value].text.toLowerCase();
                let url = '';
                if (op === 'windows') { url = 'https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease-staging/101obexcli-win32.zip'}
                if (op === 'linux') {url = 'https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease/101obexcli.-.linux.zip'}
                if (op === 'macos') {url = 'https://github.com/101OBeXCorp/101obexcli/releases/download/prerelease-staging/101obexcli-macosx.zip'}

                if (url !== undefined){
                  const link = document.createElement('a');
                  link.target = '';
                  link.href = url;
                  link.click();
                }
                
                }}>Download CLI</button>
              
            </div>
            </div>

          </div>
        </div>
      </div>
    ))
    
    return arrayDivsProyects;

  }

  // <Spinner animation="border" />

  useEffect(() => {
    getAllprojects();
    getPersonalInformation();
    window.scrollTo(0, 0);
  }, [])



  return (
    <div>

      <h2 className='textBold marginBottom2rem'>API Projects Granted</h2>
      <div>
        <p className='fontText3 colorBlack marginBottom2rem'>Complete view of your assigned projects.</p>
      </div>

      {isLoading &&
        <div className='d-flex justify-content-center align-items-center '>

          <Spinner animation="border" />
        </div>
      }

      <div className="grid-container" style={{width:'65vw'}}>

        {generateItemProjects(Allprojects)}


      </div>


      {(Allprojects.length == 0 && isLoading == false) &&
      <div>
      <><Row className="justify-content-center my-4">
          <Col xs="6" style={{display:'flex', justifyContent:'center'}}>
            <Image style={{width:'50%', marginTop:'60px', marginRight:'0'}} src={Logo} fluid />
          </Col>
          </Row>
          <Col style={{textAlign:'center', marginBottom:'60px', marginRight:'0'}}>
          <Row style={{marginRight:'0'}}><Col><h5>You don’t have data to show</h5></Col></Row>
          <Row style={{marginRight:'0'}}><Col>When you have data to show, you’ll find here.</Col></Row>
         {/*} <Row style={{marginRight:'293px'}}><Col>want to track, you'll find here. </Col></Row>*/}
          </Col>
        </>
        </div>
}


    </div>



  )
}

export default ProjectGranted;