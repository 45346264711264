import React, { useEffect, useState } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Form,
  FormControl,
  ListGroup
} from 'react-bootstrap';

import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import CreateAppForm from "./CreateAppForm";
import sun from '../../assets/img/ui_icons/sun.svg'
import moon from '../../assets/img/ui_icons/moon.svg'

import Drawer from '../avapCloud/components/Drawer';

import logo from "../../assets/img/AVAP_DeveloperPortal2.png";


interface ApiProduct {
  title: string;
  description: string;
  latestVersion: string;
  details: string;
}

interface Portal {
  portal_id: number;
  id: string;
  name: string;
  theme: {
    primaryColor: string;
    secondaryColor: string;
    backgroundColor: string;
    textColor: string;
  };
  user: {
    name: string;
    photo: string;
  };
  apiProducts: ApiProduct[];
}

const cookies = new Cookies();

const MainLayout: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  // States del layout
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [portal, setPortal] = useState<Portal | null>(null);
  const [apiProducts, setApiProducts] = useState<ApiProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ApiProduct[]>([]);

  // Manejo de dark mode
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  // Para mostrar el nombre del usuario
  const [userName, setUserName] = useState('unknown');

  // Estado para el Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);


  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const DevPortalExists = async () => {
    try {
      const portalCookie = cookies.get('portal');
      const userCookie = cookies.get('user');

      if (!portalCookie) {

        history.replace(`/devportal/${id}/login`);
        return;
      }
      setPortal(portalCookie);

      if (userCookie?.username) {
        setUserName(userCookie.username);
      }

      DevPortalProductsExists(portalCookie.portal_id);
    } catch (error) {
      console.error(error);
      history.replace(`/devportal/${id}/login`);
    }
  };

  const DevPortalProductsExists = async (portal_id: number) => {
    try {
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_portal_products/${portal_id}`,
        {},
        true
      );
      const data = res.data;
      if (Array.isArray(data) && data.length > 0) {
        const mapped = data.map((prod: any) => ({
          title: prod.avap_api_product_name,
          description: prod.dev_portal_published_description,
          latestVersion: 'v0.1',
          details:
            prod.dev_portal_published_description +
            prod.avap_api_product_documentation
        }));
        setApiProducts(mapped);
        setFilteredProducts(mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    DevPortalExists();
  }, [id]);


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (apiProducts.length > 0) {
      const filtered = apiProducts.filter((p) =>
        p.title.toLowerCase().includes(term)
      );
      setFilteredProducts(filtered);
    }
  };


  if (!portal) return <div>Cargando Portal...</div>;

  return (
    <div id="mainLayoutWrapper" className="main-layout">

      <Navbar expand="lg" className="navbar-custom">
        {/* (Opcional) Botón que togglea el sidebar 
        <Button
          variant="outline-secondary"
          onClick={toggleSidebar}
          className="mr-2"
        >
          <span className="navbar-toggler-icon" />
        </Button>
        */}

        <Navbar.Brand style={{ marginLeft: '10px' }}>
          <img src={logo} alt="" />
        </Navbar.Brand>

        <Nav className="ml-auto d-flex align-items-center">

          <Button variant="primary" onClick={() => setDrawerOpen(true)}>
            Create App
          </Button>

          <a
            style={{ color: 'black'/*portal.theme.primaryColor*/ }}
            className="ml-3"
            onClick={() => history.push(`/devportal/${id}/catalog`)}>
            My Apps
          </a>


          <NavDropdown title={<span>{userName}</span>} id="userDropdown">
            <NavDropdown.Item>Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>Logout</NavDropdown.Item>
          </NavDropdown>

          <button
            style={{ marginLeft: '10px' }}
            onClick={toggleDarkMode}
            className="workspace-button">
            <img style={{ width: '20px' }} src={isDarkMode ? sun : moon} alt="Workspace Menu" />
          </button>
          
        </Nav>
      </Navbar>

      {/* CONTENIDO DEL LAYOUT */}
      <div className="layout-body">
        {/* SIDEBAR */}
        <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
          {/* Búsqueda */}
          <Form className="sidebar-search">
            <FormControl
              type="text"
              placeholder="Search APIs..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form>

          {/* Listado de APIs en el sidebar */}
          <ListGroup variant="flush" className="mt-4">
            {filteredProducts.map((api, index) => (
              <ListGroup.Item key={index}>
                <strong>{api.title}</strong> <br />
                <small>v. {api.latestVersion}</small>
                <br />
                <a
                  onClick={() => history.push(`/devportal/${id}/documentation`)}
                  style={{ color: portal.theme.primaryColor }}
                >
                  Documentation
                </a>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        {/* CONTENIDO PRINCIPAL */}
        <div className="content-area shadow-sm">
          <h3>{portal?.name ?? 'Portal'}</h3>
          <p>
            Content
          </p>
        </div>
      </div>

      {/* DRAWER */}
      <Drawer
        isOpen={drawerOpen}
        toggleSidebar={() => setDrawerOpen(false)} // Cierra el Drawer
        name="Create App"
      >


        <CreateAppForm

          app_data={{
            id: 0,
            name: undefined,
            description: undefined,
            latestVersion: undefined,
            createdAt: undefined,
            apiProducts: [],
            apiProductsID: [],
            tokens: [],
            tokensId: []
          }}
          onSubmit={() => {
            setDrawerOpen(false);
            DevPortalExists();

          }}
          devportal_id={id.toString()} ></CreateAppForm>
      </Drawer>
    </div>
  );
};

export default MainLayout;
