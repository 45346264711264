import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PaymentConfirmation from './paymentConfirmation';
import PaymentSuccess from './paymentSuccess';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import FetchingSpinner from '../basicComponents/loaderModal';
import { parseToCoin } from '../../services/AmountService';
import SessionService from '../../services/SessionsService';
import AddCreditCard from './addCreditCard';

type AddDeveloperModalSelProps = {
  shown: boolean,
  DeveloperMultiplier: number,
  possibleProjectsToBuy: number,
  onCloseAction: () => void
}

const AddDeveloperModalSel:FunctionComponent<AddDeveloperModalSelProps> = (props: AddDeveloperModalSelProps) => {
    
  const defaultSelectedPlan = { id: 0, monthly_price: 0, requests_number:0, projects_numbers: 0 } // Seteamos esto para por defecto no tener ninguno marcado
  const { shown, onCloseAction, possibleProjectsToBuy, DeveloperMultiplier } = props;

  const [fetchingPlans, setFetchingPlans] = useState(false);
  const [projectsPlans, setProjectsPlans] = useState([]);
  const [projectPlanSelected, setSelectedPlan] = useState(defaultSelectedPlan);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentEnable, setPaymentMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [cards_, setCards] = useState([]);
  const [card_id_stripe, setCardID] = useState('');
  const [newadded_card, setAddedCard] = useState('');

  const clearAll = () => {
    setError('');
    setSuccess('');
    setPaymentSuccess(false);
    setSelectedPlan(defaultSelectedPlan)
    onCloseAction();
  }

  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if(cards_.some(e => e.id == c.id)){
            //console.log('exists');
          }else{
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);
  
    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }
  const handleModal = () => {
    setShowModal(!showModal);
    //onCloseAction();
    getCards();

  };
  

  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value=='addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();
  
  }

  const OnSuccesAction = () =>{
    SessionService.renewSession();
    clearAll();
  }

  const getPlans = async () => {
    try {

      
      setFetchingPlans(true);
      const plans = (await ObexRequestHandler.get('/extradevelopers/plans')).data;
      setProjectsPlans(plans);
      //console.log('DEVS');
      //console.log(plans);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS PLANS ', error);
    }
    setFetchingPlans(false);
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const makePayment = async () => {
    try {
      startPayment();
      const payload = { extraDeveloperPlanId: projectPlanSelected.id, card_stripe_id: card_id_stripe };
      const result = await ObexRequestHandler.post('/extradevelopers/buy', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentMode(false);

      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setLoading(false);
  }

  const removeSelectedPlan = () => setSelectedPlan(defaultSelectedPlan);

  // TODO MOSTRAR DECIMALES
  const projectsPlansRows = projectsPlans.map(p => (
    <Fragment key={p.id}>
      { (p.developer_numbers <= possibleProjectsToBuy) &&
        <Row className={'addon addons-border py-3 mb-4 position-relative' + (projectPlanSelected.id === p.id && ' addons-active')}> 
        {projectPlanSelected.id === p.id && <FontAwesomeIcon icon={faCheck} />}
          <Col md="7">
            <Row>
              <Col className="addons-title">
                <span>{p.title}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span>{p.description}</span>
              </Col>
            </Row>
          </Col>
          <Col md="3" className="price-addons my-auto">
            <span>{`${parseToCoin(p.monthly_price)}/mo*`}</span>
          </Col>
          <Col md="2" className="my-auto">
            {projectPlanSelected.id === p.id ? 
              <button value="5" className="customButton-red float-right"  onClick={removeSelectedPlan}>Remove</button>:
              <button value="5" className="customButton-blue float-right" onClick={() => setSelectedPlan(p)}>Add</button>
            }
          </Col>
        </Row>
      }
    </Fragment>
  ))


  const selectPlanModalContent = (
    <Container fluid>
      {fetchingPlans ? <FetchingSpinner/> : projectsPlansRows}
      <Row className="mb-2">
        <Col className="text-center" style={{display:'flex'}}>
          {projectPlanSelected.id > 0
            ? <button style={{marginLeft:'30%', marginRight:'30%'}} className="w-100 customButton-blue w" onClick={() => setPaymentMode(true)}>Continue to Checkout</button>
            : <button disabled style={{marginLeft:'30%', marginRight:'30%'}} className="customButton-blue w-100 textBold">Select an Option</button>
          }
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <span>*Taxes and fees may apply</span>
        </Col>
    </Row>      
    </Container>
  )

  useEffect(() => {
    getPlans();
    getCards();
  }, [])


  return(
    <>

    <Modal dialogClassName="addons-dialog themeDialog w-75 obex-dialog"
      show={shown} 
      onHide={clearAll}
      animation={false}
      container={document.getElementById('dashboardWrapper')}>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center w-100">
          <Row>
            <Col className="addons-title">
              <span >{paymentSuccess ? 'Purchase confirmed':'Add Developers Slots to your account'}</span>
            </Col>
          </Row>
          <Row>
            <Col md="10" className="mx-auto text-center modal-subtitle">

            {
            paymentSuccess ? <>Slots added to your account</>:
            (paymentEnable ?
              <span className="text-center d-block">{success ?'Payment Completed!': 'Review your payment and proceed.'}</span>:
              <span className="text-center d-block">{success ?'Slots added to your account':  'Choose your package and continue to checkout.'}</span>)
              }
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {paymentEnable ? 
          <PaymentConfirmation
            actionSubmit={makePayment}
            handleGoBack={() => setPaymentMode(false)}
            handleAnnualPayment={null}
            nameServiceSelected={`Buying ${projectPlanSelected.projects_numbers} slots projects.`}
            amountMonth={projectPlanSelected.monthly_price}
            amountYear={projectPlanSelected.monthly_price}
            processingPayment={loading}
            success={success}
            error={error}
            isBlue={true}
            cards={cards_}
            handleCard={handleCard}
            addedCard={newadded_card}
            spanish={false}

          />
          : (paymentSuccess ? <PaymentSuccess blue={true} onCloseAction={()=>{clearAll();OnSuccesAction();}} paymentDescription={''} paymentType={''}/> : selectPlanModalContent)
        }
      </Modal.Body>
    </Modal>
        {showModal && <AddCreditCard show={showModal} handleClose={handleModal} isGreen={false} spanish={false}/>}
        </>

  );
}

export default AddDeveloperModalSel;


