import React, { useState, useEffect, useCallback } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown, Alert } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";
import devIcon from '../../assets/img/ui_icons/cld_org-s.svg';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";

import UnitTestForm from "./Publisher_unitTest";
import DocumentationForm from "./DevPortal_documentation";
import FileUpload from "./components/uploadFile";
import { useHistory } from "react-router-dom";

const DevPortalDocs = () => {

  const [isEditing, setIsEditing] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [SelectedPProductId, setSelectedPProductId] = useState('');

  const [selectedIndexId, setSelectedIndexId] = useState('');

  const [apis, setApis] = useState([]);
  const [indexs, setIndex] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [apiProducts, setApiProducts] = useState([]);
  const [loadingApiProducts, setLoadingApiProducts] = useState(false);
  const [isDocumentationDrawerOpen, setIsDocumentationDrawerOpen] = useState(false);
  const [isUnittestDrawerOpen, setIsUnittestDrawerOpen] = useState(false);

  const [jsonContent, setJsonContent] = useState(null);
  const [selectedApiForUnittest, setSelectedApiForUnittest] = useState(null);

  const [localEmail, setEmail] = useState('');
  const [localPassword, setPassword] = useState('');
  const [mustChange, setMustChange] = useState(false);

  const [actualDoc, setActualDoc] = useState('')
  const history = useHistory();
  ///custon upload file
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (file) => {
    setSelectedFile(file); 
};

const handleActualDoc = async (content) => {
  console.log('content', content);
  setActualDoc(content);

  console.log('developer_portal_id', selectedProjectId)
  console.log('index_id', selectedIndexId)
  console.log('name', localEmail);


  if (!isEditing){
  const payload = {
    dev_portal_id: selectedProjectId, 
    name: localEmail, 
    content: content, 
    index_id: selectedIndexId

  };
  await ObexRequestHandler.post('/publishing/add_dev_portal_document', payload);
} else {

  const payload = {
    doc_id: selectedDocId, 
    name: localEmail, 
    content: content, 

  };
  await ObexRequestHandler.post('/publishing/dev_portal_document_update', payload);
  ///publishing/dev_portal_document_update
}

  closeDrawer();
  fetchApisByProject();
  //console.log(actualDoc)
}


const handleDeleteProxy = async (proxy) => {
  if (window.confirm('Are you sure you want to delete this Doc?')) {

    console.log(proxy);
    await ObexRequestHandler.post(`/publishing/dev_portal_document_delete?doc_id=${proxy.document_id}`)
  }
  fetchProjects();
};


///**** */

const fetchIndexByDevPortal = async () => {
  if (!selectedProjectId) return;
setIndex([]);
  setLoading(true);
  try {
    const response = await ObexRequestHandler.get(`/publishing/get_dev_portal_index/${selectedProjectId}`, {}, true);
    console.log('Response from publishing/get_dev_portal_index', response);
    const data = response.data || [];

    const dataFiltered = []
    console.log(data);
    data.forEach(element => {
      if (element.api_id){
        if (element.has_documentation_entry	!== 'No') dataFiltered.push(element); else dataFiltered.push(element);
      } else dataFiltered.push(element);
    });


    setIndex(dataFiltered);
  } catch (error) {

    console.error('Error fetching APIs by project:', error);
  }
  setLoading(false);
};
///**** */

const handleOpenDrawer = async () => {
  setIsEditing(false);
  await fetchAPIProducts(selectedProjectId);
  openDrawer(null);
}

  const openDocumentationDrawer = async () => {

    


    setIsDocumentationDrawerOpen(true);
  };

  const closeDocumentationDrawer = () => {
    setIsDocumentationDrawerOpen(false);
  };

  const openUnittestDrawer = () => {
    setIsUnittestDrawerOpen(true);
  };

  const closeUnittestDrawer = () => {
    setIsUnittestDrawerOpen(false);
  };

  const fetchProjects = async () => {
    history.push('/dev_portals_docs')
    setLoading(true);
    setProjects([]);
    try {
      const response = await ObexRequestHandler.get(`/gateway/get_dev_portal_by_org?org_id=${SessionService.ORGANIZATION}`, {}, true)
      const projectsInfo = response.data || [];
      setProjects(projectsInfo);
      console.log(projectsInfo);
      setSelectedProjectId('0');
      setSelectedProjectId(projectsInfo[0].id)
      fetchAPIProducts(projectsInfo[0].id)
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
    
    setLoading(false);
    history.push('/dev_portals_docs')
  };


  const fetchAPIProducts = async (projects_id) => {
    setIndex([]);
    try {
      const response = await ObexRequestHandler.get(`/publishing/get_dev_portal_index/${projects_id}`, {}, true
      );
      const publishedApis = response.data || {};
      const products = publishedApis;//publishedApis.Products || [];

      if (products.length === 0) {
        console.warn('No API Products found for the selected project.');
      }

      setIndex(products.map((product) => ({ id: product.index_id, index_name: product.index_name })));
    } catch (error) {
      console.error('Error fetching API Products:', error);
      setIndex([]);
    } finally {
      setLoadingApiProducts(false);
    }
  }


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file && file.type === 'application/json') {
      const reader = new FileReader();

      reader.onload = () => {
        // Comprobamos si el resultado es una cadena
        const fileContent = reader.result;
        if (typeof fileContent === 'string') {
          try {
            const json = JSON.parse(fileContent);
            setJsonContent(json); // Asigna el contenido JSON a la variable
          } catch (error) {
            alert('El archivo no es un JSON válido');
          }
        } else {
          alert('El archivo no es un formato adecuado');
        }
      };

      reader.readAsText(file); // Lee el archivo como texto
    } else {
      alert('Por favor, seleccione un archivo JSON');
    }
  };



  const handleSubmitUser = async (e) => {
    e.preventDefault();

    const payload = {
                      devportal_id: selectedProjectId,
                      username: localEmail,
                      password: localPassword,
                      active: true
                    }

    await ObexRequestHandler.post('/publishing/add_dev_portal_user', payload)

    fetchApisByProject();

    closeDrawer();


  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Formulario enviado con el proyecto seleccionado:', selectedProjectId);
    console.log('Formulario enviado con el api product seleccionado:', SelectedPProductId);
    console.log('Contenido del archivo JSON:', jsonContent);

    //let url_service = `${cloud}/gateway/routing/add_gateway_link?obex_project_id=${SelectedProject}`

    //custom file
    const formData = new FormData();
    formData.append("file", selectedFile); // logica para subir el archivo

    const payload = {
      api_product_id: SelectedPProductId,
      my_api: jsonContent
      

    }

    await ObexRequestHandler.post(`/gateway/routing/add_gateway_link?obex_project_id=${selectedProjectId}`, payload)

    fetchApisByProject();

    closeDrawer();

  };


  const handleChangeIndex = async (e) =>{
    setSelectedIndexId(e.target.value);
  }

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setSelectedProjectId(value);
    await fetchAPIProducts(value);
    console.log(name, value);
  }

  const handleChangeProduct = async (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    setSelectedPProductId(value);
    console.log(name, value);
  }

  const handlePublish = async (api) =>{
 
    const payload = {
      gateway_id: api.gateway_id, 
      new_status: !api.is_published, 
      api_id: api.api_id, 
      api_product_id: api.api_product_id
    }

    await ObexRequestHandler.post('/update_portal_apis_status', payload);

    fetchApisByProject();

    //update_portal_apis_status
  }

  const fetchApisByProject = async () => {
    if (!selectedProjectId) return;

    setLoading(true);
    try {
      const response = await ObexRequestHandler.get(`/publishing/get_dev_portal_documents/${selectedProjectId}`, {}, true);
      console.log('Response from publishing/get_dev_portal_users', response);
      const data = response.data || [];

      const dataFiltered = []
      console.log(data);
      data.forEach(element => {
        if (element.api_id){
          if (element.has_documentation_entry	!== 'No') dataFiltered.push(element); else dataFiltered.push(element);
        } else dataFiltered.push(element);
      });

      setApis(dataFiltered);
    } catch (error) {
      console.error('Error fetching APIs by project:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProjects();
  }, []);



  const openDrawer = async (role) => {

    if (role!=null){
      setIsEditing(true);
    console.log(role);

    const datos = await ObexRequestHandler.get(`/publishing/get_dev_document/${role.document_id}`);

    const { data } = datos;

    console.log(data);
    setEmail(data.document_name);
    setSelectedIndexId(data.index_id);
    setActualDoc(data.document_content)
    setSelectedDocId(data.document_id);

    } else {
      setIsEditing(false)
    }

    setIsDrawerOpen(true);
    history.push('/dev_portals_docs')
  };

  const closeDrawer = () => {
    setEmail('')
    setIsDrawerOpen(false);
  };

  const handleEditUnittest = (api) => {
    setSelectedApiForUnittest(api);
    openUnittestDrawer();
  };


  useEffect(() => {
    if (selectedProjectId) {
      fetchApisByProject();
    } else {
      setApis([]);
    }
  }, [selectedProjectId]);

  const filteredApis = apis.filter(api =>
    api.document_name?.toLowerCase().includes(searchTerm.toLowerCase()) 
  );

  return (
    <DashLayout sider={DashRouters} active={'api-publisher'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8' >
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px"><span style={{ color: '#949498', fontSize: '14px' }}>Dev Portal </span>/ Documents </span>
            </div>

          </div>
        </Col>




        <Col md='4' className='align-content-center'>



          <Button style={{ width: '225px' }} className="customButton-black px-4 float-right  resBtn" onClick={handleOpenDrawer}>
            Add Document
          </Button>



          {/* Botón opcional  */}
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">
          <span className='cld_sectionTitle'>Select a Developer Portal</span>

          <div style={{display:'flex'}}>
          <Form.Group className="my-4" style={{ width:'59%' }}>
            <Form.Control as="select" onChange={(e) => setSelectedProjectId(e.target.value)} value={selectedProjectId || ''}>
              <option value="">-- Select a developer portal --</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>



          <div className="searchContainer mb-4" style={{ position:'absolute', marginLeft:'55%', width:'50%', marginTop:'24px' }}>
                <Form.Control
                  id='buscador'
                  required
                  type="text"
                  value={searchTerm}
                  placeholder="Search by API name or endpoint"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button className="customButton-transparent px-4">
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </Button>
              </div>


      </div>

          {selectedProjectId && (
            <>


              <Table className="cld_tableWrapper">
                <tbody>
                  <tr className="headerRow">
                    <th className="textBold">Doc Name</th>
                    <th className="textBold">Index</th>
                    {/*<th className="textBold">API Products</th>
                    <th className="textBold">APP Tokens</th>*/}
                    <th style={{ textAlign: 'end' }} className="textBold">Actions</th>
                  </tr>
                  {filteredApis.map((api, index) => (
                    <tr className="tableRow" key={index}>
                      <td className="td_centered">{api.document_name || '-'}</td>
                      <td className="td_centered">{api.index_name ? api.index_name : '-'}</td>
                     {/*} <td>{api.api_products ? api.api_products.map(prr=>(prr.product_name)): '-'}</td>
                      <td>{api.tokens ? api.tokens.map(prr=>(prr.token)): '-'}</td> */}
                      <td>
                        <div className="actionsWrapper">
                          <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%', marginRight: '20px' }}>
                            <Dropdown.Toggle className="organizations">
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <Dropdown.Item
                                    key={1}
                                    onClick={()=>openDrawer(api)}
                                  >
                                    Edit Document
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    key={1}
                                    onClick={() => handleDeleteProxy(api)}
                                  >
                                    Delete Document
                                  </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}


                </tbody>
              </Table>
            </>
          )}
        </div>
      )}


      <Drawer
        toggleSidebar={closeDrawer}
        isOpen={isDrawerOpen}
        name={'Add Document to Dev Portal'}
        subtitle=''
      >


        <Form onSubmit={handleSubmitUser}>
          <Form.Group controlId="project">
            <Form.Label>Available Dev Portals</Form.Label>
            <Form.Control
            disabled={isEditing}
              as="select"
              name="project"
              value={selectedProjectId}
              onChange={handleChange}
            >
              <option value="">Select a dev portal</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>


          <Form.Group controlId="project2">
            <Form.Label>Available Indexs</Form.Label>
            <Form.Control
            disabled={isEditing}
              as="select"
              name="project2"
              value={selectedIndexId}
              onChange={handleChangeIndex}
            >
              <option value="">Select an index</option>
              {indexs.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.index_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>


          <Form.Group controlId="basePath" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter document name"
                    value={localEmail}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>


          <DocumentationForm 
            isEditing={isEditing}
            setDocumentContent={handleActualDoc} 
            documentationContent={actualDoc}
            opened={isDrawerOpen}
            >
            

          </DocumentationForm>

{/*}
                <Form.Group controlId="basePath" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter email"
                    value={localEmail}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

*/}
{/*}
                <Form.Group controlId="basePath" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={localPassword}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>


        <Form.Check
          type="checkbox"
          label="Password must change"
          checked={mustChange}
          onChange={(e) => setMustChange(e.target.checked)}
        />

        */}
       <Row>
            <div style={{ height:'2em' }}></div>
       </Row>
      {/*}

          {selectedProjectId &&
            <><Row>
              <Form.Group controlId="file" style={{ paddingLeft: '15px', paddingRight: '15px', width: '100%' }}>
                <Form.Label>OpenAPI Spec File</Form.Label>
                <FileUpload
                    onFileSelect={handleFileSelect}
                    allowedTypes={['application/json']}
                    label="Select a JSON file"
                />

              </Form.Group>
             



            </Row> </>
          }
            */}
              {/*}
                <Row>
                  <Col md='6'>
                    <Button type="submit" variant="primary" className="w-100">
                      Create
                    </Button>
                  </Col>
                </Row>
             */}
           
          
        </Form>

      </Drawer>

      <Drawer
        toggleSidebar={closeDocumentationDrawer}
        isOpen={isDocumentationDrawerOpen}
        name="Edit Documentation"
        subtitle=""
      >
      <DocumentationForm 
      isEditing={isEditing}
      opened={isDocumentationDrawerOpen}
       documentationContent={actualDoc}
      setDocumentContent={function (): void {
          throw new Error('Function not implemented.');
        } }></DocumentationForm>
      </Drawer>

      <Drawer
        toggleSidebar={closeUnittestDrawer}
        isOpen={isUnittestDrawerOpen}
        name="Edit Unittest"
        subtitle=""
      >
        	<UnitTestForm apiDetails={selectedApiForUnittest} onClose={closeUnittestDrawer} />
      </Drawer>
    </DashLayout>
  );
};

export default DevPortalDocs;
