import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row, Spinner, Alert } from 'react-bootstrap';
import ObexRequestHandler from '../../../../handlers/ObexRequestsHandler';
import GenericSelectableList from '../../../../components/DashboardComponents/CustomSelectSearch';



const AddApiKeyForm = ({onSubmit, opened}) => {



    const [apiProducts, setApiProducts] = useState([]);
const [loadingApiProducts, setLoadingApiProducts] = useState(false);
const [availableProjects, setAvailableProjects] = useState([]);
    const [formData, setFormData] = useState({
        searchPolicyName: '',
        accessRights: '',
        authenticationType: '',
        apiName: '',
        apiAuthType: '',
        rateLimit: 1000,
        rateLimitInterval: 60,
        disableRateLimiting: false,
        disableThrottling: false,
        throttleRetryLimit: 0,
        throttleInterval: 0,
        unlimitedRequests: true,
        maxRequestsPerPeriod: 0,
        quotaResetInterval: 'never',
        rateLimitSmoothing: false,
        project:[],
        apiProduct:[],
    });
    const [loading, setLoading] = useState(true);

    const fetchApiData = async () => {
        try {
          setLoading(true);
    
          // Obtener API Products
          const productsResponse = await ObexRequestHandler.get('/api/products', {}, true);
          setApiProducts(productsResponse.data || []);
    
          // Obtener proyectos disponibles
          const projectsResponse = await ObexRequestHandler.get('/projects', {}, true);
          setAvailableProjects(projectsResponse.data || []);
          console.log('Projects Response:', projectsResponse.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };


      const handleChange1 = async (e) => {
        const { name, value } = e.target;
    
        if (name === 'project') {
          setFormData((prevData) => ({ ...prevData, project: value, apiProduct: [] }));
          setLoadingApiProducts(true);
    
          try {
            const response = await ObexRequestHandler.get(
              `/publishing/get_publish_products_by_session?obex_project_id=${value}`,
              {},
              true
            );
            const publishedApis = response.data || {};
            const products = publishedApis.Products || [];
    
            if (products.length === 0) {
              console.warn('No API Products found for the selected project.');
            }
    
            setApiProducts(products.map((product) => ({ id: product.id, name: product.name })));
          } catch (error) {
            console.error('Error fetching API Products:', error);
            setApiProducts([]);
          } finally {
            setLoadingApiProducts(false);
          }
        } else {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('API Key Data Submitted:', formData);
        onSubmit();
        // Aquí puedes añadir la lógica de envío al backend
    };

    const handleCancel = () => {
        setFormData({
            searchPolicyName: '',
            accessRights: '',
            authenticationType: '',
            apiName: '',
            apiAuthType: '',
            rateLimit: 1000,
            rateLimitInterval: 60,
            disableRateLimiting: false,
            disableThrottling: false,
            throttleRetryLimit: 0,
            throttleInterval: 0,
            unlimitedRequests: true,
            maxRequestsPerPeriod: 0,
            quotaResetInterval: 'never',
            rateLimitSmoothing: false,
            project:[],
            apiProduct:[]
        });
        console.log('Form reset');
    };

    useEffect(() => {
        handleCancel();
        console.log("cancelling")
      }, [opened]);


    useEffect(() => {
        console.log("LEYENDO")
        fetchApiData();
      }, []);

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            {/*<h2>Add API Key</h2>*/}

            {/* Search and Filters 
            <Form.Group controlId="searchPolicyName">
                <Form.Label>Search</Form.Label>
                <Form.Control
                    type="text"
                    name="searchPolicyName"
                    value={formData.searchPolicyName}
                    onChange={handleChange}
                    placeholder="Search by Policy Name"
                />
            </Form.Group>

            <Row>
                <Col>
                    <Form.Group controlId="accessRights">
                        <Form.Label>Access Rights</Form.Label>
                        <Form.Control
                            as="select"
                            name="accessRights"
                            value={formData.accessRights}
                            onChange={handleChange}
                        >
                            <option value="">All Access Rights</option>
                            <option value="read">Read</option>
                            <option value="write">Write</option>
                            <option value="admin">Admin</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                */}
                <Row>
                <Col>
                    <Form.Group controlId="authenticationType">
                        <Form.Label>API Key Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="authenticationType"
                            value={formData.authenticationType}
                            onChange={handleChange}
                        >
                         {/*}   <option value="dev">Developer API Key</option> */}
                            <option value="prod">Production API Key</option>
                            <option value="test">Test API Key</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row> 

            {/* Add API Access Rights 
            <h3>Add API Access Rights</h3>*/}

            {/*}
            <Form.Group controlId="apiName">
                <Form.Label>API Key Name</Form.Label>
                <Form.Control
                    type="text"
                    name="apiName"
                    value={formData.apiName}
                    onChange={handleChange}
                    placeholder="Write API Name"
                />
            </Form.Group>
            */}

{/*}
            <Form.Group controlId="apiAuthType">
                <Form.Label>Authentication Type</Form.Label>
                <Form.Control
                    type="text"
                    name="apiAuthType"
                    value={formData.apiAuthType}
                    onChange={handleChange}
                    placeholder="Search by Authentication Type"
                />
            </Form.Group>
*/}
            {/* Global Limits and Quota */}





            {availableProjects.length === 0 ? (
            <Alert variant="danger">No API Projects available. Please create one first.</Alert>
          ) : (
            <Form.Group controlId="project">
              <Form.Label>Available Projects</Form.Label>
              <Form.Control
                as="select"
                name="project"
                value={formData.project}
                onChange={handleChange1}
              >
                <option value="">Select a project</option>
                {availableProjects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.description}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          {/* Select de API Products */}
          {formData.project && (
            <Form.Group controlId="apiProduct">
                    <Form.Label>Available API Products</Form.Label>

                    {loadingApiProducts ? (
                        <Spinner animation="border" role="status" size="sm">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    ) : apiProducts.length === 0 ? (
                        <Alert variant="warning">No API Products found for this project.</Alert>
                    ) : (
                        <GenericSelectableList
                            items={apiProducts}
                            searchKeys={['name']}
                            onAdd={(role) => setApiProducts((prev) => [...prev, role.name])}
                            onRemove={(role) => setApiProducts((prev) => prev.filter((r) => r !== role.name))}
                            renderLabel={(role) => role.name}
                            placeholder="Search API PRoducts..."
                            multiOption={true} // Selección múltiple
                            setSelectedItems={setApiProducts}
                            selectedItems={apiProducts} 
                            itemSelected={''}
                            />
                            

                    

                
            )}
                </Form.Group>
                )
            }
                
                <h3>Global Limits and Quota</h3><Form.Group controlId="disableRateLimiting">
                        <Form.Check
                            type="switch"
                            label="Disable Rate Limiting"
                            name="disableRateLimiting"
                            checked={formData.disableRateLimiting}
                            onChange={handleChange} />
                    </Form.Group>

            {!formData.disableRateLimiting && (
                <>
                    <Form.Group controlId="rateLimit">
                        <Form.Label>Rate</Form.Label>
                        <Form.Control
                            type="number"
                            name="rateLimit"
                            value={formData.rateLimit}
                            onChange={handleChange}
                            placeholder="Rate"
                        />
                    </Form.Group>
                    <Form.Group controlId="rateLimitInterval">
                        <Form.Label>Per (seconds)</Form.Label>
                        <Form.Control
                            type="number"
                            name="rateLimitInterval"
                            value={formData.rateLimitInterval}
                            onChange={handleChange}
                            placeholder="Seconds"
                        />
                    </Form.Group>
                </>
            )}

            <Form.Group controlId="disableThrottling">
                <Form.Check
                    type="switch"
                    label="Disable Throttling"
                    name="disableThrottling"
                    checked={formData.disableThrottling}
                    onChange={handleChange}
                />
            </Form.Group>

            {!formData.disableThrottling && (
                <>
                    <Form.Group controlId="throttleRetryLimit">
                        <Form.Label>Throttle Retry Limit</Form.Label>
                        <Form.Control
                            type="number"
                            name="throttleRetryLimit"
                            value={formData.throttleRetryLimit}
                            onChange={handleChange}
                            placeholder="Disabled throttling"
                        />
                    </Form.Group>
                    <Form.Group controlId="throttleInterval">
                        <Form.Label>Throttle Interval</Form.Label>
                        <Form.Control
                            type="number"
                            name="throttleInterval"
                            value={formData.throttleInterval}
                            onChange={handleChange}
                            placeholder="Disabled throttling"
                        />
                    </Form.Group>
                </>
            )}

            <Form.Group controlId="unlimitedRequests">
                <Form.Check
                    type="switch"
                    label="Unlimited Requests"
                    name="unlimitedRequests"
                    checked={formData.unlimitedRequests}
                    onChange={handleChange}
                />
            </Form.Group>

            {!formData.unlimitedRequests && (
                <>
                    <Form.Group controlId="maxRequestsPerPeriod">
                        <Form.Label>Max Requests per Period</Form.Label>
                        <Form.Control
                            type="number"
                            name="maxRequestsPerPeriod"
                            value={formData.maxRequestsPerPeriod}
                            onChange={handleChange}
                            placeholder="Unlimited"
                        />
                    </Form.Group>
                    <Form.Group controlId="quotaResetInterval">
                        <Form.Label>Quota Resets Every</Form.Label>
                        <Form.Control
                            as="select"
                            name="quotaResetInterval"
                            value={formData.quotaResetInterval}
                            onChange={handleChange}
                        >
                            <option value="never">Never</option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                        </Form.Control>
                    </Form.Group>
                </>
            )}

            <Form.Group controlId="rateLimitSmoothing">
                <Form.Check
                    type="switch"
                    label="Enable Rate Limit Smoothing"
                    name="rateLimitSmoothing"
                    checked={formData.rateLimitSmoothing}
                    onChange={handleChange}
                />
                <Form.Text className="text-muted">
                    The Rate Limit smoothing algorithm can be used with the Redis Rate Limiter to provide a gradually increasing rate limit on requests to the API.
                </Form.Text>
            </Form.Group>

            <Row>
                <Col md='6'>
                    <Button variant="primary" type="submit" className='w-100'>
                        Save API Key
                    </Button>
                </Col>
                {/*
                <Col md='6'>
                    <Button variant="outline-secondary" onClick={handleCancel} className='w-100'>
                        Cancel
                    </Button>
                </Col>*/}
            </Row>
        </Form>
    );
};

export default AddApiKeyForm;
