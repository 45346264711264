import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Button, Form, Table, Spinner, Image, Dropdown  } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisV, faPencilAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import orgIcon from '../../assets/img/ui_icons/cld_developers-s.svg'
import Cookies from 'universal-cookie';
import UpgradePlanModal from '../Modals/UpgradePlanModal';
import editicon from '../../assets/img/icon_edit.png';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';
import Drawer from './Drawer';
import AddOrganizationModal from '../Modals/addOrganizationModal';
import AddDevelopeModal from '../Modals/addDevelopeModal';
import EditDevelopeModal from '../Modals/editDevelopeModal';

const cookies = new Cookies();

type IncomeProps = {
};


const DeveloperContent:FunctionComponent<IncomeProps> = ({ }) => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [developer, setDeveloper] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadDevs, setloadDevs] = useState(false);
  const [UsedMembers, setUsedMembers] = useState(0);
  const [MaxMembers, setMaxMembers] = useState(0);
  const [ActualPlan, setActualPlan ] = useState(0);
  const [showUpgrade, setshowUpgrade] = useState(false)
  const [isAddOrgDrawerOpen, setIsAddOrgDrawerOpen] = useState(false);
  const [userSelected, setUserSelected] = useState({});

  const [roles, setRoles] = useState([]);

const [currentSuscripcion, setcurrentSuscripcion] = useState(0);
const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

const [localSelectedGA, setSelectedGA] = useState([]);
const [listaRecuperados, setlistaRecuperados] = useState([]);

const [recoveredProjects, setrecoveredProjects] = useState([]);

const toggleEditDrawer = async (e) => {
  console.log(e)
  setUserSelected(e);
  await getDevRoles(e.dev_id);
  setIsEditDrawerOpen(!isEditDrawerOpen);
  history.push('/developers')
};

const toggleCloseEditDrawer = () =>{
  setIsEditDrawerOpen(!isEditDrawerOpen);
}
const toggleAddOrgDrawer = () => {
  setIsAddOrgDrawerOpen(!isAddOrgDrawerOpen);
};

const [filtro, setFilter] = useState('');

const changeFilter = (e) => {
  const newFilter = e.target.value;
  setFilter(newFilter);
  filterProjects(newFilter)
};



const filterProjects = async (filtro) => {

  const filter_projects = recoveredProjects.filter(element =>
    element.email.toLowerCase().includes(filtro.toLowerCase())
  );
  setProjects(filtro === '' ? recoveredProjects : filter_projects);

}


const getRoles = async () => {
  try {
    setLoading(true);
    //setloadDevs(true);

    setRoles([]);
    const temp_roles = [];

    const roles = (
      await ObexRequestHandler.get('/iam/get_roles', {}, true)).data || [];


    roles.forEach(element => {
      temp_roles.push({ id: element.role_id, name: element.role_name })
    });

    setRoles(temp_roles);

    //setProjects(projects);

  } catch (error) {
    console.error('ERROR FETCHING PROJECTS ', error);


  }
  setLoading(false);
}


const getDevRoles = async (dev_id) => {
  console.log(dev_id);
  try {
    setLoading(true);
    //setloadDevs(true);

    setlistaRecuperados([]);
    const temp_roles = [];

    const roles = (
      await ObexRequestHandler.get(`/iam/get_dev_role?dev_id=${dev_id}`, {}, true)).data || [];

    console.log(roles);
    roles.forEach(element => {
      temp_roles.push({ id: element.role_id, name: element.role_name })
    });

    setlistaRecuperados(temp_roles);

    //setProjects(projects);

  } catch (error) {
    console.error('ERROR FETCHING PROJECTS ', error);


  }
  setLoading(false);
}



  const getProjects = async (filtro) => {
    try {

      setloadDevs(true);

      setProjects([]);
      
      const suscripcion = await ObexRequestHandler.get('/current_subscription', {}, false);

      const { data } = suscripcion;

      const max_members_allow = (data.max_members_slots);
      const active_slots_members = (data.active_slots_members);

      setMaxMembers(max_members_allow);
      setUsedMembers(active_slots_members);

      const projects = (
        await ObexRequestHandler.get(`/organization_devs?organization=${SessionService.ORGANIZATION}`, {}, true)).data || [];

        console.log(`/organization_devs?organization=${SessionService.ORGANIZATION}`);
        console.log(projects);
        const filter_projects = projects.filter(element =>
          element.email.toLowerCase().includes(filtro.toLowerCase())
        );

        setrecoveredProjects(projects);


        setProjects(filtro === '' ? projects : filter_projects);

      //setProjects(projects);

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setloadDevs(false);

    }
    setloadDevs(false);
  }

const whenClosing = ()=>{
setshowUpgrade(false);

}

  const sendInvitation= async (e) =>{

    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      console.log(ActualPlan)
      const user = cookies.get('101Obex');
      console.log(user);
      if (ActualPlan==1 && developer!=user.email) {
        setshowUpgrade(true); 
        {/*history.push('/plan_subcription/upgrade_plan');*/}
        setLoading(false);
        return;
      }

      if (developer.length>5 && developer.includes('@') && developer.includes('.')) {
      if (UsedMembers<=(MaxMembers)) {
              const payload = { email: developer, organization: SessionService.ORGANIZATION };
              const result = await ObexRequestHandler.post('/invite_developer', payload);
              const { success, message } = result;
              setDeveloper('');
              if (!success) throw { message };
              getProjects(filtro);
            } else {
              history.push('/plan_subcription/upgrade_plan');
            }
          } else {

          }
    } catch (error) {
      console.error('ERROR WHILE CHANGING PASSWORD ', error);
      setLoading(false);
    }
    setLoading(false);

  }

  const changeDeveloper = (e) => {
    const newDeveloper = e.target.value;
    setDeveloper(newDeveloper);
  }

  const getSuscription = async () => {

    const result = await ObexRequestHandler.get('/purchased_services');
      const { success, data } = result;
      if (success){
        setActualPlan(data.subscription.subscription_id);
        
      }
  
    }


  useEffect(() => {
    window.scrollTo(0, 0);
    getProjects(filtro);
    getRoles()
    getSuscription();



  }, []);

  const handleDelete = async (developer) => {
    console.log(developer);
    const obex_dev_users = developer.dev_id;
    await ObexRequestHandler.get(`/del_developer?obex_dev_user=${obex_dev_users}`)
    getProjects(filtro);
    getRoles()
    getSuscription();
    history.push('/developers')
  }


  const routerLink = (url) => {
    const pushData = projects 
    ? { state: { projects } } 
    : {};
    history.push(url, pushData);
  }

  return(
    <>
      <BrowserView>
    <DashLayout sider={DashRouters} active={'developers'} sublinks={[]}>
     {/*} <Row style={{marginTop:'5px', marginLeft:'-17px'}}>
        <Col> */}

       {/*} 
       <Row style={{ marginBottom:'1rem' }}>
        <h2 className='textBold'>Manage this organization's developers</h2>

       </Row>
       <div className='fontText3 colorBlackPI' style={{marginBottom:'2rem', marginLeft:'-15px'}}>
       Access and manage the list of developers within your organization.
        </div>
        */}

<Row id='topHeader'>

          <Col md="10" >
              <div className='d-flex flex-column'>
                <div className="cld_wrapperTitle">
                  <img src={orgIcon} alt="icon dashboard" />
                  <span className="cld_title w-100 margin-y-24px">
                  <span style={{ color:'#949498', fontSize:'14px' }}> IAM </span>/ Users 
                  </span>
                </div>

                {/* <span className="cld_legend w-100">
                  View and manage a list of organizations you're associated with.
                </span> */}
              </div>

            </Col>


          <Col md="2" className='align-content-center'>
             
                <Button
                  style={{ width: '225px' }}
                  className="customButton-black btn px-4 float-right   resBtn"
                  onClick={() => {
                    setlistaRecuperados([]);
                    setIsAddOrgDrawerOpen(true)}} // Abre el modal para añadir una nueva organización
                >
                  Add User
                </Button>

            </Col>


            <Col>
              <div className="customBorder"></div>
            </Col>
          </Row>

          <Row id='table' className='mt-5'>
            </Row>
                <div className='cld_borderWrapper'>
                  <span className='cld_sectionTitle'>Users</span>

                  <div className="searchContainer">
                    <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by User name"
                      onChange={changeFilter} />
                    <Button className="customButton-transparent px-4"
                      onClick={() => getProjects(filtro)}><FontAwesomeIcon icon={faSearch} size="1x" />
                    </Button>
                  </div>


                  {loadDevs &&

<Row style={{ textAlign: 'center' }}>
  <Col>
    <Spinner animation="border" style={{ marginLeft: '8px' }}/>
  </Col>
</Row>
}

{!loadDevs &&


<Table className='cld_tableWrapper' >
  
  {/*}
<thead style={{ fontFamily: 'Regular Intro Bold', textAlign:'start', backgroundColor:'transparent', color:'#595959', borderBottomColor:'#ededed', borderBottomStyle:'solid', borderBottomWidth:'2px' }}>
  <div  style={{paddingTop:'10px', fontSize:'1.2vw', height:'50px', paddingLeft:'5px' }}>Users</div>
  </thead>
  */}
  <tbody >
  <tr className='tableRow' style={{ color:'#595959'}}>
      <th className='textBold'>User Email</th>
      <th></th>
      <th style={{ textAlign:'center' }} className='textBold'># Of teams</th>
      <th style={{ textAlign:'center' }} className='textBold'>Status</th>
      <th style={{ textAlign:'center' }} className='textBold'>Roles</th>
      <th style={{ textAlign:'end', width:'5%' }} className='textBold'>Actions</th>
    </tr>
  
    {projects.map(project =>  (

      project.confirmed ?

      <tr className='tableRow' key={project.id} style={{  }}>
        <td className='td_centered' style={{ textAlign:'start' }}>{project.email}</td>
        <td></td>
        <td className="devsItem td_centered" style={{ marginLeft:'auto', marginRight:'auto', marginTop:'20px', backgroundColor: 'var(--color-background-from)', textAlign:'center' }}>{project.dev_teams}</td>
        <td className='td_centered' style={{ textAlign:'center' }}>{project.confirmed ? 'Active':'Invited'}</td>
        <td  style={{ textAlign:'start' }} className="td_centered text-right">{project.role_names}</td>
        <td  style={{ textAlign:'end' }} className=" td_centered text-right">
          {/*<a className="edit-link">*/}
          
            <Dropdown className='my-auto' style={{ marginRight:'20px' }}>
              <Dropdown.Toggle className="organizations" >
                <FontAwesomeIcon icon={faEllipsisV}   />
              </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className='noItallic' key={1} onClick={ () => toggleEditDrawer(project)}>Edit</Dropdown.Item>
                  <Dropdown.Item className='noItallic' key={2} onClick={ () => handleDelete(project) }>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
          
          </td>
      </tr> :


        <tr className='tableRow' key={project.id} style={{ color:'grey', fontStyle:'oblique' }}>
        <td className='td_centered' style={{ textAlign:'start' }}>{project.email}</td>
        <td></td>
        <td className="devsItem td_centered" style={{ marginTop:'20px',marginLeft:'auto',marginRight:'auto', backgroundColor: 'var(--color-background-from)', textAlign:'center' }}>{project.dev_teams}</td>
        <td className='td_centered' style={{ textAlign:'center' }}>{project.acepted ? 'Active':'Invited'}</td>
        <td style={{ textAlign:'start' }} className=" td_centered text-right">{project.role_names}</td>
        <td style={{ textAlign:'end' }} className="td_centered text-right">
          {/*<a className="edit-link">*/}
          
            <Dropdown className='my-auto' style={{ marginRight:'20px' }}>
              <Dropdown.Toggle className="organizations" >
                <FontAwesomeIcon icon={faEllipsisV}   />
              </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className='noItallic' key={1} onClick={() => toggleEditDrawer(project)}>Edit</Dropdown.Item>
                  <Dropdown.Item className='noItallic' key={2} onClick={ () => handleDelete(project) }>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
          
          </td>
      </tr>
      
    ))}
   {/*} {projects.length <2 ? <div style={{ height:'65px' }}></div>:<></>} */}
  </tbody>
</Table>

}


      </div>

        
        <Row style={{ marginTop:'10px' }}>
        {/*<h1>Organization <b>{SessionService.ORGANIZATION_NAME}</b> Developers</h1>*/}

       </Row>
       


       <Row>
        <Col style={{ marginLeft : '-20px' }}>

 
        </Col>
      </Row>
 {/*}      </Col> */}
      

      <UpgradePlanModal 
          showModal={ showUpgrade } 
          text={'In order to add a new project you need to upgrade your plan. \n You have consumed the maximun slots available.'} 
          onCloseModal={whenClosing} 
          onCancelModal={whenClosing} 
          currentSuscription={currentSuscripcion}
          onSuccess={whenClosing}
          />

<Drawer
            isOpen={isAddOrgDrawerOpen}
            toggleSidebar={toggleAddOrgDrawer}
            name="Add User"
            // subtitle='Invite'
          >

            <AddDevelopeModal
              handleClose={toggleAddOrgDrawer} // Cierra el Drawer
              updateTable={() => getProjects('')} // Actualiza la tabla tras la creación
              selectedGA={[]}
              show
              setlistaRecuperados={setlistaRecuperados}
              listaRecuperados={listaRecuperados}  
              roles={roles}
              opened={isAddOrgDrawerOpen}
            />

</Drawer>


<Drawer
            isOpen={isEditDrawerOpen}
            toggleSidebar={toggleCloseEditDrawer}
            name="Edit User"
          >

            <EditDevelopeModal
              handleClose={toggleCloseEditDrawer} // Cierra el Drawer
              updateTable={() => getProjects('')} // Actualiza la tabla tras la creación
              selectedGA={[]}
              userData={userSelected}
              roles={roles}
              show 
              setlistaRecuperados={setlistaRecuperados}
              listaRecuperados={listaRecuperados}            
              opened={isEditDrawerOpen}
              />

</Drawer>
          
    </DashLayout>
          </BrowserView>
          <MobileView>
            <Disclaimer />
          </MobileView>
        </>
  )
}

export default DeveloperContent;


