import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/Features-1.png'
import features_2 from '../../assets/img/landingPage/Features-2.png'
import features_3 from '../../assets/img/landingPage/Features-3.png'



import logo from '../../assets/img/logos/AVAP_VS_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'



const VersionControlProduct = () => {
    useEffect(() => {
	
		document.body.classList.add('dark-bg');
		return () => {
			document.body.classList.remove('dark-bg');
		};

	}, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center py-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Redefining API Lifecycle Management</h1>
                    <p className="fl-subtitle">
                    Lightweight scalability, universal compatibility, <br /> and intelligent traffic optimization
                    </p>
                    {/* <a className="glowBtn-dark" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a> */}
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-title mb-3">
                            Streamlined API Lifecycle and <br /> Intelligent Optimization
                               
                                
                            </p>
                            <p className="fl-text">
                            AVAP Virtual Server (AVS) streamlines the entire API lifecycle by integrating with the AVAP cloud repository for efficient version management, testing, and deployment. Built with Node.js and KOA.js, AVS offers a lightweight, scalable platform for optimal performance. It supports API exposure management, intelligent traffic redirection, and compatibility with all programming languages and API servers, ensuring flexibility and control throughout the API development process.
                            </p>
                        </div>
                    </div>

                </section>

  

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">API Lifecycle Management</h3>
                            <p className="fl-text">
                            AVS seamlessly manages multiple versions of API code, executes unit tests for each version, and ensures smooth deployment across various environments. This end-to-end API management minimizes the risk of errors, increases reliability, and enhances efficiency in both testing and production phases.

                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                        <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Universal Product</h3>
                            <p className="fl-text">
                            It is designed to work with AVAP language servers and supports a wide range of API servers, including Java, Python, and others. This universal compatibility ensures that your existing APIs integrate effortlessly with AVS, allowing developers to continue working with the tools they are familiar with while benefiting from AVAP’s advanced capabilities, and the user experience by handling varying loads and adapting to traffic demands.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            {/* <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> */}
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Intelligent Traffic Management</h3>
                            <p className="fl-text">
                            Leveraging an intelligent gateway management system to optimize API traffic routing. It dynamically redirects traffic based on performance history, event triggers, and administrator-defined parameters, ensuring the most efficient routing and optimal response times. Improving server performance, reliability, and the user experience by handling varying loads and adapting to traffic demands.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                Optimization of Infrastructure
                                </p>
                                <p className="fl-text">
                                Intelligent traffic routing reduces latency and ensures that requests are directed to the most efficient servers, improving overall performance.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                Enhanced Performance and Scalability
                                </p>
                                <p className="fl-text">
                                Built to optimize server performance while handling fluctuating traffic demands. Its lightweight, scalable architecture ensures seamless scalability, enabling it to efficiently manage sudden spikes in traffic without compromising performance.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                Sustainable and Effortless Integration
                                </p>
                                <p className="fl-text">
                                AVS combines low energy consumption with an easy installation process, making it a cost-effective and sustainable solution for managing APIs.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
