import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Spinner, Dropdown, Form } from 'react-bootstrap';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import Drawer from "./Drawer"; // Asegúrate de importar el componente Drawer correctamente
import EnvironmentForm from "./ambientForm"; // El formulario
import DashRouters from "../../configs/routers/dashboard-routers.json";
import ProxIcon from '../../assets/img/ui_icons/cld_proxy.svg'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";
import { useHistory } from "react-router-dom";

type Environment = {
  opened: any;
  id: string;
  ambient: {id: number, name: string};
  port: string;
  visibility: boolean;
  whiteListEnabled: boolean;
  whiteList: string[];
  apiProducts: any[];
  alias: string;
  ambiente: string;
  ambiente_id: any;
};

const EnvironmentManager = () => {
  const [filteredEnvironments, setFilteredEnvironments] = useState<Environment[]>([]);
  const [apiProducts, setAPIProducts] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [sel_products, set_sel_products] = useState([]);




  const getGatewayAPIProducts = async () => {

    
    const apiproduct_configs = await ObexRequestHandler.get(`/gateway/get_gateway_products?organization=${SessionService.ORGANIZATION}`)

    const apiproduct = apiproduct_configs.data.map((prox) => ({ 
      id: prox.id, 
      name: prox.avap_api_product_name,
      type: prox.api_type_name
    }));

    console.log(apiproduct);

    setAPIProducts(apiproduct);

  };



  const [environments, setEnvironments] = useState<Environment[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editingEnvironment, setEditingEnvironment] = useState<Environment | null>(null);
  const history = useHistory();
  const handleSaveEnvironment = (newEnvironment: Environment) => {
    if (editingEnvironment) {
      // Update existing environment
      setEnvironments(
        environments.map((env) =>
          env.id === editingEnvironment.id ? { ...newEnvironment, id: editingEnvironment.id } : env
        )
      );
      
    } else {
      // Add new environment
      setEnvironments([
        ...environments,
        { ...newEnvironment, id: Math.random().toString(36).substr(2, 9) },
      ]);
    }

    getEnvironments();
    getGatewayAPIProducts();

    setDrawerOpen(false);

  };

  const handleDeleteEnvironment = async (id: string) => {
    if (window.confirm("Are you sure you want to delete this environment?")) {
      
      await ObexRequestHandler.post(`/gateway/delete_ambients?ambient_id=${id}`);

      getEnvironments();
      getGatewayAPIProducts();
    }
  };

  const handleOpenDrawer = async (environment?: Environment) => {
    if (environment) {
      let gg = await ObexRequestHandler.get(`/gateway/get_ambient_data?ambient_id=${environment.id}`);
      console.log('.,.,.,.,.,.,.,.,.,,----0---.-.-.-.-.-.-.-.-')
      console.log(gg);
      console.log(gg.data);
      console.log(gg.data[0]);
      console.log('.,.,.,.,.,.,.,.,.,,----1---.-.-.-.-.-.-.-.-')
      console.log(gg.data[0].api_products);
      set_sel_products(gg.data[0].api_products)
      console.log(environment)
      console.log('.,.,.,.,.,.,.,.,.,,----2---.-.-.-.-.-.-.-.-')
      setEditingEnvironment(environment);
    } else {
      setEditingEnvironment(null);
    }
    setDrawerOpen(true);
    history.push('/ambients')
    
  };


  const getEnvironments = async () => {


    const envio = await ObexRequestHandler.get(`/gateway/get_gateway_ambients?organization=${SessionService.ORGANIZATION}`);
    const dataAmbients = envio.data


    const finalEnviroment = dataAmbients.map((envi)=>({
      alias: envi.alias,
      id: envi.id,
      ambient: envi.alias,
      port: envi.port,
      visibility: envi.visibility,
      whiteListEnabled: envi.white_list_enabled,
      whiteList: envi.whitelisted_ips || [],
      ambiente: envi.ambient_name,
      ambiente_id: envi.ambient_id
    }))


    setEnvironments(finalEnviroment);

    const filtered = finalEnviroment.filter(env =>
      env.ambient.toLowerCase().includes(filtro.toLowerCase())
    );
    setFilteredEnvironments(filtered);
  };
  const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(e.target.value);
  };


  useEffect(() => {
    getEnvironments();
    getGatewayAPIProducts();
  }, []);


  return (
    <DashLayout sider={DashRouters} active="environments" sublinks={[]}>
      <Row id='topHeader'>
        <Col md="10">
          <div className="cld_wrapperTitle">
            <img src={ProxIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
            <span style={{ color:'#949498', fontSize:'14px' }}>Gateway Manager </span>/ Ambients 
              
            </span>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right  resBtn"
            onClick={() => handleOpenDrawer()}>
            Add Ambient
          </Button>
        </Col>
        <Col> <div className="customBorder"></div></Col>
      </Row>

      <Row id="table" className="mt-5">
        <Col>
          <div className="cld_borderWrapper">
            <span className='cld_sectionTitle'>Ambients</span>

            <div className="searchContainer">
              <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by Organization name"
                onChange={changeFilter} />
              <Button
                className="customButton-transparent px-4"
                onClick={getEnvironments}>
                <FontAwesomeIcon icon={faSearch} size="1x" />
              </Button>

            </div>

            <Table className="cld_tableWrapper">
              <thead>
                <tr className="tableRow">
                  <th style={{ width: '20%' }} className='textBold'>Ambient</th>
                  <th style={{ width: '15%' }} className='textBold'>Port</th>
                  <th style={{ width: '15%' }} className='textBold'>Visibility</th>
                  <th style={{ width: '20%' }} className='textBold'>White List Enabled</th>
                  <th style={{ width: '20%' }} className='textBold'>White List Items</th>
                  <th style={{ width: '5%', textAlign: 'end' }} className='textBold'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {(filteredEnvironments.length > 0 ? filteredEnvironments : environments).map((env) => (
                  <tr className="tableRow" key={env.id}>
                    <td className="td_centered">{env.ambient}</td>
                    <td className="td_centered">{env.port}</td>
                    <td className="td_centered">{env.visibility ? 'Visible' : 'Private'}</td>
                    <td className="td_centered">{env.whiteListEnabled ? 'Yes' : 'No'}</td>
                    <td className="td_centered">{env.whiteList.length > 0 ? env.whiteList.join(", ") : "N/A"}</td>
                    <td>
                      <div className="actionsWrapper">
                        <Dropdown className="my-auto" style={{ marginRight: "20px" }}>
                          <Dropdown.Toggle className="organizations">
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item key={1} onClick={() => handleOpenDrawer(env)}>Edit</Dropdown.Item>
                            <Dropdown.Item key={1} onClick={() => handleDeleteEnvironment(env.id)}>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}

              </tbody>

            </Table>
          </div>
        </Col>
      </Row>

      <Drawer
        isOpen={drawerOpen}
        toggleSidebar={() => {
          setDrawerOpen(false); 
          history.push('/ambients');
          getEnvironments();
          getGatewayAPIProducts();
        }}
        name={editingEnvironment ? 'Edit Ambient' : 'Add Ambient'}
      >
        <EnvironmentForm
          alias={editingEnvironment?.ambient}
          products={sel_products}
          opened={drawerOpen}
          id={editingEnvironment?.id}
          ambient={editingEnvironment?.ambient || { id:0, name:'' }}
          port_id={0}
          port={editingEnvironment?.port || ''}
          visibility={editingEnvironment?.visibility || false}
          whiteListEnabled={editingEnvironment?.whiteListEnabled || false}
          whiteList={editingEnvironment?.whiteList || []}
          onSubmit={handleSaveEnvironment}
          apiProducts={apiProducts}
          editing={editingEnvironment}
          ambiente={editingEnvironment?.ambiente || ''}
          ambiente_id={editingEnvironment?.ambiente_id || 0}
        />

      </Drawer>
    </DashLayout>
  );
};

export default EnvironmentManager;
