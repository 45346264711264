import React, { useState, useEffect } from "react";
import { Button, Table, Row, Col, Form, Spinner, Dropdown, Alert } from "react-bootstrap";
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";
import Drawer from "./Drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch } from "@fortawesome/free-solid-svg-icons";
import devIcon from '../../assets/img/ui_icons/cld_org-s.svg';
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";

import UnitTestForm from "./Publisher_unitTest";
import DocumentationForm from "./Publisher_documentation";
import FileUpload from "./components/uploadFile";

const ApiPublisher = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [SelectedPProductId, setSelectedPProductId] = useState('');
  const [apis, setApis] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [AddProduct, setAddProduct] = useState(false);

  const [isProductDrawerOpen, setIsProductDrawerOpen] = useState(false);

  const [apiProducts, setApiProducts] = useState([]);
  const [loadingApiProducts, setLoadingApiProducts] = useState(false);
  const [isDocumentationDrawerOpen, setIsDocumentationDrawerOpen] = useState(false);
  const [isUnittestDrawerOpen, setIsUnittestDrawerOpen] = useState(false);
  const [documenta, setDocumenta] = useState('')

  const [jsonContent, setJsonContent] = useState(null);
  const [selectedApiForUnittest, setSelectedApiForUnittest] = useState(null);

    const [cardholder, setCardholder] = useState('');


  ///custon upload file
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (file) => {
    console.log(file)
    setSelectedFile(file); 
    handleFileChange(file);
};



//---.

const changeCardholder = (e) => {
  const newCardholder = e.target.value;
  setCardholder(newCardholder);
};

//---.


  const openDocumentationDrawer = async (api) => {

    console.log(api);
    
    const ee = `/unittest/documentation?obex_project_id=${api.project_id}&method=${api.method}&endpoint=${api.endpoint}&element_id=${api.gateway_id}&link=${api.gateway_id!=null}&api_product_id=${api.api_product_id}`
    
    console.log(ee);
    
    const hh = await ObexRequestHandler.get(ee);

    console.log(hh);
    if (hh.data.length>0) setDocumenta(hh.data[0].value)
    setIsDocumentationDrawerOpen(true);
  };

  const closeDocumentationDrawer = () => {
    setIsDocumentationDrawerOpen(false);
  };

  const closeProductDrawer = () => {
    setIsProductDrawerOpen(false);
  };

  const openUnittestDrawer = () => {
    setIsUnittestDrawerOpen(true);
  };

  const closeUnittestDrawer = () => {
    setIsUnittestDrawerOpen(false);
  };

  const fetchProjects = async () => {
    setLoading(true);
    setProjects([]);
    try {
      const response = await ObexRequestHandler.get(`/projects?organization_id=${SessionService.ORGANIZATION}`, {}, true);
      const projectsInfo = response.data || [];
      setProjects(projectsInfo);
      console.log(projectsInfo);
      setSelectedProjectId(projectsInfo[0].id)
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
    setLoading(false);
  };


  const fetchAPIProducts = async (projects_id) => {
    try {
      const response = await ObexRequestHandler.get(
        `/publishing/get_publish_products_by_session?obex_project_id=${projects_id}`,
        {},
        true
      );
      const publishedApis = response.data || {};
      const products = publishedApis.Products || [];

      if (products.length === 0) {
        console.warn('No API Products found for the selected project.');
      }

      setApiProducts(products.map((product) => ({ id: product.id, name: product.name })));
    } catch (error) {
      console.error('Error fetching API Products:', error);
      setApiProducts([]);
    } finally {
      setLoadingApiProducts(false);
    }
  }


  const handleFileChange = (file/*e: React.ChangeEvent<HTMLInputElement>*/) => {
    //const file = e.target.files ? e.target.files[0] : null;

    if (file && file.type === 'application/json') {
      const reader = new FileReader();

      reader.onload = () => {
        // Comprobamos si el resultado es una cadena
        const fileContent = reader.result;
        if (typeof fileContent === 'string') {
          try {
            const json = JSON.parse(fileContent);
            setJsonContent(json); // Asigna el contenido JSON a la variable
          } catch (error) {
            alert('El archivo no es un JSON válido');
          }
        } else {
          alert('El archivo no es un formato adecuado');
        }
      };

      reader.readAsText(file); // Lee el archivo como texto
    } else {
      alert('Por favor, seleccione un archivo JSON');
    }
  };


  const handleProductSubmit = async (e) => {
    e.preventDefault();



    const payload = {
      obex_project_id: selectedProjectId, 
      api_product: cardholder
    }

    await ObexRequestHandler.post(`/publishing/create_api_product?obex_project_id=${selectedProjectId}&api_product=${cardholder}`);

    fetchApisByProject();

    closeProductDrawer();

  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Formulario enviado con el proyecto seleccionado:', selectedProjectId);
    console.log('Formulario enviado con el api product seleccionado:', SelectedPProductId);
    console.log('Contenido del archivo JSON:', jsonContent);

    //let url_service = `${cloud}/gateway/routing/add_gateway_link?obex_project_id=${SelectedProject}`

    //custom file
    const formData = new FormData();
    formData.append('file', selectedFile); // logica para subir el archivo


    let prod_sel = SelectedPProductId;

    if (AddProduct) {

      const id_prod = await ObexRequestHandler.post(`/publishing/create_api_product?obex_project_id=${selectedProjectId}&api_product=${cardholder}`);

      
      console.log(id_prod);
      prod_sel = id_prod.data.id[0].id
    }

    const payload = {
      api_product_id: prod_sel,
      my_api: jsonContent
    }

    
    await ObexRequestHandler.post(`/gateway/routing/add_gateway_link?obex_project_id=${selectedProjectId}`, payload)

    fetchApisByProject();

    closeDrawer();

  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    setSelectedProjectId(value);
    await fetchAPIProducts(value);
    console.log(name, value);
  }

  const handleChangeProduct = async (e) => {
    console.log(",,,,,")
    const { name, value } = e.target;
    console.log(e.target);
    if (value === 'Add API Product') {
      setAddProduct(true);
    } else  {
      setSelectedPProductId(value);
      setAddProduct(false);
    }
    console.log(name, value);
    console.log(",,,,,")
  }

  const handlePublish = async (api) =>{
 
    const payload = {
      gateway_id: api.gateway_id, 
      new_status: !api.is_published, 
      api_id: api.api_id, 
      api_product_id: api.api_product_id
    }

    await ObexRequestHandler.post('/update_portal_apis_status', payload);

    fetchApisByProject();

    //update_portal_apis_status
  }

  const fetchApisByProject = async () => {
    if (!selectedProjectId) return;

    setLoading(true);
    try {
      const response = await ObexRequestHandler.get(`/publishing/get_apis_by_proj?obex_project_id=${selectedProjectId}`, {}, true);
      console.log('Response from /publishing/get_apis_by_proj:', response);
      const data = response.data || [];

      const dataFiltered = []

      data.forEach(element => {
        if (element.api_id){
          if (element.has_documentation_entry	!== 'No') dataFiltered.push(element); else dataFiltered.push(element);
        } else dataFiltered.push(element);
      });
      setApis(dataFiltered);
    } catch (error) {
      console.error('Error fetching APIs by project:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchProjects();
  }, []);



  const openProductDrawer = (role) => {
    setCardholder('')
    setIsProductDrawerOpen(true);
    //fetchAPIProducts(selectedProjectId);
  };

  const openDrawer = (role) => {
    setSelectedPProductId('');
    setIsDrawerOpen(true);
    
    fetchAPIProducts(selectedProjectId);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    fetchApisByProject();

  };

  const handleEditUnittest = (api) => {
    setSelectedApiForUnittest(api);
    openUnittestDrawer();
  };


  useEffect(() => {
    if (selectedProjectId) {
      fetchApisByProject();
    } else {
      setApis([]);
    }
  }, [selectedProjectId]);

  const filteredApis = apis.filter(api =>
    api.api_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    api.endpoint?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <DashLayout sider={DashRouters} active={'api-publisher'} sublinks={[]}>
      <Row id='topHeader'>
        <Col md='8' >
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={devIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px"><span style={{ color: '#949498', fontSize: '14px' }}>API Publisher </span>/ API Publisher </span>
            </div>

          </div>
        </Col>




        <Col md='4' className='align-content-center'>


        
        {/*}
        <Button style={{ width: '225px' }} className="customButton-black btn px-4 float-right  resBtn" onClick={() => openProductDrawer(null)}>
            API Product
          </Button>
*/}
          <Button style={{ width: '225px' }} className="customButton-black btn px-4 float-right  resBtn" onClick={
            
            () => {
              setAddProduct(false);
              setCardholder('');
              setJsonContent(null);
              setSelectedFile(null)
              openDrawer(null)
            }


          }>
            Add OpenAPI Spec
          </Button>

   

          {/* Botón opcional  */}
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <div className="mt-5"></div>

      {loading ? (
        <Spinner animation="border" />
      ) : (
        <div className="cld_borderWrapper">
          <span className='cld_sectionTitle'>Select a Project</span>

          <div style={{display:'flex'}}>
          <Form.Group className="my-4" style={{ width:'59%' }}>
            <Form.Control as="select" onChange={(e) => setSelectedProjectId(e.target.value)} value={selectedProjectId || ''}>
              <option value="">-- Select a project --</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.description}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <div className="searchContainer mb-4" style={{ position:'absolute', marginLeft:'55%', width:'50%', marginTop:'24px' }}>
                <Form.Control
                  id='buscador'
                  required
                  type="text"
                  value={searchTerm}
                  placeholder="Search by API name or endpoint"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Button className="customButton-transparent px-4" style={{ marginTop:'-0px' }}>
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </Button>
              </div>


          </div>
          {selectedProjectId && (
            <>

        
              <Table className="cld_tableWrapper">
                <tbody>
                  <tr className="headerRow">
                    <th className="textBold">API Name</th>
                    <th className="textBold">Endpoint</th>
                    <th className="textBold">Method</th>
                    <th className="textBold">API Product</th>
                    <th className="textBold">Documented</th>
                    <th className="textBold">Unittest</th>
                    <th className="textBold">Published</th>
                    <th style={{ textAlign: 'end' }} className="textBold">Actions</th>
                  </tr>
                  {filteredApis.map((api, index) => (
                    <tr className="tableRow" key={index}>
                      <td className="td_centered">{api.api_name || "-"}</td>
                      <td className="td_centered">{api.endpoint || api.gateway_endpoint || "-"}</td>
                      <td className="td_centered">{api.method || "-"}</td>
                      <td className="td_centered">{api.api_product_name || "-"}</td>
                      <td className="td_centered" style={{ textAlign: 'center' }}>{api.has_documentation_entry || '-'}</td>
                      <td className="td_centered" style={{ textAlign: 'center' }}>{api.has_unittest_entry || 'Yes'}</td>
                      <td className="td_centered" style={{ textAlign: 'center' }}>{api.is_published ? 'Yes': 'No'}</td>
                      <td>
                        <div className="actionsWrapper">
                          <Dropdown className='my-auto' style={{ textAlign: 'end', width: '100%', marginRight: '20px' }}>
                            <Dropdown.Toggle className="organizations">
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {(api.api_name || api.endpoint) && (
                                <>

                                  {api.has_documentation_entry === 'Yes' ?
                                    <Dropdown.Item
                                      key={1}
                                      onClick={()=>handlePublish(api)}
                                    >
                                      {api.is_published ? 'Unpublish': 'Publish to Catalog'}
                                    </Dropdown.Item>
                                    : <></>
                                  }

                                  <Dropdown.Item
                                    key={1}
                                    onClick={()=>openDocumentationDrawer(api)}
                                  >
                                    Edit Documentation
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    key={1}
                                    onClick={() => handleEditUnittest(api)}
                                  >
                                    Edit Unittest
                                  </Dropdown.Item>

                                </>



                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
{/*}
                  {filteredApis.length < 3 &&
                    <div style={{ height:'95px' }}></div>
                  }
*/}
                </tbody>
              </Table>
            </>
          )}
        </div>
      )}



<Drawer
        toggleSidebar={closeProductDrawer}
        isOpen={isProductDrawerOpen}
        name={'API Product'}
        subtitle=''
      >


        <Form onSubmit={handleProductSubmit}>
          <Form.Group controlId="project">
            <Form.Label>Available Projects</Form.Label>
            <Form.Control
              as="select"
              name="project"
              value={selectedProjectId}
              onChange={handleChange}
            >
              <option value="">Select a project</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.description}
                </option>
              ))}
            </Form.Control>
         
              <Form.Row style={{ height:'1em' }}></Form.Row>
           
              <Form.Label>API Product Name</Form.Label>
              <Form.Control
                isValid={cardholder.length > 0 && !cardholder.includes(' ')}
                isInvalid={cardholder.length === 0 || cardholder.includes(' ')}
                maxLength={18}
                required
                type="text"
                placeholder="Name"
                value={cardholder}
                onChange={changeCardholder}
              />
           

            </Form.Group>
   
                <Row>
                  <Col md='6'>
                    <Button disabled={cardholder.length === 0 || cardholder.includes(' ')} type="submit" variant="primary" className="w-100">
                      Submit
                    </Button>
                  </Col>
                </Row>
              
            
          
        </Form>

      </Drawer>




      <Drawer
        toggleSidebar={closeDrawer}
        isOpen={isDrawerOpen}
        name={'Publish Spec'}
        subtitle=''
      >


        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="project">
            <Form.Label>Available Projects</Form.Label>
            <Form.Control
              as="select"
              name="project"
              value={selectedProjectId}
              onChange={handleChange}
            >
              <option value="">Select a project</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.description}
                </option>
              ))}
            </Form.Control>
          </Form.Group>


          {selectedProjectId && (
            <Form.Group controlId="apiProduct">
              <Form.Label>Available API Products</Form.Label>
              {loadingApiProducts ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : apiProducts.length === 0 ? (
                <Alert variant="warning">No API Products found for this project.</Alert>
              ) : (
                <Form.Control
                  as="select"
                  name="apiProduct"
                  value={SelectedPProductId}
                  onChange={handleChangeProduct}
                >
                  <option value="">Select an API Product</option>
                  {apiProducts.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>

                  ))}
                  <option>
                    Add API Product
                  </option>
                </Form.Control>
              )}
            </Form.Group>
          )}

          {(selectedProjectId && SelectedPProductId || true) &&
            <><Row>
              <Form.Group controlId="file" style={{ paddingLeft: '15px', paddingRight: '15px', width: '100%' }}>
                <Form.Label>OpenAPI Spec File</Form.Label>
                <FileUpload
                    onFileSelect={handleFileSelect}
                    allowedTypes={['application/json']}
                    label="Select a JSON file"
                />



                
{ AddProduct &&
                <><Form.Row style={{ height: '1em' }}></Form.Row><Form.Label>API Product Name</Form.Label><Form.Control
                  isValid={cardholder.length > 0 && !cardholder.includes(' ')}
                  isInvalid={cardholder.length === 0 || cardholder.includes(' ')}
                  maxLength={18}
                  required
                  type="text"
                  placeholder="Name"
                  value={cardholder}
                  onChange={changeCardholder} /></>
        
}


              </Form.Group>
             



            </Row>
              {(jsonContent || true) &&
                <Row>
                  <Col md='6'>
                    <Button 
                      disabled = {!jsonContent || (cardholder == '' && AddProduct) || (!AddProduct && !SelectedPProductId)}
                    type="submit" variant="primary" className="w-100">
                      Submit
                    </Button>
                  </Col>
                </Row>
              }
            </>
          }
        </Form>

      </Drawer>

      <Drawer
        toggleSidebar={closeDocumentationDrawer}
        isOpen={isDocumentationDrawerOpen}
        name="Edit Documentation"
        subtitle=""
      >
      <DocumentationForm documenta={documenta}></DocumentationForm>
      </Drawer>

      <Drawer
        toggleSidebar={closeUnittestDrawer}
        isOpen={isUnittestDrawerOpen}
        name="Edit Unittest"
        subtitle=""
      >
        	<UnitTestForm apiDetails={selectedApiForUnittest} onClose={closeUnittestDrawer} />
      </Drawer>
    </DashLayout>
  );
};

export default ApiPublisher;
