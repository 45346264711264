import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
//import "./PaymentsAndbilling.css";
import card from "../../../assets/img/newDashboard/icono-tarjeta.png";
import dropdown from "../../../assets/img/newDashboard/icono-flecha-expandir.png";
import "./../NewDashboard.css";
import "./licensesManager.css";
import { Alert, Col, Modal, Row, Spinner, Image, Table } from 'react-bootstrap';
import SessionService from '../../../services/SessionsService';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import {countries} from 'country-data';
import addCreditCard from '../../../components/Modals/addCreditCard'
import AddCreditCard from '../../../components/Modals/addCreditCard';
import Logo from '../../../assets/img/ilustracion_noInfo2-green.png';
import { parseToCoin } from '../../../services/AmountService';

const licensesManager = () => {
  let { url } = useRouteMatch();
  const history = useHistory();

  const [showForms, setShowForms] = useState({});

  const toggleForm = (form) => {
    setShowForms(prevState => ({
      ...prevState,
      [form]: !prevState[form]
    }));
  };

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setAddedNewCard(true);
    getCards();
    setShowModal(!showModal);
  };



  const [countries_, setCountries] = useState([]);

  const [name, setName] = useState('');
  const [last_name, setLName] = useState('');
  const [licenses, setLicenses] = useState([])
  const [cards, setCards] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [cardSelectedToDelete, setCardSelectedToDelete] = useState<any>({});
  const [error, setError] = useState('');

  const [addedNewCard, setAddedNewCard] = useState(false);

  const [payments, setPayments] = useState([]);
  const [stillLoaded, setStillLoaded] = useState(true);


  const countriesList = [
    <option key="default" value="">Country</option>,
    ...countries_.map(c => <option key={c.country_code} value={c.country_code}>{c.country}</option>)
  ];
  

  const [showModalImg, setShowModalImg] = useState(false);

  const get_user_licenses = async () => {
    try {
      const result = (await ObexRequestHandler.get('/get_user_licenses')).data;
      
      result.push({id:200, name:'AVAP (Interpreter & Language Server)'})
      result.push({id:300, name:'AVAP Virtual Server (Standalone & P2P)'})
      setLicenses(result);
      return result;
    } catch (error) {
      console.error('get_user_licenses', error);
    }
  };

  const handleClose = () => setShowModalImg(false);

  const getCountries = async () => {
    try {
      const result = (await ObexRequestHandler.get('/countries')).data;
      setCountries(result);
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }
  }
  const removeCard = async (card) => {
    try {
      console.log(`Try removing ${card}`)
      // await ObexRequestHandler.delete(`/cards/${card}`);
      // await getCards();
    } catch (error) {
      console.error('ERROR DELETING CARD', error)
      throw error;
    }
  }

  const AnotherremoveCard = async () => {
    try {
      console.log(`cardSelectedToDelete ${cardSelectedToDelete}`)

     const result = await ObexRequestHandler.delete(`/cards/${cardSelectedToDelete.id}`);
     console.log(result)
     if(result.success==false){
      setError(result.message.replace('Cannot delete the card.: ', ''));
      setTimeout(function () {
        setError('');
        handleClose();
      }.bind(this), 2500);
     }else{
      
      handleClose();
    }
      await getCards();

    } catch (error) {
      console.error('ERROR DELETING CARD', error)
      throw error;
    }
  }

  const ShowQuestion = async(card)=>{
    setShowModalImg(true);
    setCardSelectedToDelete(card);
  }


  const getPayments = async () => {
    try {
      const result = await ObexRequestHandler.get('/payments');
      const { success, data } = result;
      if (success) {
        setStillLoaded(false);
        setPayments(data);
        setLoadingContent(false)
      } else {
        setStillLoaded(false);
        setLoadingContent(false);
        console.log('Cannot get the account settings information');
      }
    } catch (error) {
      setStillLoaded(false);
      setLoadingContent(false);
      console.error('ERROR FETCHING ACCOUNT SETTINGS ', error);
    }
  }


  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      // debugger
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          if (isDefault){
            console.log('DEFAULT CARD');
            console.log(c);
            SessionService.StripeDefaultCard.alias = c.alias;
            SessionService.StripeDefaultCard.brand = c.brand;
            SessionService.StripeDefaultCard.last4 = c.last4;
            SessionService.StripeDefaultCard.paymentId = c.id;
          }
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          c.remove = () => removeCard(c.id);
          console.log(c);

          //
          //
          
          if (c.billing_details && c.billing_details.address && countries[c.billing_details.address.country]) {
            const countryName = countries[c.billing_details.address.country].name;
            console.log(`/get_states_from_country?country=${countryName}`);
    
            // Lógica para obtener estados si es necesario
            try {
                const statesResponse = await ObexRequestHandler.get(`/get_states_from_country?country=${countryName}`);
                const states = statesResponse.data?.data?.states || [];
                const statesList = states.map(state => <option value={state.state_code}>{state.name}</option>);
                
                c['statesList'] = statesList;
            } catch (error) {
                console.error('Error al obtener estados:', error);
            }
        } else {
            console.warn('La propiedad billing_details o address está ausente o es nula.');
        }
    
        var form_str = "form" + c.id;
        setShowForms(prevState => ({
            ...prevState,
            [form_str]: false
        }));

        // To open last card
        const fistItem = cards[0];
        var form_str = "form" + fistItem.id;

        if(c.id== fistItem.id && addedNewCard==true){

          setShowForms(prevState => ({
              ...prevState,
              [form_str]: true
          }));
        }
    
        // Aquí estableces el nombre y el apellido solo si existen en billing_details
        setName(c.billing_details?.name ? (c.billing_details.name.split(/\s+/))[0] : "");
        setLName(c.billing_details?.name ? ((c.billing_details.name.split(/\s+/)).length > 0 ? (c.billing_details.name.split(/\s+/))[1] : "") : "");
    }
      }
      setCards(pmCards);
      setLoadingContent(false);
      setSubmitDisabled(false);
    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }

  const updateCountry = async (id_,country_) => {
    try {
      const payload={
        address_country:country_
    };
    var srt_ = "/cards/"+id_+"/data";
      await ObexRequestHandler.put(srt_, payload)

    } catch (error) {
      alert('ERROR CHANGING REGION');
      console.error('ERROR CHANGING REGION', error);
    }

  }

  const updateState = async (id_,state_) => {
    try {
      const payload={
        address_state:state_
    };
    var srt_ = "/cards/"+id_+"/data";
      await ObexRequestHandler.put(srt_, payload)

    } catch (error) {
      alert('ERROR CHANGING STATE');
      console.error('ERROR CHANGING STATE', error);
    }

  }
  const updateCity = async (id_,city_) => {
    try {
      const payload={
        address_city:city_
    };
    var srt_ = "/cards/"+id_+"/data";
      await ObexRequestHandler.put(srt_, payload);

    } catch (error) {
      alert('ERROR CHANGING CITY');
      console.error('ERROR CHANGING CITY', error);
    }

  }
  const updateLine1 = async (id_,address_line1_) => {
    try {
      const payload={
        address_line1:address_line1_
    };
    var srt_ = "/cards/"+id_+"/data";
      await ObexRequestHandler.put(srt_, payload)

    } catch (error) {
      alert('ERROR CHANGING address_line1');
      console.error('ERROR CHANGING address_line1', error);
    }

  }
  const updateLine2 = async (id_,address_line2_) => {
    try {
      const payload={
        address_line2:address_line2_
    };
    var srt_ = "/cards/"+id_+"/data";
      await ObexRequestHandler.put(srt_, payload)

    } catch (error) {
      alert('ERROR CHANGING address_line2');
      console.error('ERROR CHANGING address_line2', error);
    }

  }
  const updatePostalCode = async (id_,address_postal_code_) => {
    try {
      const payload={
        address_postal_code:address_postal_code_
    };
    var srt_ = "/cards/"+id_+"/data";
      await ObexRequestHandler.put(srt_, payload)

    } catch (error) {
      alert('ERROR CHANGING address_postal_code');
      console.error('ERROR CHANGING address_postal_code', error);
    }

  }
  const updateEmail = async (id_,email_) => {
    try {
      const payload={
        email:email_
    };
    var srt_ = "/cards/"+id_+"/data";
      await ObexRequestHandler.put(srt_, payload)

    } catch (error) {
      alert('ERROR CHANGING email');
      console.error('ERROR CHANGING email', error);
    }

  }
  const updatePhone = async (id_,phone_) => {
    try {
      const payload={
        phone:phone_
    };
    var srt_ = "/cards/"+id_+"/data";
      await ObexRequestHandler.put(srt_, payload)

    } catch (error) {
      alert('ERROR CHANGING phone');
      console.error('ERROR CHANGING phone', error);
    }

  }


  const updateName = async (id_,name_) => {
    try {
      const payload={
        name:name_
    };
    var srt_ = "/cards/"+id_+"/data";
      await ObexRequestHandler.put(srt_, payload)

    } catch (error) {
      alert('ERROR CHANGING name');
      console.error('ERROR CHANGING name', error);
    }

  }
  const handleFirstName = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setName(value);
    var str_name= value +' '+ last_name;
    updateName(id,str_name);
  }
  const handleLastName = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setLName(value);
    var str_name= name +' '+ value;
    updateName(id,str_name);
  }

  
  const handleCountry = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    updateCountry(id, value);
  }
  const handleState = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    updateState(id, value);
  }

  useEffect(() => {
    //getCards();
    //getCountries();
    getPayments();
    get_user_licenses();
    window.scrollTo(0, 0);
  }, [])



  const listOfCards = cards.map(c => 
    <div className={` ${showForms["form"+c.id] ? 'expanded' : ''}`}
    style={{
       width:"49%"
    }}
    >

          <div className='py-3 d-flex flex-column boxShadowCard borderRadius'>
            <div className='d-flex justify-content-between pl-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={card} alt="" width={"100%"} height={"100%"}  style={{marginTop:"5px"}}/>
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>{c.brand[0].toUpperCase() + c.brand.slice(1)}</div>
              <div className='fontSizeTitleCard textBold colorPay' style={{ marginRight: "300px" }}>{c.last4}</div>
              <div className='sizeImgTitleCard' onClick={() => toggleForm('form'+c.id)} style={{ cursor: 'pointer' }}>
                <img src={dropdown} alt="" />
              </div>
            </div>
            {showForms["form"+c.id] && (
              <div className="form-container pt-3" style={{ padding: "70px" }}>
                <form>
                  <div className="form-group">
                    <div  className='colorPay marText' style={{marginTop:"20px"}}>Country</div>
                    <div className='fontText1 marText'>Country/Region</div>
                    <select id="country" className="form-control select" onChange={(e) => handleCountry(e, c.id)} defaultValue={c.billing_details.address.country || ''}>
                      {countriesList}
                    </select>
                  </div>
                  {/* <div className="form-group">
                    <div className='colorPay marText' style={{marginTop:"30px"}}>Payment Method</div>
                    <div className='fontText1 marText'>Select Payment Method</div>
                    <select id="paymentMethod" className="form-control select">
                      <option value="">March</option>
                    </select>
                  </div> */}
                  <div className="form-group">
                    <label className='fontText1 marText'>Card Number</label>
                    <input type="text" id="cardNumber" className="form-control inputpay" value={"**** **** **** "+c.last4} disabled/>
                  </div>
                  <div className="containerpay">
                    <div>
                      <label className='fontText1 marText'>mm/yyyy</label>
                      <input type="text" id="expiryDate" className="inputpay form-control" placeholder="MM/YY" value={c.exp_month+"/"+c.exp_year} disabled/>
                    </div> 
                    {/* <div>
                      <label className='fontText1 marText'>CVV</label>
                      <input type="text" id="cvv" className="inputpay form-control" />
                    </div> */}
                  </div>
                  <div style={{marginTop:"30px"}}>
                    <div className='colorPay marText'>Billing Address</div>
                  </div>
                  <div className="containerpay" style={{marginTop:"-5px"}}>
                    <div>
                      <label className='fontText1 marText'>First Name</label>
                      <input type="text" id="firstName" className="inputpay form-control" onChange={(e)=>handleFirstName(e,c.id)} defaultValue={c.billing_details.name!=null?(c.billing_details.name.split(/\s+/))[0]:""}/>
                    </div>
                    <div>
                      <label className='fontText1 marText'>Last Name</label>
                      <input type="text" id="lastName" className="inputpay form-control" onChange={(e)=>handleLastName(e,c.id)} defaultValue={c.billing_details.name!=null?((c.billing_details.name.split(/\s+/)).length>0?(c.billing_details.name.split(/\s+/))[1]:""):""}/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>Street</label>
                    <input type="text" id="address" className="inputpay form-control" onChange={(e)=>updateLine1(c.id,e.target.value)} defaultValue={c.billing_details.address.line1}/>
                  </div>
                  <div className="containerpay">
                    <div>
                      <label className='fontText1 marText'>Number</label>
                      <input type="text" id="number" className="inputpay form-control" onChange={(e)=>updateLine2(c.id,e.target.value)} defaultValue={c.billing_details.address.line2}/>
                    </div>
                    <div>
                      <label className='fontText1 marText'>Postal Code</label>
                      <input type="text" id="postalCode" className="inputpay form-control" onChange={(e)=>updatePostalCode(c.id,e.target.value)} defaultValue={c.billing_details.address.postal_code}/>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>City</label>
                    <input type="text" id="city" className="inputpay form-control" onChange={(e)=>updateCity(c.id,e.target.value)} defaultValue={c.billing_details.address.city}/>
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>State</label>
                    <select id="country" className="form-control select"  onChange={(e)=>handleState(e, c.id)} defaultValue={c.billing_details.address.state}>
                      {c.statesList}
                  </select>
                    {/* <input type="text" id="state" className="inputpay form-control" onChange={(e)=>updateState(c.id,e.target.value)} defaultValue={c.billing_details.address.state}/> */}
                  </div>
                  <div className="containerpay">
                    <div>
                      <label className='fontText1 marText'>Code</label>
                      <input type="text" id="code" className="inputpay form-control" value={c.billing_details.area_calling_code} disabled/>
                    </div>
                    <div>
                      <label className='fontText1 marText'>Phone Number</label>
                      <input type="text" id="phoneNumber" className="inputpay form-control"  onChange={(e)=>updatePhone(c.id,e.target.value)} defaultValue={c.billing_details.phone}/>
                    </div>
                  </div>
                </form>
                <p className='custom-button btn-orange w-100 textBold btn-block btn' style={{ width:'100px',color: "red", marginTop: "20px" }} onClick={()=>ShowQuestion(c)}>Delete payment Method</p>
              </div>
            )}
          </div>
        </div>
  )

  const loadingSpinner = <Row><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;



  return (
    <div style={{marginRight:''}}>
      <Row>
        <Col>
      <h2 className='textBold marginBottom2rem'>Licenses Manager</h2>
      <div>
        <p className='fontText3 colorBlack marginBottom2rem' >See all your licenses</p>
      </div>
      </Col>
      <Col style={{ display:'flex', justifyContent:'end', paddingTop:'10px' }}>
      {/*<button style={{height:'40px'}} className="btn custom-button w-30 textBold" onClick={()=>handleModal()}>Add Card</button>*/}
      </Col>
      </Row>




      <div className="posicionamiento">
      {loadingContent? loadingSpinner : 
      
      

          <Row style={{ width:'100vw', paddingLeft:'0px', paddingRight:'0px' }}>
      <Col style={{ width:'100%' }}>
      <div className='avp-border'>
              <Table responsive style={{paddingLeft:'10px'}}>
                
                <thead className='avp-border-color' style={{  fontFamily: 'Regular Intro Bold', textAlign:'start', backgroundColor:'transparent', color:'#595959', /*borderBottomStyle:'solid', borderBottomWidth:'2px'*/ }}>
                <div  className='nowarp fontSizeTitleCard textBold'  style={{ padding:'10px', fontSize:'1.2vw', paddingLeft:'34px' }}>Subscription Payments</div>
                </thead>
                
                <tbody>
                <tr style={{color:'#595959'}}>
                    <th style={{paddingLeft:'34px'}} className='fontSizesubTitleCard textBold'>Type</th>
                    <th className='fontSizesubTitleCard textBold'>ID</th>
                    <th className='fontSizesubTitleCard textBold'>Name</th>
                    
                  </tr>
          {licenses.map(licenses => (
            <tr style={{}} className='lineaBajaTabla'>
              <td style={{paddingLeft:'34px'}}>License</td>
              <td>{licenses.id}</td>
              <td>{licenses.name}</td>
            </tr>
            ))}
          </tbody>
        </Table>
        </div>
      </Col>
    </Row>
      
      
 
        
        
        
    }


      {/* MODAL BEGIN */}
      <Modal show={showModalImg} onHide={handleClose} id="modalImg">
        <Modal.Header closeButton>
          <Modal.Title>Do you want delete it?</Modal.Title>
        </Modal.Header>
        <Modal.Body className=''>
        <div className='d-flex justify-content-around w-100'>
            <button className="custom-button btn-orange w-40 textBold btn-block btn" onClick={()=>AnotherremoveCard()}>Yes</button>
            <button className="w-40 textBold btn custom-button " onClick={()=>handleClose()}>No</button>
          </div>
          {error.length > 0 && (
                    <Alert style={{position:'absolute', zIndex:99999999, width:'93%', marginTop:'30px'}} variant='danger'>
                      {error}
                    </Alert>)}
        </Modal.Body>
      </Modal>

      {/* MODAL END */}

      {showModal && <AddCreditCard show={showModal} handleClose={handleModal} isGreen={true}  spanish={false}/>}

        {/* <div className={` ${showForms.form1 ? 'expanded' : ''}`}>

          <div className='py-3 d-flex flex-column boxShadowCard borderRadius'>
            <div className='d-flex justify-content-between pl-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={card} alt="" width={"100%"} height={"100%"}  style={{marginTop:"5px"}}/>
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Visa</div>
              <div className='fontSizeTitleCard textBold colorPay' style={{ marginRight: "300px" }}>1234</div>
              <div className='sizeImgTitleCard' onClick={() => toggleForm('form1')} style={{ cursor: 'pointer' }}>
                <img src={dropdown} alt="" />
              </div>
            </div>
            {showForms.form1 && (
              <div className="form-container pt-3" style={{ padding: "70px" }}>
                <form>
                  <div className="form-group">
                    <div  className='colorPay marText' style={{marginTop:"20px"}}>Country</div>
                    <div className='fontText1 marText'>Country/Region</div>
                    <select id="country" className="form-control select">
                      <option className='selects' value="">Select Country</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <div className='colorPay marText' style={{marginTop:"30px"}}>Payment Method</div>
                    <div className='fontText1 marText'>Select Payment Method</div>
                    <select id="paymentMethod" className="form-control select">
                      <option value="">March</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>Card Number</label>
                    <input type="text" id="cardNumber" className="form-control inputpay" />
                  </div>
                  <div className="containerpay">
                    <div>
                      <label className='fontText1 marText'>mm/yyyy</label>
                      <input type="text" id="expiryDate" className="inputpay form-control" placeholder="MM/YY" />
                    </div>
                    <div>
                      <label className='fontText1 marText'>CVV</label>
                      <input type="text" id="cvv" className="inputpay form-control" />
                    </div>
                  </div>
                  <div style={{marginTop:"30px"}}>
                    <div className='colorPay marText'>Billing Address</div>
                  </div>
                  <div className="containerpay" style={{marginTop:"-5px"}}>
                    <div>
                      <label className='fontText1 marText'>First Name</label>
                      <input type="text" id="firstName" className="inputpay form-control" />
                    </div>
                    <div>
                      <label className='fontText1 marText'>Last Name</label>
                      <input type="text" id="lastName" className="inputpay form-control" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>Street</label>
                    <input type="text" id="address" className="inputpay form-control" />
                  </div>
                  <div className="containerpay">
                    <div>
                      <label className='fontText1 marText'>Number</label>
                      <input type="text" id="number" className="inputpay form-control" />
                    </div>
                    <div>
                      <label className='fontText1 marText'>Postal Code</label>
                      <input type="text" id="postalCode" className="inputpay form-control" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>City</label>
                    <input type="text" id="city" className="inputpay form-control" />
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>State</label>
                    <input type="text" id="state" className="inputpay form-control" />
                  </div>
                  <div className="containerpay">
                    <div>
                      <label className='fontText1 marText'>Code</label>
                      <input type="text" id="code" className="inputpay form-control" />
                    </div>
                    <div>
                      <label className='fontText1 marText'>Phone Number</label>
                      <input type="text" id="phoneNumber" className="inputpay form-control" />
                    </div>
                  </div>
                </form>
                <p style={{ color: "red", marginTop: "20px" }}>Delete payment Method</p>
              </div>
            )}
          </div>
        </div>

        <div className={` ${showForms.form2 ? 'expanded' : ''}`}>

          <div className='py-3 d-flex flex-column boxShadowCard borderRadius'>
            <div className='d-flex justify-content-between pl-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={card} alt="" width={"100%"} height={"100%"}  style={{marginTop:"5px"}}/>
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>Visa</div>
              <div className='fontSizeTitleCard textBold colorPay' style={{ marginRight: "300px" }}>1234</div>
              <div className='sizeImgTitleCard' onClick={() => toggleForm('form2')} style={{ cursor: 'pointer' }}>
                <img src={dropdown} alt="" />
              </div>
            </div>
            {showForms.form2 && (
              <div className="form-container pt-3" style={{ padding: "70px" }}>
                <form>
                  <div className="form-group">
                    <div  className='colorPay marText' style={{marginTop:"20px"}}>Country</div>
                    <div className='fontText1 marText'>Country/Region</div>
                    <select id="country" className="form-control select">
                      <option className='selects' value="">Select Country</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <div className='colorPay marText' style={{marginTop:"30px"}}>Payment Method</div>
                    <div className='fontText1 marText'>Select Payment Method</div>
                    <select id="paymentMethod" className="form-control select">
                      <option value="">March</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>Card Number</label>
                    <input type="text" id="cardNumber" className="form-control inputpay" />
                  </div>
                  <div className="containerpay">
                    <div>
                      <label className='fontText1 marText'>mm/yyyy</label>
                      <input type="text" id="expiryDate" className="inputpay form-control" placeholder="MM/YY" />
                    </div>
                    <div>
                      <label className='fontText1 marText'>CVV</label>
                      <input type="text" id="cvv" className="inputpay form-control" />
                    </div>
                  </div>
                  <div style={{marginTop:"30px"}}>
                    <div className='colorPay marText'>Billing Address</div>
                  </div>
                  <div className="containerpay" style={{marginTop:"-5px"}}>
                    <div>
                      <label className='fontText1 marText'>First Name</label>
                      <input type="text" id="firstName" className="inputpay form-control" />
                    </div>
                    <div>
                      <label className='fontText1 marText'>Last Name</label>
                      <input type="text" id="lastName" className="inputpay form-control" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>Street</label>
                    <input type="text" id="address" className="inputpay form-control" />
                  </div>
                  <div className="containerpay">
                    <div>
                      <label className='fontText1 marText'>Number</label>
                      <input type="text" id="number" className="inputpay form-control" />
                    </div>
                    <div>
                      <label className='fontText1 marText'>Postal Code</label>
                      <input type="text" id="postalCode" className="inputpay form-control" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>City</label>
                    <input type="text" id="city" className="inputpay form-control" />
                  </div>
                  <div className="form-group">
                    <label className='fontText1 marText'>State</label>
                    <input type="text" id="state" className="inputpay form-control" />
                  </div>
                  <div className="containerpay">
                    <div>
                      <label className='fontText1 marText'>Code</label>
                      <input type="text" id="code" className="inputpay form-control" />
                    </div>
                    <div>
                      <label className='fontText1 marText'>Phone Number</label>
                      <input type="text" id="phoneNumber" className="inputpay form-control" />
                    </div>
                  </div>
                </form>
                <p style={{ color: "red", marginTop: "20px" }}>Delete payment Method</p>
              </div>
            )}
          </div>
        </div> */}
      </div>

{(licenses.length == 0 && loadingContent == false) &&
      <div>
      <><Row className="justify-content-center my-4">
          <Col xs="6" style={{display:'flex', justifyContent:'center'}}>
            <Image style={{width:'50%', marginTop:'60px', marginRight:'0'}} src={Logo} fluid />
          </Col>
          </Row>
          <Col style={{textAlign:'center', marginBottom:'60px', marginRight:'0'}}>
          <Row style={{marginRight:'0'}}><Col><h5>You don’t have data to show</h5></Col></Row>
          <Row style={{marginRight:'0'}}><Col>When you have data to show, you’ll find here.</Col></Row>
         {/*} <Row style={{marginRight:'293px'}}><Col>want to track, you'll find here. </Col></Row>*/}
          </Col>
        </>
        </div>
}
    </div>

  );
};

export default licensesManager;


