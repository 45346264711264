import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { LiveChatWidget } from '@livechat/widget-react'

// Importación de imágenes
import logo from '../../../assets/img/landingPage/AVAP_Framework.svg'
import icn_avap from '../../../assets/img/logos/icon_avap.svg'
import icn_extension from '../../../assets/img/logos/extensionsIcon.svg'
import icn_ds from '../../../assets/img/logos/icono_DS.svg'
import icn_avs from '../../../assets/img/logos/icono_VS.svg'
import icn_lang from '../../../assets/img/logos/icon_avap.svg'
import icn_gate from '../../../assets/img/logos/icono_Gateway.svg'
import icn_vc from '../../../assets/img/logos/AVAP_APIControVersion.svg'
import icn_publisher from '../../../assets/img/logos/icono_publisher.svg'
import icn_market from '../../../assets/img/logos/AVAP_Marketplace_icon.svg'
import icn_connector from '../../../assets/img/logos/AVAP_Connector_Icono.svg'
import icn_collab from '../../../assets/img/logos/icono_CollaborativeN.svg'
import icn_cloud from '../../../assets/img/logos/icono_Cloud.svg'
import icn_platon from '../../../assets/img/logos/icono_Platon.svg'
import icn_communities from '../../../assets/img/logos/icono_Communities.svg'
import icn_framework from '../../../assets/img/logos/icono_Framework.svg'
import icn_alexandria from '../../../assets/img/logos/icono_Alexandria.svg'
import icn_blog from '../../../assets/img/logos/icono_blog.svg'
import icn_101 from '../../../assets/img/logos/101-menu.svg'

import transparent from '../../../assets/img/logos/transparent.png'

// UI Icons
import chevron from '../../../assets/img/ui_icons/chevron.svg'
import user from '../../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../../assets/img/ui_icons/fa-doc.svg'

// Definición de interfaces
interface NavItem {
    text: string;
    href: string;
    desc: string;
    img?: string;
    type?: "link";
    target?: string;
}

interface NavSection {
    title: string;
    items: NavItem[];
}

// Definición de enlaces
const productsLinks: NavSection[] = [
    {
        title: "Build Virtual APIs",
        items: [
            {
                text: "AVAP (Advanced Virtual API Programming)",
                href: "/avap",
                desc: "High-level virtual programming language designed and created for API development.",
                img: icn_avap
            },
            {
                text: "AVAP Dev Studio",
                href: "/devstudio",
                desc: "AVAP’s IDE for complete project management, API development, code maintenance, and API publication.",
                img: icn_ds
            },
            {
                text: "AVAP Dev Studio Extensions for Microsoft Visual Code",
                href: "/vsc-extensions",
                desc: "AVAP language extensions to build, version, and publish APIs seamlessly in Microsoft Visual Code.",
                img: icn_ds
            }
        ],
    },
    {
        title: "Run APIs",
        items: [
            {
                text: "AVAP Gateway Advanced",
                href: "/gateway",
                desc: "Intelligent, secure gateway for dynamic traffic routing, optimized performance, and scalable security.",
                img: icn_gate
            },
            {
                text: "AVAP Virtual Server",
                href: "/avs",
                desc: "Lightweight universal API server enabling lifecycle management and intelligent traffic optimization.",
                img: icn_avs
            },
            {
                text: "AVAP Interpreter & Language Server",
                href: "/interpreter",
                desc: "Fast, scalable API development with real-time interpretation and AI-powered code enhancement.",
                img: icn_lang
            }
        ],
    },
    {
        title: "Test & Deliver APIs",
        items: [
            {
                text: "AVAP API Version Control",
                href: "/version-control",
                desc: "Designed for API versioning with tracking, multi-version management, and backward compatibility.",
                img: icn_vc
            },
            {
                text: "AVAP API Publisher",
                href: "/api-publisher",
                desc: "Streamlined API publishing with robust management, version control, and seamless platform integration.",
                img: icn_publisher
            },
        ],
    },
    {
        title: "Power Up APIs",
        items: [
            {
                text: "AVAP Marketplace",
                href: "/avap-marketplace",
                desc: "A collaborative platform for discovering, integrating, and monetizing API products.",
                img: icn_market
            },
            {
                text: "AVAP Connectors",
                href: "/avap-connectors",
                desc: "Pre-configured components designed to simplify access to external systems, platforms, and databases.",
                img: icn_connector
            },
        ],
    },
];

const servicesLinks: NavSection[] = [
    {
        title: "PROFESSIONAL",
        items: [
            {
                text: "AVAP Collaborative Network",
                href: "/collaborative",
                desc: "Peer-to-peer platform for scalable API development, deployment, and optimized processing across servers.",
                img: icn_collab
            },
            {
                text: "AVAP Cloud Professional",
                href: "/cloud",
                desc: "A powerful, flexible platform for API lifecycle management. Infrastructure and technology to start developing.",
                img: icn_cloud
            }
        ],
    },
    {
        title: "BUSINESS",
        items: [
            {
                text: "AVAP Cloud Business",
                href: "/cloud",
                desc: "A powerful, flexible platform for API lifecycle management. Infrastructure to take you to the next level.",
                img: icn_cloud
            }
        ],
    },
    {
        title: "CORPORATE",
        items: [
            {
                text: "AVAP Cloud Enterprise",
                href: "/cloud",
                desc: "A powerful, flexible platform for API lifecycle management. Full deployment control of infrastructure, tailored for corporations.",
                img: icn_cloud
            }
        ],
    }
];

const resourcesLinks: NavSection[] = [
    {
        title: "DEVELOPER SUPPORT",
        items: [
            {
                text: "AVAP Developer Portal",
                href: "/default",
                desc: "Centralized hub for AVAP technical documentation, code samples, and more for AVAP Framework.",
                img: icn_ds,
                type: 'link',
                target: "_blank",
            },
            {
                text: "AVAP Communities",
                href: "https://speed-computing-1656.my.site.com/communities/s/",
                type: "link",
                desc: "Collaborative forum for developers to share knowledge, solve challenges, and innovate.",
                img: icn_communities
            },
        ],
    },
    {
        title: "KNOWLEDGE CENTER",
        items: [
            {
                text: "AVAP Alexandria",
                href: "https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US",
                type: "link",
                desc: "Centralized hub for product guides, legal documents, and resources.",
                img: icn_alexandria
            },
            {
                text: "AVAP Blog",
                href: "https://speed-computing-1656.my.site.com/blog/s/",
                type: "link",
                desc: "Find our insights on technology, AVAP, APIs, innovation, business, and product updates.",
                img: icn_blog
            }
        ],
    },
    {
        title: "API PRODUCTS",
        items: [
            {
                text: "AVAP Marketplace",
                href: "/avap-marketplace",
                desc: "A collaborative platform for discovering, integrating, and monetizing API products.",
                img: icn_market
            }
        ],
    },
    {
        title: "NEWEST FEATURES",
        items: [
            {
                text: "Product Roadmap",
                href: "/roadmap",
                desc: "Visualize and explore the next steps of our complete AVAP ecosystem effortlessly.",
                img: icn_framework
            }
        ],
    }
];

const companyLinks: NavSection[] = [
    {
        title: "COMPANY",
        items: [
            {
                text: "About Us",
                href: "/about",
                desc: "Discover AVAP’s mission, values, and journey in API innovation.",
                img: icn_101
            },
            {
                text: "Contact Us",
                href: "/contact",
                desc: "Reach out for support, inquiries, or collaboration opportunities.",
                img: transparent
            }
        ],
    }
];

const sideLinks: NavSection[] = [
    {
        title: "Open Source ",
        items: [
            {
                text: "PLATON (Programming Language Architecture for True Open virtualizatioN)",
                href: "/platon",
                desc: "Open-source project offering the kernel and specifications of a virtual programming language as an accessible resource.",
                img: icn_platon,
            },
        ]
    },
    {
        title: "GETTING STARTED",
        items: [
            {
                text: "Create your AVAP ID",
                href: "https://staging.avapframework.com/Register",
                desc: '',
                img: user,
                type: "link"
            },
            {
                text: "Download basic software",
                href: "https://developer.avapframework.com/wiki/over_view12-GET%20STARTED_Downloads/0",
                desc: '',
                img: download,
                type: "link"
            },
            {
                text: "Create your Developer Token",
                href: "https://developer.avapframework.com/wiki/over_view_aefmvsc_cli-GET%20STARTED_Dev_Token/0",
                desc: '',
                img: key,
                type: "link"
            },
            {
                text: "Create your first API Project",
                href: "https://developer.avapframework.com/wiki/over_view_cloud-GET%20STARTED_Projects/0",
                desc: '',
                img: doc,
                type: "link"
            }
        ]
    },
];

const sideLinks2: NavSection[] = [
    {
        title: "LEGAL ",
        items: [
            {
                text: "Terms & Conditions AVAP Collaborative Network",
                href: "https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US",
                type:"link",
                desc: "Understand the rules governing your use of AVAP Collaborative Network."
            },
            {
                text: "Terms & Conditions AVAP Cloud",
                href: "https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US",
                type:"link",
                desc: "Understand the rules governing your use of AVAP Cloud."
            },
            {
                text: "Privacy Policy",
                href: "https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US",
                type:"link",
                desc: "Learn how AVAP collects, uses, and protects your data."
            }
        ]
    }
];

const Navbar: React.FC = () => {
    const history = useHistory();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMenuOpen2, setIsMenuOpen2] = useState(false);
    const [isMenuOpen3, setIsMenuOpen3] = useState(false);
    const [isMenuOpen4, setIsMenuOpen4] = useState(false);
    const [isMenuOpen5, setIsMenuOpen5] = useState(false);

    const handleCloseAll = () => {
        setIsMenuOpen(false)
        setIsMenuOpen2(false)
        setIsMenuOpen3(false)
        setIsMenuOpen4(false)
        setIsMenuOpen5(false)
    }

    const [isMobileProductsOpen, setIsMobileProductsOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const toggleMenu = (menu: string) => {
        setActiveMenu(activeMenu === menu ? null : menu);
    };

    // Función auxiliar para renderizar enlaces simples
    const renderLink = (item: NavItem, className = "") => {
        if (item.type === "link") {
            return (
                <a
                    href={item.href}
                    target={item.target ? item.target : "_self"}
                    rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                    className={className}
                >
                    {item.text}
                </a>
            );
        } else {
            return (
                <a
                 onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}
                    className={className}
                    tabIndex={0}
                    onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                >
                    {item.text}
                </a>
            );
        }
    };

    // Función auxiliar para renderizar enlaces con íconos y descripciones
    const renderMenuItem = (item: NavItem) => {
        if (item.type === "link") {
            return (
                <a
                    href={item.href}
                    target={item.target ? item.target : "_blank"}
                    rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                >
                    <img src={item.img} alt={item.text} className="fl-icon" />
                    <div className="wrapper">
                        <span className="title"> {item.text}</span>
                        <span className="desc">{item.desc}</span>
                    </div>
                </a>
            );
        } else {
            return (
                <a
                 onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}

                    tabIndex={0}
                    onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                >
                    <img src={item.img} alt={item.text} className="fl-icon" />
                    <div className="wrapper">
                        <span className="title"> {item.text}</span>
                        <span className="desc">{item.desc}</span>
                    </div>
                </a>
            );
        }
    };

    // Función auxiliar para renderizar enlaces en el drawer
    const renderDrawerLink = (item: NavItem) => {
        if (item.type === "link") {
            return (
                <a
                    href={item.href}
                    target={item.target ? item.target : "_blank"}
                    rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                    className="lf-drawer-item lf-drawer-dropdown"
                >
                    {item.text}
                </a>
            );
        } else {
            return (
                <a
                 onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}
                    className="lf-drawer-item lf-drawer-dropdown"

                    tabIndex={0}
                    onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                >
                    {item.text}
                </a>
            );
        }
    };

    return (
        <>
            <LiveChatWidget license="18865161" group="0" />
            <nav className="lf-navbar">
                <div className="lf-navbar-brand">
                    <a
                        href="#"
                        onClick={(e) => { e.preventDefault(); history.push('/framework'); }}
                        style={{ backgroundImage: `url(${logo})` }}
                        className="lf-brand-logo"
                        aria-label="AVAP Framework"
                    ></a>
                    <button
                        className="lf-menu-toggle"
                        aria-label="Toggle menu"
                        onClick={toggleDrawer}
                    >
                        ☰
                    </button>
                </div>

                {/* Menú Flotante para Productos */}
                {isMenuOpen && (
                    <div className="lf-floating-menu">
                        <div className="fl-left">
                            {productsLinks.map((section, index) => (
                                <div className="lf-menu-section" key={index}>
                                    <h4>{section.title}</h4>
                                    <ul>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                {renderMenuItem(item)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <div className="fl-right">
                            {/* Sección "Open Source" */}
                            <div className="openSource">
                                {sideLinks
                                    .filter((section) => section.title.trim().toLowerCase() === "open source")
                                    .map((section, index) => (
                                        <div className="lf-menu-section" key={index}>
                                            <h4>{section.title}</h4>
                                            <ul>
                                                {section.items.map((item, idx) => (
                                                    <li key={idx}>
                                                        {item.type === "link" ? (
                                                            <a
                                                                href={item.href}
                                                                target={item.target ? item.target : "_blank"}
                                                                rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                                                                className="d-flex"
                                                            >
                                                                <img src={item.img} alt={item.text} />
                                                                <div>
                                                                    <span className="title">{item.text}</span>
                                                                    {item.desc && <span className="desc">{item.desc}</span>}
                                                                </div>
                                                            </a>
                                                        ) : (
                                                            <a
                                                             onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}
                                                                className="d-flex"

                                                                tabIndex={0}
                                                                onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                                                            >
                                                                <img src={item.img} alt={item.text} />
                                                                <div>
                                                                    <span className="title">{item.text}</span>
                                                                    {item.desc && <span className="desc">{item.desc}</span>}
                                                                </div>
                                                            </a>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                            </div>
                            <div className="dividerMenu"></div>
                            {/* Sección "GETTING STARTED" */}
                            <div className="started">
                                {sideLinks
                                    .filter((section) => section.title.trim().toLowerCase() === "getting started")
                                    .map((section, index) => (
                                        <div className="lf-menu-section" key={index}>
                                            <h4>{section.title}</h4>
                                            <ul>
                                                {section.items.map((item, idx) => (
                                                    <li key={idx}>
                                                        {item.type === "link" ? (
                                                            <a
                                                                href={item.href}
                                                                target={item.target ? item.target : "_blank"}
                                                                rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                                                            >
                                                                <img className="icon-small" src={item.img} alt={item.text} />
                                                                <span className="title">{item.text}</span>
                                                            </a>
                                                        ) : (
                                                            <a
                                                             onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}

                                                                tabIndex={0}
                                                                onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                                                            >
                                                                <img className="icon-small" src={item.img} alt={item.text} />
                                                                <span className="title">{item.text}</span>
                                                            </a>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                            </div>

                            <div className="devPortal">
                                {/* Puedes añadir contenido aquí si es necesario */}
                            </div>
                        </div>
                    </div>
                )}

                {/* {isMenuOpen2 && (
                            <div className="lf-floating-menu">
                                             <h4>{section.title}</h4>
                                            <ul>
                                                {section.items.map((item, idx) => (
                               <div className="fl-left">
                                    {servicesLinks.map((section, index) => (
                                        <div className="lf-menu-section-3" key={index}>
                                                    <li key={idx}>
                                                        <a onClick={() => history.push(item.href)}>
                                                            <img src={item.img} alt={item.text} className="fl-icon" />
                                                            <div className="wrapper">
                                                                <span className="title"> {item.text}</span>
                                                                <span className="desc">{item.desc}</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                                {

                                }
                            </div>
                        )} */}

                {isMenuOpen2 && (
                    <div className="lf-floating-menu">
                        <div className="fl-left">
                            {servicesLinks.map((section, index) => (
                                <div className="lf-menu-section-3" key={index}>
                                    <h4>{section.title}</h4>
                                    <ul>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                {item.type === "link" ? (
                                                    <a
                                                        href={item.href}
                                                        target={item.target ? item.target : "_blank"}
                                                        rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                                                    >
                                                        <img src={item.img} alt={item.text} className="fl-icon" />
                                                        <div className="wrapper">
                                                            <span className="title"> {item.text}</span>
                                                            <span className="desc">{item.desc}</span>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    <a
                                                     onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}

                                                        tabIndex={0}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                                                    >
                                                        <img src={item.img} alt={item.text} className="fl-icon" />
                                                        <div className="wrapper">
                                                            <span className="title"> {item.text}</span>
                                                            <span className="desc">{item.desc}</span>
                                                        </div>
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                )}


                {/* Otros menús flotantes (isMenuOpen3, isMenuOpen4, isMenuOpen5) */}
                {(isMenuOpen3 && false) && (
                    <div className="lf-floating-menu">
                        <div className="fl-left">
                            {resourcesLinks.map((section, index) => (
                                <div className="lf-menu-section" key={index}>
                                    <h4>{section.title}</h4>
                                    <ul>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                {item.type === "link" ? (
                                                    <a
                                                        href={item.href}
                                                        target={item.target ? item.target : "_blank"}
                                                        rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                                                    >
                                                        <img src={item.img} alt={item.text} className="fl-icon" />
                                                        <div className="wrapper">
                                                            <span className="title"> {item.text}</span>
                                                            <span className="desc">{item.desc}</span>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    <a
                                                     onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}

                                                        tabIndex={0}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                                                    >
                                                        <img src={item.img} alt={item.text} className="fl-icon" />
                                                        <div className="wrapper">
                                                            <span className="title"> {item.text}</span>
                                                            <span className="desc">{item.desc}</span>
                                                        </div>
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {isMenuOpen4 && (
                    <div className="lf-floating-menu">
                        <div className="fl-left">
                            {resourcesLinks.map((section, index) => (
                                <div className="lf-menu-section-3" key={index}>
                                    <h4>{section.title}</h4>
                                    <ul>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                {item.type === "link" ? (
                                                    <a
                                                        href={item.href}
                                                        target={item.target ? item.target : "_blank"}
                                                        rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                                                    >
                                                        <img src={item.img} alt={item.text} className="fl-icon" />
                                                        <div className="wrapper">
                                                            <span className="title"> {item.text}</span>
                                                            <span className="desc">{item.desc}</span>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    <a
                                                     onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}

                                                        tabIndex={0}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                                                    >
                                                        <img src={item.img} alt={item.text} className="fl-icon" />
                                                        <div className="wrapper">
                                                            <span className="title"> {item.text}</span>
                                                            <span className="desc">{item.desc}</span>
                                                        </div>
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {isMenuOpen5 && (
                    <div className="lf-floating-menu">
                        <div className="fl-left" style={{ width: '30%' }}>
                            {companyLinks.map((section, index) => (
                                <div className="lf-menu-section" key={index}>
                                    <h4>{section.title}</h4>
                                    <ul>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                {renderMenuItem(item)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                        <div className="fl-right" style={{ width: '70%' }}>
                            {/* Sección "Legal" */}
                            <div className="openSource">
                                {sideLinks2.map((section, index) => (
                                    <div className="lf-menu-section" key={index}>
                                        <h4>{section.title}</h4>
                                        <ul>
                                            {section.items.map((item, idx) => (
                                                <li key={idx}>
                                                    {item.type === "link" ? (
                                                        <a
                                                            href={item.href}
                                                            target={item.target ? item.target : "_blank"}
                                                            rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                                                            className="d-flex flex-column ml-4 mb-5"
                                                        >
                                                            <span className="title">{item.text}</span>
                                                            {item.desc && <span className="desc">{item.desc}</span>}
                                                        </a>
                                                    ) : (
                                                        <a
                                                         onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}
                                                            className="d-flex flex-column ml-4 mb-5"

                                                            tabIndex={0}
                                                            onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                                                        >
                                                            <span className="title">{item.text}</span>
                                                            {item.desc && <span className="desc">{item.desc}</span>}
                                                        </a>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                <ul className="lf-navbar-main-links">
                    <li
                        className="lf-menu-item"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            handleCloseAll();
                            setIsMenuOpen(!isMenuOpen)
                        }}
                    >
                        <span className="lf-menu-link">Products</span>
                    </li>
                    <li
                             className="lf-menu-item"
                             style={{ cursor: 'pointer' }}
                             onClick={() => {
                                 handleCloseAll();
                                 setIsMenuOpen2(!isMenuOpen2)
                             }}
                            >
                        <span className="lf-menu-link">Services</span>
                    </li>
                    <li>
                        <a
                            onClick={() => history.push('/brunix')}
                            style={{ cursor: 'pointer' }}
                            className="lf-menu-link">AI Brunix</a>






                    </li>
                    <li>
                        <span
                            onClick={() => {
                                handleCloseAll();
                                setIsMenuOpen4(!isMenuOpen4)
                            }}
                            style={{ cursor: 'pointer' }}
                            className="lf-menu-link"
                        >
                            Resources
                        </span>
                    </li>
                    <li>
                        <span
                            onClick={() => {
                                handleCloseAll();
                                setIsMenuOpen5(!isMenuOpen5)
                            }}
                            style={{ cursor: 'pointer' }}
                            className="lf-menu-link"
                        >
                            Company
                        </span>
                    </li>
                </ul>

                <ul className="lf-navbar-actions">
                    <li>
                        <a
                            onClick={() => history.push("/avap_cloud")}
                            className="lf-menu-item lf-button"

                            tabIndex={0}
                            onKeyPress={(e) => { if (e.key === 'Enter') history.push("/avap_cloud") }}
                        >
                            Get a Demo
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={() => history.push("/avap_cloud")}
                            className="lf-menu-item lf-button lf-primary"

                            tabIndex={0}
                            onKeyPress={(e) => { if (e.key === 'Enter') history.push("/avap_cloud") }}
                        >
                            Start for Free
                        </a>
                    </li>
                </ul>
            </nav>

            {/* Drawer lateral para móvil */}
            <div className={`lf-drawer ${isDrawerOpen ? "lf-drawer-open" : ""}`}>
                <button className="lf-close-drawer" onClick={toggleDrawer}>
                    ✖
                </button>
                <ul className="lf-drawer-links">
                    <li className="lf-drawer-item lf-drawer-dropdown">
                        <button
                            className="lf-drawer-dropdown-toggle"
                            onClick={() => setIsMobileProductsOpen(!isMobileProductsOpen)}
                        >
                            Products{" "}
                            <img
                                src={chevron}
                                alt="Toggle"
                                style={{
                                    transform: isMobileProductsOpen ? "rotate(0deg)" : "rotate(-90deg)",
                                    transition: "transform 0.2s ease",
                                }}
                            />
                        </button>
                        {isMobileProductsOpen && (
                            <ul className="lf-drawer-submenu">
                                {productsLinks.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <li><strong>{section.title}</strong></li>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                {item.type === "link" ? (
                                                    <a
                                                        href={item.href}
                                                        target={item.target ? item.target : "_blank"}
                                                        rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                                                    >
                                                        {item.text}
                                                    </a>
                                                ) : (
                                                    <a
                                                     onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}

                                                        tabIndex={0}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                                                    >
                                                        {item.text}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                        <li><hr /></li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        )}
                    </li>

                    <li>
                        <a
                            onClick={() => history.push('/cloud')}
                            style={{ cursor: 'pointer' }}
                            className="lf-menu-link">Avap Cloud</a>
                    </li>


                    <li>
                        <a
                            onClick={() => history.push('/brunix')}
                            style={{ cursor: 'pointer' }}
                            className="lf-menu-link">AI Brunix</a>
                    </li>

                    {/* Resources */}
                    <li className="lf-drawer-item lf-drawer-dropdown">
                        <button
                            className="lf-drawer-dropdown-toggle"
                            onClick={() => setIsMenuOpen4(!isMenuOpen4)}
                        >
                            Resources{" "}
                            <img
                                src={chevron}
                                alt="Toggle"
                                style={{
                                    transform: isMenuOpen4 ? "rotate(0deg)" : "rotate(-90deg)",
                                    transition: "transform 0.2s ease",
                                }}
                            />
                        </button>
                        {isMenuOpen4 && (
                            <ul className="lf-drawer-submenu">
                                {resourcesLinks.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <li><strong>{section.title}</strong></li>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                {item.type === "link" ? (
                                                    <a
                                                        href={item.href}
                                                        target={item.target ? item.target : "_blank"}
                                                        rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                                                    >
                                                        {item.text}
                                                    </a>
                                                ) : (
                                                    <a
                                                     onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}

                                                        tabIndex={0}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                                                    >
                                                        {item.text}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                        <li><hr /></li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        )}
                    </li>

                    {/* Company */}
                    <li className="lf-drawer-item lf-drawer-dropdown">
                        <button
                            className="lf-drawer-dropdown-toggle"
                            onClick={() => setIsMenuOpen5(!isMenuOpen5)}
                        >
                            Company{" "}
                            <img
                                src={chevron}
                                alt="Toggle"
                                style={{
                                    transform: isMenuOpen5 ? "rotate(0deg)" : "rotate(-90deg)",
                                    transition: "transform 0.2s ease",
                                }}
                            />
                        </button>
                        {isMenuOpen5 && (
                            <ul className="lf-drawer-submenu">
                                {companyLinks.map((section, index) => (
                                    <React.Fragment key={index}>
                                        <li><strong>{section.title}</strong></li>
                                        {section.items.map((item, idx) => (
                                            <li key={idx}>
                                                {item.type === "link" ? (
                                                    <a
                                                        href={item.href}
                                                        target={item.target ? item.target : "_blank"}
                                                        rel={item.target === "_blank" ? "noopener noreferrer" : undefined}
                                                    >
                                                        {item.text}
                                                    </a>
                                                ) : (
                                                    <a
                                                     onClick={() => {
    history.push(item.href);
    window.scrollTo(0, 0);
}}

                                                        tabIndex={0}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') history.push(item.href) }}
                                                    >
                                                        {item.text}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                        <li><hr /></li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        )}
                    </li>
                    <li><a href="#signin" className="lf-drawer-item lf-button mb-2 mt-5">Sign In</a></li>
                    <li><a href="#join" className="lf-drawer-item lf-button lf-primary">Join Today</a></li>
                </ul>
            </div>

            {isDrawerOpen && <div className="lf-backdrop" onClick={toggleDrawer}></div>}

            {(isMenuOpen || isMenuOpen2 || isMenuOpen4 || isMenuOpen5) && <div onClick={() => { handleCloseAll() }} className="lf-menu-overlay"></div>}
        </>
    );

};

export default Navbar;
