// src/newViews/DevPortal/MainLayout.tsx

import React, { useEffect, useState } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Form,
  FormControl,
  ListGroup,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import CreateAppForm from "./CreateAppForm";
import Drawer from '../avapCloud/components/Drawer';
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import logo from "../../assets/img/AVAP_DeveloperPortal2.png";
import config from '../../configs/appConfig.json'

import sun from '../../assets/img/ui_icons/sun.svg'
import moon from '../../assets/img/ui_icons/moon.svg'

// Interfaces
interface ApiProduct {
  title: string;
  description: string;
  latestVersion: string;
  document_id: number; // Asegúrate de que cada API tenga un ID de documento asociado
}

interface App {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  apiProducts: string[];
}

interface Portal {
  portal_id: number;
  id: string;
  name: string;
  logo: string;
  theme: {
    primaryColor: string;
    secondaryColor: string;
    backgroundColor: string;
    textColor: string;
  };
  user: {
    name: string;
    photo: string;
  };
  apiProducts: ApiProduct[];
  apps?: App[];
}

interface Index {
  index_id: number;
  index_name: string;
  description?: string;
  documents?: {
    id: number;
    name: string;
  }[];
}

// Renderizador personalizado para encabezados
const headingRenderer = ({
  level,
  children,
}: {
  level: number;
  children: React.ReactNode;
}) => {
  if (level === 2) {
    const text = extractText(children);
    const id = text.toLowerCase().replace(/\s+/g, "-");
    return <h2 id={id}>{children}</h2>;
  }
  return React.createElement(`h${level}`, {}, children);
};

// Función para extraer texto de children
const extractText = (children: React.ReactNode): string => {
  if (typeof children === "string") {
    return children;
  } else if (Array.isArray(children)) {
    return children.map((child) => extractText(child)).join("");
  } else if (
    React.isValidElement(children) &&
    children.props.children
  ) {
    return extractText(children.props.children);
  }
  return "";
};

// Renderizador personalizado para código
const codeRenderer = ({
  language,
  value,
}: {
  language: string;
  value: string;
}) => {
  return (
    <SyntaxHighlighter
      style={materialLight}
      language={language || "plaintext"}
      showLineNumbers
      PreTag="div"
    >
      {value}
    </SyntaxHighlighter>
  );
};

const MainLayout: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  // Estados del layout
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [portal, setPortal] = useState<Portal | null>(null);
  const [apiProducts, setApiProducts] = useState<ApiProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<ApiProduct[]>([]);

  // Manejo de dark mode
  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  // Para mostrar el nombre del usuario
  const [userName, setUserName] = useState<string>('unknown');

  // Estado para el Drawer
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  // =========================================================
  // ESTADOS DE DOCUMENTACIÓN
  // =========================================================
  const [documents, setDocuments] = useState<any[]>([]);
  const [indexes, setIndexes] = useState<Index[]>([]);
  const [indexesLoading, setIndexesLoading] = useState<boolean>(true);
  const [submenu, setSubmenu] = useState<string[]>([]);
  const [selectedView, setSelectedView] = useState<'swagger' | number>('swagger');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [portal_id, set_portal_id] = useState<number>(0);


  const [swaggerUrl, setSwaggerUrl] = useState<string>(
    `${config.url}:${config.port}/get_portal_specs/15`
  );

  // Funciones del layout
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const cookies = new Cookies();

  const DevPortalExists = async () => {
    try {
      const portalCookie = cookies.get('portal');
      const userCookie = cookies.get('user');

      if (!portalCookie) {
        history.replace(`/devportal/${id}/login`);
        return;
      }
      setPortal(portalCookie);
      set_portal_id(portalCookie.portal_id);

      if (userCookie?.username) {
        setUserName(userCookie.username);
      }

      await DevPortalProductsExists(portalCookie.portal_id);
      await loadDocumentation(portalCookie.portal_id);
      await fetchIndexes(portalCookie.portal_id);
    } catch (error) {
      console.error(error);
      history.replace(`/devportal/${id}/login`);
    }
  };

  const DevPortalProductsExists = async (portal_id: number) => {
    try {
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_portal_products/${portal_id}`,
        {},
        true
      );
      const data = res.data;
      if (Array.isArray(data) && data.length > 0) {
        const mapped = data.map((prod: any) => ({
          title: prod.avap_api_product_name,
          description: prod.dev_portal_published_description,
          latestVersion: 'v0.1',
          document_id: prod.document_id, // Asegúrate de que 'document_id' esté presente
        }));
        setApiProducts(mapped);
        setFilteredProducts(mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // =========================================================
  // FETCH: Documentos
  // =========================================================
  const fetchDocuments = async (portalId: number): Promise<any[]> => {
    try {
      console.log("Fetching documents for portal ID:", portalId);
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_portal_documents/${portalId}`,
        {},
        true
      );

      console.log("Response from /publishing/get_dev_portal_documents:", res.data);

      if (res.data.success && res.data.data && res.data.data.length > 0) {
        return res.data.data;
      } else if (Array.isArray(res.data) && res.data.length > 0) {
        return res.data;
      } else {
        return [];
      }
    } catch (err) {
      console.error("Error fetching documents:", err);
      return [];
    }
  };

  const fetchDocumentContent = async (doc_id: number): Promise<string> => {
    try {
      console.log("Fetching content for document ID:", doc_id);
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_document/${doc_id}`,
        {},
        true
      );

      console.log(`Response from /publishing/get_dev_document/${doc_id}:`, res.data);

      if (res.data.success && res.data.data && res.data.data.document_content) {
        return res.data.data.document_content;
      } else if (res.data.document_content) {
        return res.data.document_content;
      } else {
        return "";
      }
    } catch (err) {
      console.error(`Error fetching content for document ID ${doc_id}:`, err);
      return "";
    }
  };

  // =========================================================
  // FETCH: Índices
  // =========================================================
  const fetchIndexes = async (portalId: number) => {
    try {
      setIndexesLoading(true);
      console.log("Fetching indexes for portal ID:", portalId);
      const res = await ObexRequestHandler.get(
        `/publishing/get_dev_portal_index/${portalId}`,
        {},
        true
      );
      console.log("Response from /get_dev_portal_index:", res.data);

      if (res.data.success && Array.isArray(res.data.data)) {
        setIndexes(res.data.data);
      } else if (Array.isArray(res.data)) {
        setIndexes(res.data);
      } else {
        setIndexes([]);
      }
    } catch (err) {
      console.error("Error fetching indexes:", err);
      setIndexes([]);
    } finally {
      setIndexesLoading(false);
    }
  };

  // =========================================================
  // Carga de documentación
  // =========================================================
  const loadDocumentation = async (portalId: number) => {
    try {
      const fetchedDocuments = await fetchDocuments(portalId);
      const documentsWithContent = await Promise.all(
        fetchedDocuments.map(async (doc: any) => {
          const content = await fetchDocumentContent(doc.document_id);
          return { ...doc, content };
        })
      );
      setDocuments(documentsWithContent);
    } catch (err) {
      console.error("Error loading documentation:", err);
      setError("Failed to load documentation.");
    }
  };

  // =========================================================
  // useEffect principal
  // =========================================================
  useEffect(() => {
    (async () => {
      setLoading(true);
      await DevPortalExists();
      setLoading(false);
    })();
  }, [id]);

  // =========================================================
  // Submenú
  // =========================================================
  const generateSubmenu = (markdownContent: string) => {
    const lines = markdownContent.split("\n");
    const headings = lines
      .filter((line) => /^#{1,6}\s+/.test(line))
      .map((line) => {
        const match = line.match(/^(#{1,6})\s+(.*)$/);
        if (match) {
          const level = match[1].length;
          const text = match[2].trim();
          const id = text.toLowerCase().replace(/\s+/g, "-");
          return { level, text, id };
        }
        return null;
      })
      .filter((item) => item !== null) as { level: number; text: string; id: string }[];

    if (headings.length > 0) {
      setSubmenu(headings.map((heading) => heading.text));
    } else {
      setSubmenu(["No titles available"]);
    }
  };

  useEffect(() => {
    if (selectedView === 'swagger') {
      setSubmenu([]);
      return;
    }
    const selectedDocument = documents.find(doc => doc.document_id === selectedView);
    if (selectedDocument?.content) {
      generateSubmenu(selectedDocument.content);
    } else {
      setSubmenu(["No titles available"]);
    }
  }, [selectedView, documents]);

  // =========================================================
  // Manejo del Drawer
  // =========================================================
  const handleAppSubmit = (
    app: { name: string; description: string; selectedAPIs: string[] }
  ) => {
    if (portal) {
      const newApp: App = {
        id: `app${portal.apps ? portal.apps.length + 1 : 1}`,
        name: app.name,
        description: app.description,
        createdAt: new Date().toISOString().split("T")[0],
        apiProducts: app.selectedAPIs,
      };
      if (portal.apps) {
        setPortal({ ...portal, apps: [...portal.apps, newApp] });
      } else {
        setPortal({ ...portal, apps: [newApp] });
      }
      console.log("App Created:", newApp);
      setDrawerOpen(false);
    }
  };

  // =========================================================
  // Función handleSearch
  // =========================================================
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (apiProducts.length > 0) {
      const filtered = apiProducts.filter((p) =>
        p.title.toLowerCase().includes(term)
      );
      setFilteredProducts(filtered);
    }
  };

  // =========================================================
  // Render
  // =========================================================
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!portal) return <div>Loading...</div>;

  return (
    <div id="mainLayoutWrapper" className="main-layout">

      {/* Navbar Superior */}
      <Navbar expand="lg" className="navbar-custom">
        {/* Botón que togglea el sidebar */}
        {/* <Button
          variant="outline-secondary"
          onClick={toggleSidebar}
          className="mr-2"
        >
          <span className="navbar-toggler-icon" />
        </Button> */}

        <Navbar.Brand style={{ marginLeft: '10px' }}>
          <img src={logo} alt="Logo" />
        </Navbar.Brand>

        <Nav className="ml-auto d-flex align-items-center">

          <Button variant="primary" onClick={() => setDrawerOpen(true)}>
            Create App
          </Button>

          <a
            href="#"
            className="ml-3"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); // Previene que el clic afecte al toggle del sidebar
              history.push(`/devportal/${id}/catalog`);
            }}
            style={{ cursor: 'pointer', color: 'black'/*portal.theme.primaryColor*/, textDecoration: 'none' }}
          >
            My Apps
          </a>

          <NavDropdown title={<span>{userName}</span>} id="userDropdown">
            <NavDropdown.Item>Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item>Logout</NavDropdown.Item>
          </NavDropdown>

          {/* Toggle Dark Mode */}
          <button
            style={{ marginLeft: '10px' }}
            onClick={toggleDarkMode}
            className="workspace-button">
            <img style={{ width: '20px' }} src={isDarkMode ? sun : moon} alt="Workspace Menu" />
          </button>
        </Nav>
      </Navbar>

      {/* CONTENIDO DEL LAYOUT */}
      <div className="layout-body">
        {/* SIDEBAR */}
        {/* <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}> */}
        <div className="sidebar open">
          {/* Búsqueda */}
          <Form className="sidebar-search">
            <FormControl
              type="text"
              placeholder="Search APIs..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </Form>

          {/* Listado de APIs en el sidebar */}
          <ListGroup variant="flush" className="mt-4">
            {filteredProducts.map((api, index) => (
              <ListGroup.Item key={index}>
                <strong>{api.title}</strong> <br />
                <small>v. {api.latestVersion}</small>
                <br />
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation(); 
                    if (api.document_id && typeof api.document_id === 'number') {
                      setSelectedView(api.document_id);
                    } else {
                      console.warn('No se encontró un ID de documento válido para este API.');
                    }
                  }}
                  style={{ color: portal.theme.primaryColor, cursor: 'pointer', textDecoration: 'none' }}
                >
                  Documentation
                </a>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        {/* CONTENIDO PRINCIPAL */}
        <div className="content-area shadow-sm">

          <Container fluid className="my-4">
            <Row>
              {/* Menú Izquierdo */}
              <Col md={3} className="border-right">
                {indexesLoading ? (
                  <div>Loading indexes...</div>
                ) : indexes.length > 0 ? (
                  <ListGroup className="mb-4">
                    {indexes.map((indexItem) => (
                      <React.Fragment key={indexItem.index_id}>
                        <ListGroup.Item>
                          <strong>{indexItem.index_name}</strong>
                          <p className="m-0">{indexItem.description}</p>
                        </ListGroup.Item>
                        {indexItem.documents && indexItem.documents.map((doc) => (
                          <ListGroup.Item
                            key={doc.id}
                            action
                            active={selectedView === doc.id}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation(); 
                              setSelectedView(doc.id);
                            }}
                          >
                            {doc.name}
                          </ListGroup.Item>
                        ))}
                      </React.Fragment>
                    ))}
                  </ListGroup>
                ) : (
                  <div>No indexes available</div>
                )}

                {/* Menú de Specification */}
                <h5 className="mt-4">Specification</h5>
                <ListGroup>
                  <ListGroup.Item
                    action
                    active={selectedView === 'swagger'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation(); 
                      setSelectedView('swagger');
                    }}
                  >
                    Specification
                  </ListGroup.Item>
                </ListGroup>
              </Col>

              {/* Contenido de la Documentación */}
              <Col md={6}>
                <div className="p-5">
                  {selectedView === 'swagger' ? (
                    <SwaggerUI
                      url={swaggerUrl}
                      layout="BaseLayout"
                    />
                  ) : (
                    <ReactMarkdown
                      source={
                        documents.find(doc => doc.document_id === selectedView)
                          ?.content || ''
                      }
                      escapeHtml={false}
                      renderers={{
                        heading: headingRenderer,
                        code: codeRenderer,
                      }}
                    />
                  )}
                </div>
              </Col>

           
              <Col md={3} className="border-left">
                {selectedView !== 'swagger' && (
                  <>
                    <h5>On this page</h5>
                    <ListGroup>
                      {submenu.length > 0 ? (
                        submenu.map((section, index) => (
                          <ListGroup.Item
                            action
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation(); 
                              const element = document.getElementById(
                                section.toLowerCase().replace(/\s/g, "-")
                              );
                              if (element) {
                                element.scrollIntoView({ behavior: "smooth" });
                              }
                            }}
                          >
                            {section}
                          </ListGroup.Item>
                        ))
                      ) : (
                        <ListGroup.Item disabled>No titles available</ListGroup.Item>
                      )}
                    </ListGroup>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {/* DRAWER */}
      <Drawer
        isOpen={drawerOpen}
        toggleSidebar={() => setDrawerOpen(false)} 
        name="Create App"
      >
        {portal && (
          <CreateAppForm
            app_data={{
              id: 0,
              name: undefined,
              description: undefined,
              latestVersion: undefined,
              createdAt: undefined,
              apiProducts: [],
              apiProductsID: [],
              tokens: [],
              tokensId: []
            }}
            devportal_id={portal_id.toString()}
            onSubmit={() => {
              setDrawerOpen(false);
              DevPortalExists(); 
            }}
          />
        )}
      </Drawer>
    </div>
  );
};

export default MainLayout;
