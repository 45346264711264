import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert, InputGroup, Navbar, Nav } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import Logo from '../../assets/img/newAvapCloud/logo_Cloud@2x.png';
import DevLogin from '../../assets/img/logindevicon.png';
import UserLogin from '../../assets/img/icon_login.png';
import EmailIcon from '../../assets/img/emailiconlogin.png';
import Fondologin from '../../assets/img/legos_fondologin.svg';
import PunteroLogin from '../../assets/img/puntero_login.png';
import VerificationNotice from './VerificationNotice';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
//import Footer from '../functionalComponents/DashboardLayout/FooterMenuAccess';
import './style.css';
import Cookies from 'universal-cookie';
import FooterMenu from '../functionalComponents/DashboardLayout/FooterMenuAccess';
import { color } from 'd3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'
import favicon from '../../assets/img/Favicon_AVAP_Cloud.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/DisclaimerCloud';

type CloudLoginFormProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const CloudLoginForm: FunctionComponent<{}> = () => {

  const [mail, setMail] = useState('');
  const [developer, setDeveloper] = useState(false);
  const [password, setPassword] = useState('');
  const [signining, setSignining] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showRecovery, setShowRecovery] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const handleCloseRecovery = () => setShowRecovery(false);
  const handleShowRecovery = (e) => {
    e.preventDefault();
    setShowRecovery(true);
  };
  const [rememberMe, setRememberMe] = useState(false);
  const cookies = new Cookies();

  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {setShowPassword(!showPassword)};


  useEffect(() => {
    // favicon 
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = favicon; // Usa la ruta importada directamente
    document.head.appendChild(link);

    // clean favicon 
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    console.log("A BORRAR")
    SessionService.logout();


    document.getElementById('entrada-cloud-login').focus()
    if (config.url != 'https://avs-primary-pre.101obex.mooo.com'){
    ReactGA.initialize('G-KKCW4SZLW4');
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Cloud Sign In Page' });
    }
    const savedRememberMe = cookies.get('rememberMe') === 'true';
    setRememberMe(savedRememberMe);

    if (savedRememberMe && cookies.get('myObj')) {
      const storedObj = cookies.get('myObj');
      setMail(storedObj.mail);
      setPassword(storedObj.password);
    } else {
      cookies.remove('myObj');
    }
  }, []);

  function routeDashboard() {
    history.push({
      pathname: '/cloud_dashboard'
    })
  }

  function routeDevDashboard() {
    history.push({
      // pathname: '/devdashboard'
       pathname: '/cloud_dashboard'
    })
  }

  function routeClientDashboard() {
    history.push({
      pathname: '/clientdashboard'
    })
  }

  function routeHome() {
    const path = '/login';
    window.location.href = path;
  }

  function routePlans() {
    const path = 'https://www.101obex.com/pricing';
    window.location.href = path;
  }

  const redirectPage = async (signin) => {
    setSignining(true);
    if (signin.developer && !signin.user) {
      routeDevDashboard();
    } else if(signin.avapId && signin.developer){
      routeDevDashboard();
    }
    else
    {
      if (signin.user) {
        const projects = await ObexRequestHandler.get('/projects');
        if (!projects.success && projects.status == 403) {
          SetStillLoaded(false);
          handleShow();
        } else {

        }
      } else {
        if (signin.client) routeClientDashboard();
        SetStillLoaded(false);
        setError('No suscription found.')
        setSubmitDisabled(false);
        setSignining(false);
      }
    }
    setSubmitDisabled(false);
    setSignining(false);
    SetStillLoaded(true);
  }

  const handleSubmitSignip = async (event) => {
    event.preventDefault();
    try {
      setError('');
      setSignining(true);
      const signin = await SessionService.login(mail, password, developer);
      setSuccess(true);
      if(rememberMe==true){

        const obj = { mail: mail, password:password };

        // Convertir el objeto a una cadena JSON
        const objString = JSON.stringify(obj);

        // Guardar la cadena en localStorage
        cookies.set('myObj', objString, { path: '/' });

      }else{
        cookies.remove('myObj');
      }
      //const sessionExpiration = signin.secondsTokenExpiration
      if (signin.user) redirectPage(signin); else setError('No Suscription found!')
              setError('No Suscription found!');
      SetStillLoaded(false);
      setTimeout(function () {
        SetStillLoaded(false);
      }.bind(this), 2000)
      setTimeout(function () {
        setError('');
      }.bind(this), 4000)
    } catch (error) {

      setError(error.message || 'An error was ocurred while sigining.');
      SetStillLoaded(false);
      setTimeout(function () {
        SetStillLoaded(false);
      }.bind(this), 2000)
      setTimeout(function () {
        setError('');
      }.bind(this), 4000)

    }
    setSubmitDisabled(false);
    setSignining(false);
  //  SetStillLoaded(true);
  };


  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    routePlans();
  };


  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);

  }

  const changeAcces = (e) => {

    setDeveloper(!developer);

  }

  const changePass = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }

  const handleCheckboxChange = (e) => {
    
    const isChecked = e.target.checked;
    setRememberMe(isChecked);
    cookies.set('rememberMe', isChecked, { path: '/' }); // Guardar en cookies
  };

  return (

    <>
      <BrowserView>

    <div className="text-center d-flex flex-column containerLogin ">
{/*}
<Navbar className='navbar' style={{ position:'fixed', width:'100%' }}>
            <Container className="">
            <Navbar.Brand href="#home" style={{}}>
                <Image src={logo_framework} style={{ marginBottom: '5px' }} width="227px" alt="Logo" />
            </Navbar.Brand>
            <Nav style={{ width: '80%' }}>
            <Nav.Link href="#section1" style={{marginRight:"10px", marginLeft:"10px"}}>Products</Nav.Link>
            <Nav.Link href="#section2" style={{marginRight:"10px", marginLeft:"10px"}}>Partners</Nav.Link>
            <Nav.Link href="#section3" style={{marginRight:"10px", marginLeft:"10px"}}>Resource</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Company</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Contact Sales</Nav.Link>
            </Nav>
            </Container>
        </Navbar>
      

      */}
      {stillLoaded ?
        <Container className="vh-100 d-flex flex-column vw-100">
          <Row className="h-100" >
            <Col md="1" className="mx-auto d-flex align-items-center">
              <Spinner animation="border" />
            </Col>
          </Row>
        </Container>
        :
        <><div className="justify-content-center pt-5 rowLogin " style={{minHeight:"100vh"}}> {/* 74vh */}
          <div className="login-form px-5 pt-4">
            <Row className="justify-content-center " style={{marginTop:"7rem"}}>
              <Col >
                {/* <Image onClick={routeHome} src={Logo} fluid /> */}
                <Image onClick={routeHome} src={Logo} fluid
                  width="300px"
                  style={{ marginBottom: '60px' }}
                />
                 <h6 className='text-boldLogin'>Sign in with your AVAP ID and manage your account.</h6>
              </Col>
            </Row>

            <Row >
              <Col >
                <Row style={{

                  marginBottom: '20px',
                  borderStyle: 'hidden',
                  borderWidth: '1px',
                  marginLeft: '0px',
                  marginRight: '0px',
                  borderRadius: '5px'


                }}>
                  <Col>
                    {/* <Row>
                <Col xs="3" style={{ marginBottom: '-20px'}}>
                  <h1 style={{fontSize: '24px', color: '#7abcb0'}}>Sign in</h1>
                </Col> 
              </Row> */}

                    {/* <Form.Group controlId="customerLogin" 
                style={{ 
                          borderStyle:'solid', 
                          borderRadius:'7px', 
                          borderWidth:'1px', 
                          marginLeft:'-15px', 
                          marginRight:'-15px', 
                          borderColor:'lightgrey' 
                        }}>
                          
              <Row style={{ marginTop:'10px', marginBottom:'-0px' }}>
              <Image  src={UserLogin} style={{
                maxWidth:'100px',
                marginLeft:'30px',
                marginTop:'10px',
                marginBottom:'10px',
                borderRightStyle:'solid',
                paddingRight:'15px',
                borderRightWidth:'1px',
                borderRightColor:'lightgray'


              }} />

                    <Col style={{
                      maxWidth:'50px',
                      marginTop:'10px',
                      marginLeft:'0px'
                    }}
                    >


                      
                    <Form.Control 
                    style={{
                      maxHeight:'20px'

                    }}
                    required type="radio" title="Customer Login" checked = {!developer} onChange={changeAcces} />

                 
                     <Image src={PunteroLogin} style={{ maxWidth: '24px', marginRight: '0px', marginTop: '0px' }} />
                    </Col>
                    
                    <Col style={{ textAlign:'start', marginLeft:'0px' }} >
                    <Form.Label style={{ fontSize:'10px', marginTop:'10px', color: '#7abcb0', fontWeight: 'bold', marginBottom: '-10px'}}><h1 style={{color: '#7abcb0'}} >Unique Login</h1> </Form.Label>
                    <Row style={{ 
                      color:'gray', 
                      fontSize:'12px', 
                      paddingRight:'15px',
                       marginLeft:'5px' 
                       }}>Access to all your profiles, Customer, Developer or Administrator.</Row>
                    </Col>
              </Row>
              </Form.Group> */}



                    {/* <Form.Group controlId="developerLogin" 
                  style={{ 
                    borderStyle:'solid', 
                    borderRadius:'7px', 
                    borderWidth:'1px', 
                    marginLeft:'-15px', 
                    marginRight:'-15px', 
                    borderColor:'lightgrey' 
                    }}>
              <Row style={{ marginTop:'10px' }}>
                    
              <Image src={DevLogin}  style={{
                maxWidth:'100px',
                marginLeft:'30px',
                marginTop:'10px',
                marginBottom:'10px',
                borderRightStyle:'solid',
                paddingRight:'15px',
                borderRightWidth:'1px',
                borderRightColor:'lightgray'


              }}/>
                    
                    <Col style={{
                      maxWidth:'50px',
                      marginTop:'13px',
                      marginLeft:'10px'
                    }}
                    >
                      
                    <Form.Control 
                    style={{
                      maxHeight:'20px'

                    }}
                    required type="radio" title="Developer Login" checked = {developer} onChange={changeAcces}  />
                    </Col>
                    <Col style={{ textAlign:'start', marginLeft:'-10px' }} >
                    <Form.Label style={{ 
                      fontSize:'22px',
                      marginTop:'7px', 
                      color: '#7abcb0' 
                      }}><strong>Team Member </strong></Form.Label>
                    <Row style={{ 
                      color:'gray', 
                      fontSize:'12px', 
                      paddingRight:'15px', 
                      marginLeft:'3px' 
                      }}>User within an account that performs daily tasks.</Row>
                    </Col>
              </Row>
              </Form.Group> */}



                  </Col>
                </Row>
                <Row >
                  {/* <Col style={{ maxWidth:'30px' }}>
                <Image  src={EmailIcon} style={{
                maxWidth:'25px',
}} />
                </Col> */}
                </Row>
                <Form className="obex-form theForm">
                  <Form.Group controlId="formBasicEmail">

                    <Form.Control style={{backgroundColor:'transparent'}} id='entrada-cloud-login' required type="email" value={mail} placeholder="Email" onChange={changeMail} className="borderRadiusLogin" />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <div className='d-flex'>
                    <InputGroup className="">
                      <Form.Control style={{backgroundColor:'transparent'}} required type={showPassword ? 'text' : 'password'}  value={password} placeholder="Password" onChange={changePass} className="borderRadiusLeftLogin" />
                      <InputGroup.Append>
                      <Button className='btnNew' onClick={handleShowPassword} style={{ background: "white", borderLeft: "0px", borderRight: "none", borderTop: "1px #ced4da solid", borderBottom: "1px #ced4da solid", width:'40px' }}>
                          {showPassword ?
                            <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#AFAFAF" }} /> :
                            <FontAwesomeIcon icon={faEye} style={{ color: "#AFAFAF" }} />}
                        </Button>
                          </InputGroup.Append>
                      <button
                        type="submit"
                        className="borderRadiusRightLogin btnNew  btn-block"
                        style={{width:'40px'}}
                        onClick={handleSubmitSignip} disabled={signining}>
                        {/* {signining ? 'Loading' : 'Next'} */}
                        {!signining && <>
                        <svg xmlns="http://www.w3.org/2000/svg" fill='gray' width={"15px"} height={"15px"}  viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                      </>}
                        {signining && 
                        <div className='d-flex justify-content-center align-items-center'>
                          <Spinner animation="border" size="sm" style={{ color:"gray" }} />
                        </div>
                        }
                      </button>
                    </InputGroup>
                    </div>
                  </Form.Group>


                  {error.length > 0 && (
                    <Alert variant='danger'>
                      {error}
                    </Alert>)}
                </Form>
              </Col>
            </Row>
            <Row>
              <Col style={{ color: 'gray', fontSize: '14px', marginBottom:'7rem' }}>

                <div className='d-flex align-items-center flex-column mt-4'>
                  <div className='pb-2'>
                  <div className="containerPri">
                    <div className="checkbox-container1 pr-2" >
                    <input type="checkbox" checked={rememberMe} onChange={handleCheckboxChange}/>
                    </div>
                    <div>
                      <div style={{ marginTop:"2px" }} className="theblack" >Remember me</div>
                    </div>
                  </div>
                  </div>
                  <div className=' cursor-pointer' style={{color:"#147BCE"}} onClick={handleShowRecovery}>Forgotten your password?</div>
                  <a href="/Register" style={{color:"#147BCE"}}> Create AVAP ID</a>

                </div>
                {/* <span>
              <Row style={{ textAlign:'center', justifyContent:'center', marginBottom:'10px' }}>
              <a className="link_forgetpass" onClick={handleShowRecovery}>Are you having problems with signing in?</a>
              </Row>
            </span>
              <Row>
            By continuing, you agree to the 101OBeX Agreement or other agreement for 101OBeX services, and the Privace Notice. This site uses essential cookies. 
            </Row> */}
                <Row style={{ marginTop: '10px' }}></Row>
                <Row style={{ marginBottom: '30px' }}>
                  <Col style={{ textAlign: 'center' }}>
                    {/* <span>Need to create a new organization?</span> */}
                    {/* IM REMOVE THIS 
            <a onClick={handleSubmitSignup} style={{ color:'#7abcb0' }}> Sign up </a> */}
                    {/* <span>here.</span> */}
                  </Col>
                </Row>
              </Col>

            </Row>

          </div>
          <PasswordRecovery show={showRecovery} handleClose={handleCloseRecovery} />
        </div><VerificationNotice
            mail={mail}
            show={show}
            handleClose={handleClose} />
          <Row className="mt-auto">
            <Col className="px-0">
              {/*   <Footer /> */}
            </Col>
          </Row></>
      }
     {/*} <div>
        <FooterMenu />
      </div>*/}
    </div>
    </BrowserView>
      <MobileView>
        <div id='entrada-cloud-login'>
        <Disclaimer />

        </div>
      </MobileView>
    </>

  );
}

export default CloudLoginForm;