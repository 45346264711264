import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';

import obexLogo from '../../assets/img/landingPage/Logotipo_101OBEX_2024.png';
import avapLogo from '../../assets/img/logos/AVAP_Lenguaje_white.svg';
import icn_vision from '../../assets/img/landingPage/icn_vision.png';
import icn_mision from '../../assets/img/landingPage/icn_mission.png';
import openAi from '../../assets/img/landingPage/openapi-blanco.png'
import linux from '../../assets/img/landingPage/Linux.png'
import fondo from '../../assets/img/landingPage/fondo.png';

const FrameworkAboutUs = () => {
    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);


    return (
        <>
            <FlNav></FlNav>
            <div className="fl-framework bg-black text-white">
                {/* Hero Section */}
                <section id="hero" className="text-center py-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img className="obexLogo" src={obexLogo} alt="" />
                    <h1 className="fl-title m-0">Empowering Connections, Enabling Innovation</h1>
                    <p className="fl-title-big m-0">An API Virtualization Company</p>
                    {/* <img
                        src="https://placehold.co/600x300"
                        alt="Placeholder"
                        className="img-fluid mt-4"
                    /> */}
                </section>

                {/* Vision and Mission */}
                <section className="container py-5 fl-gradientContainer mb-5" >
                    <div className="row">
                        <div className="col-md-6 pl-5">
                            <img
                                src={icn_vision}
                                alt="Vision Icon"
                                className="mb-3"
                            />
                            <h3 className="fl-subtitle">Vision</h3>
                            <p className="fl-text">
                                1010BEX, CORP aims to transform the future of technology by creating next-generation solutions
                                that will have an impact in making data transfer and sharing technologically inclusive and economically
                                efficient, empowering seamless digital interactions across a connected society.
                            </p>
                        </div>
                        <div className="col-md-6 pl-5">
                            <img
                                src={icn_mision}
                                alt="Mission Icon"
                                className="mb-3"
                            />
                            <h3 className="fl-subtitle">Mission</h3>
                            <p className="fl-tex">
                                Our mission is to create innovative products based on our technology that lead the way in
                                complete API lifecycle management, bringing our vision to life through solutions that drive
                                progress and empower a more connected, inclusive, and efficient society.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Investors */}
                <section className="text-center py-5 mt-5 mb-5">
                    <h2 className="fl-title">Investors</h2>
                    {/* <img
                        src="https://placehold.co/300x150?text=Logo%201200"
                        alt="Investors Logo"
                        className="img-fluid mt-3"
                    /> */}
                    <div className="wrapperInvestors">
                        <p className="fl-subtitle-xs">Members of</p>
                        <div className="d-flex justify-content-center imgWrapper">
                            <img
                                src={openAi}
                                alt="OpenAPI Logo"
                            />
                            <img
                                src={linux}
                                alt="Linux Foundation Logo"
                            />
                        </div>
                    </div>
                </section>

                {/* AVAP Section */}
                <section className="container py-5 fl-gradientContainer mt-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <img className="fl-avapLogo" src={avapLogo} alt="avap logo" />
                            <p className="fl-text">
                                With AVAP, we have created a technology that redefines the API lifecycle management by
                                providing a seamless, comprehensive platform that enhances efficiency, security, and
                                innovation throughout the entire API lifecycle, from development to deployment.
                            </p>
                            <p className="fl-text">
                                The API virtualization is central to an innovative and disruptive approach to the new
                                development models, driving increased productivity of development teams and efficient
                                investment in software maintenance.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            {/* <img
                                src="https://placehold.co/400x300"
                                alt="AVAP Diagram"
                                className="img-fluid"
                            /> */}
                        </div>
                    </div>
                </section>

                {/* Our Story */}
                <section className="py-5">
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col text-center">
                                <h2 className="fl-title mb-5">Our Story</h2>
                            </div>
                        </div>
                        <div className="row  grayGradient fl-br-20">
                            <div className="col px-5 py-5">
                                <p className="fl-subtitle">
                                    Envisioned a solution to the limitation of General-purpose programming languages.
                                </p>
                                <p className="fl-text">
                                    AVAP (Advanced Virtual API Programming) is a high-level programming language designed specifically for API development.
                                    Its origins trace back to late 2019, when a team of engineers at 101OBEX, led by Raúl Nogales (Founder & CEO) and Rafael Ruiz (CTO),
                                    envisioned a solution to the limitations of general-purpose programming languages. They sought a tool that would combine simplicity,
                                    rapid development, efficiency, technology abstraction, and virtualization to streamline API creation.
                                    AVAP focuses entirely on functional workflows, eliminating the need to navigate the technical intricacies of traditional languages.
                                </p>

                                {/* <img
                                    src="https://placehold.co/1000x200?text=Placeholder"
                                    alt="AVAP Diagram"
                                    className="img-fluid"
                                /> */}
                            </div>
                        </div>

                    </div>
                </section>

                <section className="container">
                    <div className="row mt-5">
                        <div className="col-md-6 ">
                            <div className="wrapper grayGradient fl-br-20 px-5 py-3">
                                <img
                                    src={icn_vision}
                                    alt="Vision Icon"
                                    className="mb-3"
                                />
                                <h4 className="fw-bold">The vision extended beyond a language
                                </h4>
                                <p>
                                    Our goal was to integrate it into a development framework that could enhance code durability and even improve code autonomy.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="wrapper grayGradient fl-br-20 px-5 py-3">
                                <img
                                    src={icn_mision}
                                    alt="Vision Icon"
                                    className="mb-3"
                                />
                                <h4 className="fw-bold">Accelerate Development and Optimize Investments</h4>
                                <p>
                                    Over the past five years, the platform has expanded with the launch of products like AVAP Virtual Server, AVAP Dev Studio, and more.
                                    <br /> &nbsp;
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Clients */}
                <section className="container text-center py-5">
                    <div className="row">
                        <div className="col-12">
                            {/* <h2 className="fl-title">Our Clients</h2> */}
                        </div>
                        <div className="col-3">
                            {/* <img src="https://placehold.co/150x50" alt="Client 1" /> */}
                        </div>
                        <div className="col-3">
                            {/* <img src="https://placehold.co/150x50" alt="Client 2" /> */}
                        </div>
                        <div className="col-3">
                            {/* <img src="https://placehold.co/150x50" alt="Client 3" /> */}
                        </div>
                        <div className="col-3">
                            {/* <img src="https://placehold.co/150x50" alt="Client 4" /> */}
                        </div>
                    </div>
                </section>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default FrameworkAboutUs;
