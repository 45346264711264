import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef, useEffect } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';

const CustomDeveloperSelect = ({ developers, onAdd, onRemove, selDevelopers, opened }) => {
    const [isOpen, setIsOpen] = useState(false); // Controla si el menú está abierto
    const [searchTerm, setSearchTerm] = useState(''); // Controla el término de búsqueda
    const [selectedDevelopers, setSelectedDevelopers] = useState([]); // Lista de developers seleccionados
    const inputRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        console.log('-----SD-----')
        console.log(selDevelopers)
        if (selDevelopers.length>0){
            selDevelopers.forEach(element => {
                console.log(element)
               // handleSelect(element);
                onAdd(element.dev_id)
            });
            setSelectedDevelopers(selDevelopers);
            selDevelopers=[];
        } else setSelectedDevelopers([])
        console.log('-----SD-----')

      }, [opened ]);


      useEffect(()=>{
        if (isOpen && inputRef.current) {
            inputRef.current.focus(); // Asigna el foco al control
          }

      }, [isOpen])

    const toggleDropdown = () => {
       

        setIsOpen(!isOpen);
        
    }

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSelect = (developer) => {
        // Evita duplicados
        console.log(selectedDevelopers);
        console.log(developer)
        if (!selectedDevelopers.some((d) => d.dev_id === developer.dev_id)) {
            const updatedSelection = [...selectedDevelopers, developer];
            setSelectedDevelopers(updatedSelection); // Actualiza la lista local

            console.log('lklklkl----');
            console.log(updatedSelection)

            onAdd(developer);
        }
        setIsOpen(false); // Cierra el menú
    };

    const handleRemove = (developer) => {
        const updatedSelection = selectedDevelopers.filter((d) => d.dev_id !== developer.dev_id);
        setSelectedDevelopers(updatedSelection); 
        onRemove(developer); // Llama al callback para eliminar el developer
    };

    // Filtra los developers por el término de búsqueda
    /*
    const filteredDevelopers = developers.filter((dev) =>
        (dev.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
        (dev.name?.toLowerCase() || '').includes(searchTerm.toLowerCase())
    );
    
*/
    const filteredDevelopers = developers.filter((dev) =>
        (
            (dev.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
            (dev.name?.toLowerCase() || '').includes(searchTerm.toLowerCase())
        ) &&
        !selectedDevelopers.some(selectedDev => selectedDev.email === dev.email) // Excluye desarrolladores seleccionados
    );


    return (
        <div className="custom-dev-wrapper">
            {/* Input principal */}
            <InputGroup>
                <Form.Control
                    type="text"
                    placeholder="Select developers..."
                    onFocus={toggleDropdown} 
                    readOnly
                />
                                    <InputGroup.Text style={{
                                                    marginLeft: 'calc(100% - 28px)',
                                                    marginTop:'2px',
                                                    backgroundColor:'transparent',
                                                    color:'white',
                                                    borderColor:'transparent',
                                                    position:'absolute'
                                                    }}>
                                    {/*<i className="bi bi-chevron-down"></i>*/}
                                    <FontAwesomeIcon style={{ width:'8px' }} icon={faChevronDown}/>
                                  </InputGroup.Text>
            </InputGroup>

            {/* Dropdown personalizado */}
            {isOpen && (
                <div className="custom-dropdown" style={{ marginTop:'-36px' }}>
                    {/* Campo de búsqueda */}
                    <div className="">
                        <Form.Control
                            ref={inputRef}
                            type="text"
                            placeholder="Select developers..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onBlur={()=>{
                                setTimeout(() => {
                                    setIsOpen(false)
                                  }, 200);
                                
                            }}>
 
                    </Form.Control>
                    </div>

                    {/* Lista de opciones */}
                    <div className="dropdown-options">
                        {filteredDevelopers.length > 0 ? (
                            filteredDevelopers.map((dev) => (
                                <div
                                    key={dev.id}
                                    className="dropdown-option"
                                    onClick={() => {
                                        console.log('PUSHHH')
                                        handleSelect(dev)}}
                                >
                                    {dev.name} ({dev.email})
                                </div>
                            ))
                        ) : (
                            <div className="no-results">No developers found</div>
                        )}
                    </div>
                </div>
            )}

            {/* Lista de developers seleccionados */}
            <div className="selected-developers mt-3">
                {selectedDevelopers.map((dev) => (
                    <div key={dev.id} className="selected-developer-item">
                        <span>{dev.name} ({dev.email})</span>
                        <Button
                         className='delete'                      //variant="danger"
                      style={{ backgroundColor:'transparent', borderColor:'transparent' }}
                            size="sm"
                            onClick={() => handleRemove(dev)}
                        >
                            x
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomDeveloperSelect;
